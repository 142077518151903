import { Directive, ElementRef, HostListener, Input, Renderer2, AfterViewInit } from '@angular/core';
import { FormGroupDirective } from '@angular/forms';
import { MatCheckbox } from '@angular/material/checkbox';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

@UntilDestroy()
@Directive({
    selector: '[l7MandatoryCheckboxWithError]',
    standalone: false,
})
export class MandatoryCheckboxWithErrorDirective implements AfterViewInit {

    private _formGroupDirective: FormGroupDirective;
    private _hasErrorAlready: boolean;
    @Input() errorMessage: string = 'Dies ist ein Pflichtfeld';

    @Input() set triggerValidation(value: boolean) {
        if (value) {
            this.handleCheckboxChange();
        }
    }

    constructor(private readonly elementRef: ElementRef, private readonly renderer: Renderer2, private readonly fgDirective: FormGroupDirective, private readonly host: MatCheckbox) {
    }

    @HostListener('change') onChange(): void {
        this.handleCheckboxChange();
    }

    ngAfterViewInit(): void {
        this._formGroupDirective = this.fgDirective;
        this.watchFormGroupValidation();
    }

    private watchFormGroupValidation(): void {
        this._formGroupDirective.ngSubmit.pipe(untilDestroyed(this)).subscribe(() => {
            this.handleCheckboxChange();
        });
    }

    private handleCheckboxChange(): void {
        const checkbox = this.elementRef.nativeElement as MatCheckbox;
        const checkBoxParent = this.elementRef.nativeElement.parentNode;
        const isChecked = this.host.checked;

        if (!isChecked) {
            this.renderer.addClass(checkbox, 'uncheck-mandatory-checkbox');
            this.renderer.addClass(checkbox, 'ng-invalid');
            this.displayErrorMessage(checkbox, checkBoxParent);
        } else {
            this.renderer.removeClass(checkbox, 'uncheck-mandatory-checkbox');
            this.renderer.removeClass(checkbox, 'ng-invalid');
            this.clearErrorMessage(checkbox, checkBoxParent);
        }
    }

    private displayErrorMessage(checkbox: MatCheckbox, parent: HTMLElement): void {
        if (!this._hasErrorAlready) {
            const errorContainer = this.renderer.createElement('mat-error');
            errorContainer.setAttribute('class', 'mat-error mat-error-standalone');
            errorContainer.innerHTML = this.errorMessage;
            this.renderer.appendChild(parent, errorContainer);
            this._hasErrorAlready = true;
        }
    }

    private clearErrorMessage(checkbox: MatCheckbox, parent: HTMLElement): void {
        const errorContainer = parent.querySelector('mat-error');
        if (errorContainer) {
            this.renderer.removeChild(parent, errorContainer);
            this._hasErrorAlready = false;
        }
    }

}
