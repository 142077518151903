/* libs/presentation/src/Components/upload-documents/upload-documents.component.scss */
::ng-deep .cdk-select-categories {
  font-size: 14px !important;
}
:host h2 {
  font-size: 18px;
  margin-top: 25px;
}
:host p {
  font-size: 14px;
  margin-bottom: 15px;
}
:host l7-file-uploader {
  margin-bottom: 15px;
}
:host l7-file-uploader ::ng-deep .button.button-blue {
  margin-top: 0;
}
:host mat-form-field.fixed-height {
  height: 73px;
}
:host .spinner-container {
  position: absolute;
  height: 100%;
  width: 100%;
  background-color: rgba(255, 255, 255, 0.5);
  z-index: 9;
  padding-top: 5%;
}
/*# sourceMappingURL=upload-documents.component.css.map */
