import { IInsuranceTypeDto } from '@abcfinlab/api/global';

export const SUPPORTED_INSURANCE_TYPES = [
    {
        translation_key: 'calculation.no_insurance',
        value: IInsuranceTypeDto.No,
    },
    {
        translation_key: 'calculation.add_100pro_insurance_placeholder',
        value: IInsuranceTypeDto.Pro100,
    },
    {
        translation_key: 'calculation.add_ordinary_insurance_placeholder',
        value: IInsuranceTypeDto.Standard,
    },
];
