/* libs/presentation/src/Components/email-input-with-chips/email-input-with-chips.component.scss */
:host .mat-mdc-form-field ::ng-deep .mat-mdc-form-field-flex {
  max-height: 55px;
}
:host .mat-mdc-form-field {
  -webkit-box-shadow: none;
  box-shadow: none;
  border: none;
  width: auto;
  height: auto;
  font-size: 14px;
}
:host .text-purchase-entry {
  margin-bottom: 15px;
}
:host mat-chip-grid {
  margin-bottom: 15px;
}
:host input.mat-mdc-chip-input {
  width: 100%;
}
/*# sourceMappingURL=email-input-with-chips.component.css.map */
