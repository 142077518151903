// #region Imports

import { once } from '@abcfinlab/core';
import { Directive, ElementRef, EventEmitter, Input, NgZone, Output, type OnInit } from '@angular/core';
import { MotionCache } from './MotionCache';
import { MotionManager } from './MotionManager';

// #endregion

/**
 * @private
 */
type MotionCallback = (args?: { keyframes?: PropertyIndexedKeyframes; options?: KeyframeAnimationOptions }) => void;

/**
 * @private
 */
type InitialProperties = Record<string, string | Array<string> | number | null | Array<number | null> | undefined>;

/**
 * @public
 */
@Directive({
    selector: '[motion]',
    standalone: false,
})
export class MotionDirective implements OnInit {

    // #region Fields

    private readonly _element: ElementRef;
    private readonly _zone: NgZone;
    private readonly _motionCache: MotionCache;
    private readonly _motionManager: MotionManager;
    private readonly _ready: EventEmitter<MotionCallback>;
    private readonly _finished: EventEmitter<void>;

    private _name: string;
    private _keyframes: PropertyIndexedKeyframes | null;
    private _options: KeyframeAnimationOptions | null;
    private _initial: InitialProperties | null;

    // #endregion

    // #region Ctor

    /**
     * Constructs a new instance of the `MotionDirective` class.
     *
     * @public
     */
    public constructor(element: ElementRef, zone: NgZone, motionCache: MotionCache, motionManager: MotionManager) {
        this._element = element;
        this._zone = zone;
        this._motionCache = motionCache;
        this._motionManager = motionManager;
        this._name = '';
        this._keyframes = null;
        this._options = null;
        this._initial = null;
        this._ready = new EventEmitter();
        this._finished = new EventEmitter();
    }

    // #endregion

    // #region Properties

    /**
     * Gets or sets the `initial` property.
     *
     * @public
     */
    @Input('motionInitial')
    public get initial(): InitialProperties | null {
        return this._initial;
    }

    public set initial(value: InitialProperties | null) {
        this._initial = value;
    }

    /**
     * Gets or sets the `keyframes` property.
     *
     * @public
     */
    @Input('motionKeyframes')
    public get keyframes(): PropertyIndexedKeyframes | null {
        return this._keyframes;
    }

    public set keyframes(value: PropertyIndexedKeyframes | null) {
        this._keyframes = value;
    }

    /**
     * Gets or sets the `options` property.
     *
     * @public
     */
    @Input('motionOptions')
    public get options(): KeyframeAnimationOptions | null {
        return this._options;
    }

    public set options(value: KeyframeAnimationOptions | null) {
        this._options = value;
    }

    /**
     * Gets or sets the `name` property.
     *
     * @public
     */
    @Input('motionPreset')
    public get name(): string {
        return this._name;
    }

    public set name(value: string) {
        this._name = value;
    }

    /**
     * Called when <ACTION>.
     * Provides reference to `MotionCallback` as event argument.
     *
     * @public
     * @readonly
     * @eventProperty
     * @type EventEmitter<MotionCallback>
     */
    @Output()
    public get ready(): EventEmitter<MotionCallback> {
        return this._ready;
    }

    /**
     * Called when <ACTION>.
     * Provides reference to `void` as event argument.
     *
     * @public
     * @readonly
     * @eventProperty
     * @type EventEmitter<void>
     */
    @Output()
    public get finished(): EventEmitter<void> {
        return this._finished;
    }

    // #endregion

    // #region Methods

    /**
     * @public
     */
    public ngOnInit(): void {
        if (this._name) {
            const preset = this._motionCache.get(this._name);
            this._initial = preset.from;
            once(this.ready, (x) => {
                x({ keyframes: preset.to });
            });
        }

        this.applyInitialProperties();

        const cb: MotionCallback = (args?: { keyframes?: PropertyIndexedKeyframes; options?: KeyframeAnimationOptions }): void => {
            const p = args?.keyframes ?? this._keyframes;
            const o = {
                ...args?.options,
                ...this._options,
                ...{
                    duration: 333,
                    easing: 'ease',
                },
            };

            if (p) {
                once(this._motionManager.animate(this._element.nativeElement, p, o), () => {
                    this._finished.emit();
                });
            }
        };

        this._ready.emit(cb);
    }

    /**
     * @private
     */
    private applyInitialProperties(): void {
        this._zone.runOutsideAngular(() => {
            Object.entries(this._initial ?? {}).forEach(([k, v]) => {
                if (k in this._element.nativeElement.style) {
                    this._element.nativeElement.style.setProperty(k, v?.toString() ?? '');
                }
            });
        });
    }

    // #endregion

}
