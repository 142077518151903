import { Directive, ElementRef, Input, OnChanges, Renderer2, SimpleChanges } from '@angular/core';

@Directive({
    selector: '[l7Size]',
    standalone: false,
})
export class SizeDirective implements OnChanges {

    // #region Fields

    private readonly _el: ElementRef;
    private readonly _renderer: Renderer2;

    // #endregion

    public constructor(el: ElementRef, renderer: Renderer2) {
        this._el = el;
        this._renderer = renderer;
    }

    // #region Properties

    @Input('l7Size')
    public size: string = '';

    @Input()
    public minSize: string = '';

    @Input()
    public maxSize: string = '';

    // #endregion

    // #region Methods

    /**
     * @public
     */
    public ngOnChanges(changes: SimpleChanges): void {
        if (changes.size) {
            const [width, height] = this.size.split(';').map(s => s.trim());
            this._renderer.setStyle(this._el.nativeElement, 'width', width);
            this._renderer.setStyle(this._el.nativeElement, 'height', height);
        }

        if (changes.minSize) {
            const [minWidth, minHeight] = this.minSize.split(';').map(s => s.trim());
            this._renderer.setStyle(this._el.nativeElement, 'minWidth', minWidth);
            this._renderer.setStyle(this._el.nativeElement, 'minHeight', minHeight);
        }

        if (changes.maxSize) {
            const [maxWidth, maxHeight] = this.maxSize.split(';').map(s => s.trim());
            this._renderer.setStyle(this._el.nativeElement, 'maxWidth', maxWidth);
            this._renderer.setStyle(this._el.nativeElement, 'maxHeight', maxHeight);
        }
    }

    // #endregion

}
