// #region Imports

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ScrollToErrorDirective } from './ScrollToErrorDirective';

// #endregion

/**
 * The `ScrollToErrorModule` module.
 *
 * @public
 */
@NgModule({
    imports: [
        CommonModule,
    ],
    declarations: [
        ScrollToErrorDirective,
    ],
    exports: [
        ScrollToErrorDirective,
    ],
})
export class ScrollToErrorModule { }
