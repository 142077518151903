/*
 * Copyright The OpenTelemetry Authors
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      https://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
export var PerformanceTimingNames;
(function (PerformanceTimingNames) {
  PerformanceTimingNames["CONNECT_END"] = "connectEnd";
  PerformanceTimingNames["CONNECT_START"] = "connectStart";
  PerformanceTimingNames["DECODED_BODY_SIZE"] = "decodedBodySize";
  PerformanceTimingNames["DOM_COMPLETE"] = "domComplete";
  PerformanceTimingNames["DOM_CONTENT_LOADED_EVENT_END"] = "domContentLoadedEventEnd";
  PerformanceTimingNames["DOM_CONTENT_LOADED_EVENT_START"] = "domContentLoadedEventStart";
  PerformanceTimingNames["DOM_INTERACTIVE"] = "domInteractive";
  PerformanceTimingNames["DOMAIN_LOOKUP_END"] = "domainLookupEnd";
  PerformanceTimingNames["DOMAIN_LOOKUP_START"] = "domainLookupStart";
  PerformanceTimingNames["ENCODED_BODY_SIZE"] = "encodedBodySize";
  PerformanceTimingNames["FETCH_START"] = "fetchStart";
  PerformanceTimingNames["LOAD_EVENT_END"] = "loadEventEnd";
  PerformanceTimingNames["LOAD_EVENT_START"] = "loadEventStart";
  PerformanceTimingNames["NAVIGATION_START"] = "navigationStart";
  PerformanceTimingNames["REDIRECT_END"] = "redirectEnd";
  PerformanceTimingNames["REDIRECT_START"] = "redirectStart";
  PerformanceTimingNames["REQUEST_START"] = "requestStart";
  PerformanceTimingNames["RESPONSE_END"] = "responseEnd";
  PerformanceTimingNames["RESPONSE_START"] = "responseStart";
  PerformanceTimingNames["SECURE_CONNECTION_START"] = "secureConnectionStart";
  PerformanceTimingNames["UNLOAD_EVENT_END"] = "unloadEventEnd";
  PerformanceTimingNames["UNLOAD_EVENT_START"] = "unloadEventStart";
})(PerformanceTimingNames || (PerformanceTimingNames = {}));
