<mat-card class="address-card "
          [class.mat-card-outlined]="!this.flat"
          [class.mat-elevation-z0]="this.flat">
  <mat-card-header>
    <h3 matCardTitle>{{ title }}</h3>
    <mat-card-subtitle>
      <span class="left"></span> {{subTitle}}
    </mat-card-subtitle>
  </mat-card-header>

  <mat-card-content>
    <div class="left"
         *ngIf="iconContent">
        <mat-icon [fontIcon]="iconContent" [fontSet]="'l7'"></mat-icon>
    </div>
    <div class="center"
         *ngIf="contact">
      <p>{{contact.name}}</p>
      <p>{{contact.street}} {{contact.house_number}}</p>
      <p>{{contact.postcode}} {{contact.city}}</p>
    </div>
    <ng-container *ngIf="contentHtml">
      <div class="center"
           [innerHTML]="contentHtml"></div>
    </ng-container>
    <ng-content select=".content">
    </ng-content>
  </mat-card-content>
  <mat-card-actions>
    <ng-content select=".actions">
    </ng-content>
  </mat-card-actions>
</mat-card>
