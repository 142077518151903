import { Injectable } from '@angular/core';
import { NavigationEnd, NavigationStart, Route, Router } from '@angular/router';
import { BehaviorSubject, Observable } from 'rxjs';
import { filter } from 'rxjs/operators';
import { ErrorHandlerService } from '../errorHandler/error-handler.service';

@Injectable({
    providedIn: 'root',
})
export class RoutingstateService {

    private history = [];

    private readonly browserRefresh$ = new BehaviorSubject<boolean>(false);

    constructor(
        private readonly _router: Router,
        private readonly _errorHandler: ErrorHandlerService,
    ) {
        this._router.events.subscribe((event) => {
            if (event instanceof NavigationStart) {
                this.browserRefresh$.next(!this._router.navigated);
            }
        });
    }

    public loadRouting(): void {
        this._router.events
            .pipe(filter(event => event instanceof NavigationEnd))
            .subscribe(({ urlAfterRedirects }: NavigationEnd) => {
                this._errorHandler.cleanUp();
                this.history = [...this.history, urlAfterRedirects];
            });
    }

    public getHistory(): Array<string> {
        return this.history;
    }

    public getPreviousUrl(): string {
        return this.history[this.history.length - 2] || '/index';
    }

    public getBrowserRefresh(): Observable<boolean> {
        return this.browserRefresh$ as Observable<boolean>;
    }

    /**
     * thats only for internal unsage, it print's all angular known routes.
     */
    public print(): void {
        this.printpath('', this._router.config);
    }

    private printpath(parent: string, config: Array<Route>): void {
        // eslint-disable-next-line @typescript-eslint/prefer-for-of
        for (let i = 0; i < config.length; i++) {
            const route = config[i];
            // eslint-disable-next-line no-console
            console.log(`${String(parent)}/${route.path}`);
            if (route.children) {
                const currentPath = route.path ? `${String(parent)}/${route.path}` : parent;
                this.printpath(currentPath, route.children);
            }
        }
    }

}
