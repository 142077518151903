// #region Imports

import { NgModule } from '@angular/core';
import { FeatureDirective } from '../Directives/FeatureDirective';

// #endregion

/**
 * The `FeatureModule` module.
 *
 * @public
 */
@NgModule({
    declarations: [
        FeatureDirective,
    ],
    exports: [
        FeatureDirective,
    ],
})
export class FeatureModule { }
