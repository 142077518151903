// #region Imports

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TagComponent } from './TagComponent';

// #endregion

/**
 * @public
 */
@NgModule({
    imports: [
        CommonModule,
    ],
    declarations: [
        TagComponent,
    ],
    exports: [
        TagComponent,
    ],
})
export class TagModule {
}
