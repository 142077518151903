// #region Imports

import { ChangeDetectionStrategy, Component, OnDestroy, OnInit } from '@angular/core';
import { AuthorizeCallbackViewPresenter } from './AuthorizeCallbackViewPresenter';

// #endregion

/**
 * The `AuthorizeCallbackView` view.
 *
 * @public
 */
@Component({
    selector: 'l7-authorize-callback-view',
    templateUrl: './AuthorizeCallbackView.html',
    styleUrls: ['./AuthorizeCallbackView.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [
        AuthorizeCallbackViewPresenter,
    ],
    standalone: false,
})
export class AuthorizeCallbackView implements OnInit, OnDestroy {

    // #region Fields

    private readonly _presenter: AuthorizeCallbackViewPresenter;

    // #endregion

    // #region Ctor

    /**
     * Constructs a new instance of the `AuthorizeCallbackView` class.
     *
     * @public
     */
    public constructor(presenter: AuthorizeCallbackViewPresenter) {
        this._presenter = presenter;
    }

    // #endregion

    // #region Proeprties

    /**
     * Returns the presenter of the `AuthorizeCallbackView`
     *
     * @public
     * @readonly
     */
    public get presenter(): AuthorizeCallbackViewPresenter {
        return this._presenter;
    }

    // #endregion

    // #region Methods

    /**
     * @internal
     */
    public ngOnInit(): void {
        this._presenter.initialize();
    }

    /**
     * @internal
     */
    public ngOnDestroy(): void {
        this._presenter.dispose();
    }

    // #endregion

}
