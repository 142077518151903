<h2 *ngIf="data.title"
    matDialogTitle>{{ data.title | translate }}</h2>
<mat-dialog-content style="display: flex; flex-direction: column;">
  <!--  <img src="assets/images/image-failure.svg"
       alt="Server Error"> -->

    <ng-container *ngIf="data.text; else defaultErrorText">
        <p>Zeitpunkt: {{dateNow | date:'short'}} Uhr</p>
        <l7-spacer [thickness]="'top'"></l7-spacer>
        <p>{{data.text | translate : data.params}}</p>
    </ng-container>
    <ng-template #defaultErrorText>
        <h2>Es ist ein Fehler aufgetreten</h2>
        <p>Zeitpunkt: {{dateNow | date:'short'}} Uhr</p>
        <l7-spacer [thickness]="'top'"></l7-spacer>
        <p style="white-space: pre-line;">{{ formatText(data.error.error?.error_description) }}</p>
        <l7-spacer [thickness]="'top'"></l7-spacer>
        <p>Error status code: {{data.error.status}}</p>
    </ng-template>
</mat-dialog-content>
<mat-dialog-actions>
  <button id="button_close-server-error-dialog"
          mat-flat-button
          [color]="'accent'"
          (click)="onCloseDialog()">
    <span *ngIf="data.routingBehaviour === routingBehaviour.ROOT">{{'global.start.page' | translate}}</span>
    <span *ngIf="data.routingBehaviour !== routingBehaviour.ROOT">{{'global.ok' | translate}}</span>
  </button>
</mat-dialog-actions>
