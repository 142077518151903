<div fxLayout="column"
     fxLayoutGap="16px" [style.height.px]="height">
    <ng-container>
        <div  *ngIf="!webcamElement?.videoInitialized" fxLayoutAlign="center center" [style.height]="'100%'">
            <mat-spinner></mat-spinner>
        </div>
        <ng-container  *ngTemplateOutlet="cameraRef"></ng-container>
    </ng-container>

    <div fxFlex fxLayout="row" fxFlexAlign="center" fxLayoutGap="16px" *ngIf="showButtons">
        <button mat-stroked-button
                *ngIf="showBackButton"
                [attr.test-id]="'backButton'"
                color="primary"
                (click)="back()">{{'contract_management.back' | translate}}</button>
        <button mat-flat-button
                [color]="'primary'"
                [attr.test-id]="'cameraButton'"
                (click)="recordSubmitted()">
            {{buttonText | translate}}
        </button>
    </div>
</div>

<ng-template #cameraRef>
    <div fxFlex fxLayout="row" class="container" [style.width.px]="width">
        <div class="camera-feed" #cameraArea>
            <webcam [trigger]="triggerObservable"
                    (imageCapture)="handleImage($event)"
                    *ngIf="showWebcam"
                    [width]="width"
                    [allowCameraSwitch]="allowCameraSwitch"
                    [switchCamera]="nextWebcamObservable"
                    [videoOptions]="videoOptions"
                    mirrorImage="never"
                    [imageQuality]="1"
                    (cameraSwitched)="cameraWasSwitched($event)"
                    (initError)="handleInitError($event)"
                    #webcamComponent>
            </webcam>

            <div *ngIf="webcamElement?.videoInitialized" class="overlay-container" [style.height.px]="(webcamElement)? webcamElement.nativeVideoElement.height: 50">
                <div fxFlex="10%" class="mask-overlay rounded-corners-top"></div>
                <div fxFlex fxLayout="row" fxLayoutAlign="center center">
                    <div class="mask-overlay"></div>
                    <div class="clear-area" #clearArea></div>
                    <div class="mask-overlay"></div>
                </div>
                <div fxFlex="10%" class="mask-overlay rounded-corners-bottom"></div>
            </div>
        </div>
    </div>
</ng-template>
