import {
    ContractCalculationCrudService,
    IInsuranceAndHandlingDto,
    IProcessingStatusDto,
} from '@abcfinlab/api/contract';
import {
    CalculationService,
    ICalculationInsuranceRequestDto,
    IContractTypeDto,
    IInsuranceTypeDto,
    IObjectGroupDto,
} from '@abcfinlab/api/global';
import { ToastService } from '@abcfinlab/ui';
import {
    AfterViewInit,
    ChangeDetectionStrategy,
    Component,
    EventEmitter,
    Input,
    OnChanges,
    OnInit,
    Output,
    SimpleChanges,
} from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatSelectChange } from '@angular/material/select';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { BehaviorSubject, Observable } from 'rxjs';
import {
    calculateInsuranceValueRange,
    hundredProMustBeDisabled,
} from '../../../../../apps/l7/src/app/helper/calculation.helper';
import { formValuesAreEqual } from '../../Helpers/FormHelper';
import { SUPPORTED_INSURANCE_TYPES } from '../../Models/InsuranceTypes.config';

@UntilDestroy()
@Component({
    selector: 'l7-insurance-form',
    templateUrl: './insurance-form.component.html',
    styleUrls: ['./insurance-form.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false,
})
export class InsuranceFormComponent implements OnInit, OnChanges, AfterViewInit {

    @Input() view: 'QUOTE_DETAILS' | 'BACKOFFICE';
    @Input() contractType: IContractTypeDto;
    @Input() contractId: string;
    @Input() insuranceValueMin: number;
    @Input() insuranceValueMax: number;
    @Input() objectGroup: IObjectGroupDto;
    @Input() totalLeasingValue: number;
    @Input() totalTerms: number;
    @Input() set insuranceAndHandlingFeeValues(value: IInsuranceAndHandlingDto) {
        if (value) {
            this.insuranceType = value.insurance_type ? value.insurance_type : IInsuranceTypeDto.No;
            this.insuranceAndHandlingFee = {
                ...value,
                ...{ insurance_type: this.insuranceType },
            };
            let insuranceToggle: boolean | IInsuranceTypeDto = value.insurance;
            if (this.contractType === IContractTypeDto.Mietkauf || this.contractType === IContractTypeDto.Mkn) {
                insuranceToggle = this.insuranceAndHandlingFee.insurance_type;
            }

            this.originalValues = {
                insurance_type: this.insuranceType,
                insurance_toggle: insuranceToggle,
                insurance_value: value.insurance_value,
                handling_fee: value.handling_fee,
                handling_fee_value: value.handling_fee_value,
            };
            this.initializeInsurance();
        }
    }

    @Input() contractStatus: IProcessingStatusDto;

    @Input() navStatus: number;

    @Input() isReadonlyUser: boolean;

    @Output() validation: EventEmitter<boolean> = new EventEmitter<boolean>();
    @Output() loaded: EventEmitter<boolean> = new EventEmitter<boolean>();

    public insuranceType: IInsuranceTypeDto;

    private insuranceAndHandlingFee: IInsuranceAndHandlingDto;

    private originalValues;

    private readonly _areValuesChanged$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

    public form: UntypedFormGroup;
    public insuranceTypes: any = SUPPORTED_INSURANCE_TYPES;

    public minValue: number;
    public maxValue: number;

    public get doesnt_have_status(): boolean {
        return !this.isStatusAllowed(this.contractStatus);
    }

    public get areValuesChanged(): Observable<boolean> {
        return this._areValuesChanged$.asObservable();
    }

    private get calculateInsurancePayload(): ICalculationInsuranceRequestDto {
        return {
            insurance_type: this.insuranceType,
            insurance_value: this.form.controls.insurance_value.getRawValue(),
            object_group_id: this.objectGroup.code,
            object_group_insurance_value: this.objectGroup.insurance_value,
            total_leasing_value: this.totalLeasingValue,
            total_terms: this.totalTerms,
        };
    }

    constructor(
        private readonly formBuilder: UntypedFormBuilder,
        private readonly contractCalculationService: ContractCalculationCrudService,
        private readonly notification: ToastService,
        private readonly calculationService: CalculationService,
    ) {
        this.form = this.formBuilder.group({
            insurance_toggle: [{ value: false, disabled: this.isReadonlyUser }],
            insurance_value: [{ value: null, disabled: this.isReadonlyUser }],
            insurance_type: [{ value: null, disabled: true }],
            handling_fee: [{ value: false, disabled: this.isReadonlyUser }],
            handling_fee_value: [{ value: null, disabled: this.isReadonlyUser }, [Validators.required]],
            total_insurance_instalments: [{ value: null, disabled: true }],
            total_insurance_instalments_vat: [{ value: null, disabled: true }],
        });
    }

    ngOnChanges(changes: SimpleChanges) {
        if ((changes?.contractStatus && !this.isStatusAllowed(changes?.contractStatus.currentValue))
            || (changes?.navStatus && changes?.navStatus.currentValue >= 50)) {
            this.enableDisableFields(false);
        }
    }

    ngOnInit(): void {
        this.form.valueChanges
            .pipe(untilDestroyed(this))
            .subscribe((formValues) => {
                const compareValues = formValuesAreEqual(this.originalValues, {
                    insurance_toggle: this.form.controls.insurance_toggle.value,
                    insurance_type: this.insuranceType,
                    insurance_value: this.form.controls.insurance_value.value,
                    handling_fee: this.form.controls.handling_fee.value,
                    handling_fee_value: this.form.controls.handling_fee_value.value,
                });
                this._areValuesChanged$.next(!compareValues);
            });
        this.form.controls.insurance_value.valueChanges
            .pipe(untilDestroyed(this))
            .subscribe(_ => this.calculateInsurance());
        this.form.statusChanges
            .pipe(untilDestroyed(this))
            .subscribe((value) => {
                const isValid = value === 'VALID' ? true : false;
                this.validation.emit(isValid);
            });
        if ((this.contractType === IContractTypeDto.Mietkauf || this.contractType === IContractTypeDto.Mkn) && this.insuranceType && this.insuranceType !== IInsuranceTypeDto.No) {
            this.calculateInsurance();
        }
    }

    ngAfterViewInit() {
        this.loaded.emit(true);
    }

    private initializeInsurance() {
        if (!this.doesnt_have_status || this.navStatus >= 50) {
            this.enableDisableFields(true);
        }
        switch (this.contractType) {
            case IContractTypeDto.Ta:
            case IContractTypeDto.Va:
            case IContractTypeDto.Itflex:
                if (!this.insuranceAndHandlingFee.default_insurance_value) {
                    this.form.controls.insurance_toggle.disable();
                    this.form.controls.insurance_value.disable();
                } else if (!this.insuranceAndHandlingFee.insurance_value && this.insuranceAndHandlingFee.default_insurance_value) {
                    this.form.controls.insurance_value.disable();
                } else {
                    this.initializeValidation();
                }
                break;
            case IContractTypeDto.Kfz:
                if (hundredProMustBeDisabled(this.totalLeasingValue, this.objectGroup.code)) {
                    this.form.controls.insurance_toggle.disable();
                    this.form.controls.insurance_value.disable();
                } else if (!this.insuranceAndHandlingFee.insurance_value && !hundredProMustBeDisabled(this.totalLeasingValue, this.objectGroup.code)) {
                    this.form.controls.insurance_value.disable();
                } else {
                    this.initializeValidation();
                }
                break;
            case IContractTypeDto.Mietkauf:
            case IContractTypeDto.Mkn:
                this.form.controls.insurance_value.disable();
                if ((this.insuranceType === 'STANDARD' || this.insuranceType === 'PRO100') && this.insuranceAndHandlingFee.insurance_value) {
                    this.form.controls.insurance_value.enable();
                    this.initializeValidation();
                }
                break;
        }
        this.initializeValues();
        if (this.doesnt_have_status || this.isReadonlyUser) {
            this.enableDisableFields(false);
        }
    }

    private initializeValues(): void {
        if (this.contractType !== IContractTypeDto.Mietkauf && this.contractType !== IContractTypeDto.Mkn) {
            this.form.controls.insurance_toggle.patchValue(this.insuranceAndHandlingFee.insurance);
        } else {
            this.form.controls.insurance_toggle.patchValue(this.insuranceType);
        }
        this.form.controls.insurance_type.patchValue(this.insuranceType);
        this.form.controls.insurance_value.patchValue(this.insuranceAndHandlingFee.insurance_value);
        this.form.controls.handling_fee.patchValue(this.insuranceAndHandlingFee.handling_fee);
        this.form.controls.handling_fee_value.patchValue(this.insuranceAndHandlingFee.handling_fee_value);
    }

    private initializeValidation(): void {
        this.form.controls.insurance_value.clearValidators();
        if (this.insuranceType === IInsuranceTypeDto.Standard) {
            this.form.controls.insurance_value.setValidators([
                Validators.required, Validators.min(this.insuranceValueMin), Validators.max(this.insuranceValueMax),
            ]);
        } else if (this.insuranceType === IInsuranceTypeDto.Pro100) {
            this.form.controls.insurance_value.setValidators([
                Validators.required, Validators.min(this.insuranceValueMin),
            ]);
        }

        this.form.controls.insurance_value.updateValueAndValidity({ emitEvent: false });
    }

    private enableDisableFields(value: boolean) {
        if (value) {
            this.form.enable();
        } else {
            this.form.disable();
        }
        this.form.controls.total_insurance_instalments_vat.disable();
        this.form.controls.total_insurance_instalments.disable();
    }

    private resetValidation(): void {
        this.form.controls.insurance_value.setValidators([]);
        this.form.controls.insurance_value.updateValueAndValidity();
    }

    private isStatusAllowed(status: IProcessingStatusDto) {
        const allowedStatus = [IProcessingStatusDto.Billable, IProcessingStatusDto.Signed, IProcessingStatusDto.ToBeDiscussed];
        return allowedStatus.includes(status);
    }

    private calculateInsurance(): void {
        if (this.view === 'BACKOFFICE') {
            return;
        }
        this.calculationService.calculateInsurance({ body: this.calculateInsurancePayload }).pipe(untilDestroyed(this))
            .subscribe((res) => {
                this.form.controls.total_insurance_instalments.patchValue(res.total_insurance_instalments);
                if (this.contractType === IContractTypeDto.Mkn && this.insuranceType === IInsuranceTypeDto.Pro100) {
                    this.form.controls.total_insurance_instalments_vat.patchValue('Nettobetrag: keine USt.');
                } else {
                    this.form.controls.total_insurance_instalments_vat.patchValue(res.total_insurance_instalments_vat);
                }

                if (!this.insuranceAndHandlingFee.default_insurance_value100_pro && !this.insuranceValueMin && this.insuranceType === IInsuranceTypeDto.Pro100) {
                    this.insuranceAndHandlingFee.default_insurance_value100_pro = res.insurance_value;
                    this.insuranceValueMin = res.insurance_value;
                    this.form.controls.insurance_value.patchValue(res.insurance_value, { emitEvent: false });
                    this.initializeValidation();
                }
            });
    }

    public handleInsuranceChange(event: MatSlideToggleChange): void {
        if (event.checked) {
            this.insuranceType = IInsuranceTypeDto.Standard;
            const insuranceMaxAndMinValues = calculateInsuranceValueRange(this.totalLeasingValue, this.objectGroup.insurance_value);
            this.insuranceValueMax = insuranceMaxAndMinValues.maxValue;
            this.insuranceValueMin = insuranceMaxAndMinValues.minValue;
            this.form.controls.insurance_value.enable({ emitEvent: false });
            if (this.insuranceAndHandlingFee.insurance_value) {
                this.form.controls.insurance_value.patchValue(this.insuranceAndHandlingFee.insurance_value);
            } else {
                this.form.controls.insurance_value.patchValue(this.insuranceAndHandlingFee.default_insurance_value);
            }
            this.initializeValidation();
        } else {
            this.insuranceType = IInsuranceTypeDto.No;
            this.form.controls.insurance_value.disable({ emitEvent: false });
            this.form.controls.insurance_value.patchValue(null);
            this.resetValidation();
        }
        this.form.controls.insurance_type.patchValue(this.insuranceType);
    }

    public handle100ProChange(event: MatSlideToggleChange): void {
        if (event.checked) {
            this.insuranceType = IInsuranceTypeDto.Pro100;
            this.insuranceValueMax = 0;
            this.insuranceValueMin = this.insuranceAndHandlingFee.default_insurance_value100_pro;
            this.form.controls.insurance_value.patchValue(this.insuranceAndHandlingFee.default_insurance_value100_pro);
            this.form.controls.insurance_value.enable({ emitEvent: false });
        } else {
            this.insuranceType = IInsuranceTypeDto.No;
            this.form.controls.insurance_value.disable({ emitEvent: false });
            this.form.controls.insurance_value.patchValue(null);
        }
        this.form.controls.insurance_type.patchValue(this.insuranceType);
        this.initializeValidation();
        this.calculateInsurance();
    }

    public handleInsuranceSelection(evt: MatSelectChange): void {
        if (evt.value === IInsuranceTypeDto.Standard) {
            this.insuranceType = IInsuranceTypeDto.Standard;
            const insuranceMaxAndMinValues = calculateInsuranceValueRange(this.totalLeasingValue, this.objectGroup.insurance_value);
            this.insuranceValueMax = insuranceMaxAndMinValues.maxValue;
            this.insuranceValueMin = insuranceMaxAndMinValues.minValue;
            this.form.controls.insurance_value.enable({ emitEvent: false });
            this.form.controls.insurance_value.patchValue(this.insuranceAndHandlingFee.default_insurance_value);
            this.initializeValidation();
        } else if (evt.value === IInsuranceTypeDto.Pro100) {
            this.insuranceValueMax = 0;
            this.insuranceValueMin = this.insuranceAndHandlingFee.default_insurance_value100_pro;
            this.insuranceType = IInsuranceTypeDto.Pro100;
            this.form.controls.insurance_value.enable({ emitEvent: false });
            this.form.controls.insurance_value.patchValue(this.insuranceAndHandlingFee.default_insurance_value100_pro);
            this.initializeValidation();
        } else if (evt.value === IInsuranceTypeDto.No) {
            this.insuranceType = IInsuranceTypeDto.No;
            this.form.controls.insurance_value.disable({ emitEvent: false });
            this.form.controls.insurance_value.patchValue(null);
            this.resetValidation();
        }
        this.form.controls.insurance_type.patchValue(this.insuranceType);
        this.calculateInsurance();
    }

    public handleHandlingFeeChange(event: MatSlideToggleChange): void {
        if (event.checked) {
            this.form.controls.handling_fee_value.enable();
            this.form.controls.handling_fee_value.patchValue(200);
        } else {
            this.form.controls.handling_fee_value.disable();
            this.form.controls.handling_fee_value.patchValue(0);
        }
    }

    public insuranceTypeDisabled(insuranceType: IInsuranceTypeDto): boolean {
        if (insuranceType === IInsuranceTypeDto.Pro100) {
            return hundredProMustBeDisabled(this.totalLeasingValue, this.objectGroup.code);
        }
        return (!this.insuranceAndHandlingFee.default_insurance_value && insuranceType === IInsuranceTypeDto.Standard);
    }

    public saveInsurance() {
        this._areValuesChanged$.next(false);
        // TODO remove to the parent
        this.contractCalculationService.updateExpenses({
            contractId: this.contractId,
            body: {
                ...this.insuranceAndHandlingFee,
                ...{
                    insurance: this.insuranceType === IInsuranceTypeDto.No ? false : true,
                    insurance_type: this.insuranceType,
                    insurance_value: this.form.controls.insurance_value.value,
                    handling_fee: this.form.controls.handling_fee.value,
                    handling_fee_value: this.form.controls.handling_fee_value.value,
                },
            },
        })
            .pipe(untilDestroyed(this))
            .subscribe(
                () => {
                    this.notification.show('Angaben erfolgreich gespeichert', 'success');
                    this.originalValues = {
                        insurance_toggle: this.form.controls.insurance_toggle.value,
                        insurance_type: this.insuranceType,
                        insurance_value: this.form.controls.insurance_value.value,
                        handling_fee: this.form.controls.handling_fee.value,
                        handling_fee_value: this.form.controls.handling_fee_value.value,
                    };
                },
                (_error) => {
                    this.notification.show(_error.error.error, 'danger');
                    this._areValuesChanged$.next(true);
                },
            );
    }

    protected readonly contractTypes = IContractTypeDto;

}
