<!--#region Templates -->

<ng-template #tpl
             let-context>
    <div fxLayout="column"
         fxLayoutGap="16px"
         class="row-detail-container"
         [@detailExpand]>
        <l7-invoice-contract-information-view [isReadonlyUser]="this.presenter.isReadonlyGroup | async">
        </l7-invoice-contract-information-view>
        @if (this.presenter.contractInformation | async) {
            <l7-insurance-form [contractType]="(this.presenter.contractInformation | async)?.newGetQuote.inhouseQuoteCalculation.contractType"
                               [contractId]="(this.presenter.contractInformation | async)?.contract.id"
                               [objectGroup]="(this.presenter.contractInformation | async)?.objGroup"
                               [totalLeasingValue]="(this.presenter.totalLeasingValue | async)"
                               [totalTerms]="(this.presenter.contractInformation | async)?.newGetQuote.inhouseQuoteCalculation.quoteCalculation.totalTerms"
                               [view]="'BACKOFFICE'"
                               [contractStatus]="(this.presenter.contractStatus | async)"
                               [isReadonlyUser]="(presenter.isReadonlyGroup | async)"
                               [navStatus]="this.presenter.navStatus | async"
                               [insuranceValueMin]="(this.presenter.insuranceMaxAndMinValues | async)?.min"
                               [insuranceValueMax]="(this.presenter.insuranceMaxAndMinValues | async)?.max"
                               [insuranceAndHandlingFeeValues]="(this.presenter.contractInformation | async)?.insuranceAndHandlingFee">
            </l7-insurance-form>
        }
        <mat-divider></mat-divider>
        <l7-invoice-documents-view [salesChanel]="presenter.salesChanel | async"
                                   [isReadonlyUser]="this.presenter.isReadonlyGroup | async"
                                   [isSlb]="this.presenter.isSlbQuote | async"
                                   [navStatus]="this.presenter.navStatus | async"
                                   [slbLnNeu]="this.presenter.isUnechtLnNeu | async">
        </l7-invoice-documents-view>
        <mat-divider></mat-divider>
        <l7-settlement-states-view [salesChanel]="presenter.salesChanel | async" [isReadOnlyUser]="(this.presenter.isReadonlyGroup | async)">
        </l7-settlement-states-view>
    </div>
</ng-template>

<!-- #endregion -->

<l7-page [isEmpty]="(this.presenter.dataSourceTotal | async) === 0">
    <l7-page-header [title]="'settlement.title' | translate" [ngClass]="{'sticky-banner': !ignoreReadOnlyBanner }">
        <l7-banner [appearance]="'outlined'"
                   [color]="'warn'"
                   [icon]="'Alert_Triangle'"
                   [iconFontSet]="'l7'"
                   *ngIf="(presenter.isReadonlyGroup | async) && (this.ignoreReadOnlyBanner === false)"
                   (closed)="this.ignoreReadOnlyBanner = true"
                   [closable]="true"
                   [content]="'settlement.readOnlyBanner' | translate">
        </l7-banner>
    </l7-page-header>

    <l7-page-pre-content>
        <l7-filter *cdkBreakpoint="let bp"
                   [title]="'Suchen und Filtern'"
                   [expandable]="bp('lt-md') ? true : false"
                   [defaultCondition]="presenter.defaultCondition| async"
                   [condition]="presenter.condition | async"
                   [conditionComparer]="presenter.conditionComparer | async">
            <mat-form-field l7FilterPart
                            fxFlex.lt-md="100"
                            fxFlex="40"
                            appearance="outline">
                <mat-label>{{ 'settlement.overview.filter.search' | translate }}</mat-label>
                <input #term="matInput"
                       matInput
                       [attr.test-id]="'OverviewConditionTermInput'"
                       [value]="(this.presenter.condition | async).term"
                       (keyup.enter)="this.presenter.onTermChanged($event.target.value)">
                <button mat-icon-button
                        matSuffix
                        (click)="this.presenter.onTermChanged(term.value)">
                    <mat-icon [fontSet]="'l7'"
                              [fontIcon]="'Search'"></mat-icon>
                </button>
            </mat-form-field>

            <mat-form-field l7FilterPart
                            appearance="outline"
                            fxFlex="20">
                <mat-label>{{ 'settlement.overview.filter.status' | translate }}</mat-label>
                <mat-select #select
                            multiple
                            [value]="(this.presenter.condition | async).status"
                            [attr.test-id]="'OverviewConditionStatusSelect'"
                            (selectionChange)="this.presenter.onConditionChanged($event.value)">
                    <mat-select-trigger>
                        {{select.value ? ('states.contract_state.' + select.value[0] | lowercase | translate) : ''}}
                        <span *ngIf="select.value?.length > 1"
                              style="opacity: 0.75; font-size: 0.75em;">
                            (+{{select.value.length - 1}} {{ 'settlement.overview.filter.more' | translate }})
                        </span>
                    </mat-select-trigger>
                    <mat-option *ngFor="let status of this.presenter.status | async"
                                [attr.test-id]="(status | lowercase) + 'Option'"
                                [value]="status">{{ 'states.contract_state.' + (status | lowercase) | translate }}</mat-option>
                </mat-select>
            </mat-form-field>

            <button *ngIf="(this.presenter.isDefaultSearchCondition | async) === false"
                    l7FilterClearAll
                    mat-button
                    color="warn"
                    [attr.test-id]="'ResetConditionButton'"
                    (click)="this.presenter.onResetConditions()">{{ 'settlement.overview.filter.reset' | translate }}</button>
        </l7-filter>

        <!-- <l7-page-section fxLayout="row"
                         fxLayoutAlign="start start"
                         fxLayoutGap="16px"
                         [inline]="true">
        </l7-page-section> -->
    </l7-page-pre-content>
    <l7-page-content>
        <mat-table *ngIf="this.presenter.dataSourceTotal | async"
                   [attr.test-id]="'OverviewTable'"
                   [dataSource]="this.presenter.dataSource">
            <ng-container *ngFor="let column of this.presenter.columns"
                          [matColumnDef]="column">
                <mat-header-cell *matHeaderCellDef>
                    <l7-text-block [truncate]="true"
                                   [text]="'settlement.overview.' + column | translate"></l7-text-block>
                </mat-header-cell>
                <mat-cell *matCellDef="let row">
                    <ng-container [ngSwitch]="column">
                        <ng-container *ngSwitchCase="'object.value'">
                            <l7-text-block [truncate]="true"
                                           [text]="row | memberValue:column | currency:(row | memberValue:'object.currency')"></l7-text-block>
                        </ng-container>
                        <ng-container *ngSwitchCase="'billable_since'">
                            <l7-text-block [truncate]="true"
                                           [text]="row[column] | date"></l7-text-block>
                        </ng-container>
                        <ng-container *ngSwitchCase="'status'">
                            <l7-text-block [truncate]="true"
                                           [text]="'states.contract_state.' + (row[column] | lowercase) | translate"></l7-text-block>
                        </ng-container>
                        <ng-container *ngSwitchDefault>
                            <l7-text-block [truncate]="true"
                                           [text]="row | memberValue:column"></l7-text-block>
                        </ng-container>
                    </ng-container>
                </mat-cell>
            </ng-container>
            <mat-header-row *matHeaderRowDef="this.presenter.columns"></mat-header-row>
            <mat-row *matRowDef="let row; columns: this.presenter.columns;"
                     matRipple
                     class="detail-row"
                     [l7DetailRow]="row"
                     [l7DetailRowTemplate]="tpl"
                     [attr.test-id]="row['quote_id']"
                     (l7DetailRowExpanded)="this.presenter.onRowSelected(row)">
            </mat-row>
        </mat-table>

        <mat-paginator showFirstLastButtons
                       [length]="this.presenter.dataSourceTotal | async"
                       [pageSize]="this.presenter.pageSize | async"
                       [pageSizeOptions]="this.presenter.pageSizes | async"
                       (page)="this.presenter.onPageChanged($event)">
        </mat-paginator>
    </l7-page-content>
</l7-page>
