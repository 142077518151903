/* libs/auth/src/Views/AuthorizeCallbackView.scss */
:host {
  position: relative;
  height: 100%;
  width: 100%;
  top: 0;
}
:host .login-error-container {
  text-align: center;
}
:host .svg-container {
  width: 100%;
  height: 100%;
  position: relative;
}
/*# sourceMappingURL=AuthorizeCallbackView.css.map */
