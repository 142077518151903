/* libs/presentation/src/Components/retailer-sign-contract/RetailerSignContractView.scss */
my-box {
  background-color: var(--l7-info-500-color);
}
:host fieldset {
  margin-bottom: 10px;
}
:host legend {
  font-size: 12px;
  font-weight: unset;
  margin-bottom: 5px;
}
:host mat-card-content.more-padding {
  padding: 15px 5px 10px 5px;
}
:host .uncheck-mandatory-checkbox ::ng-deep .mdc-checkbox__background {
  border-color: #ad0149 !important;
}
:host .mat-mdc-radio-group {
  display: grid;
}
:host .align-center {
  text-align: center;
}
:host .more-margin {
  margin-left: 26px;
}
:host a {
  text-decoration: underline;
  font-weight: normal;
  color: #1b7e9a;
}
:host .mat-mdc-form-field.warning ::ng-deep .mat-form-field-outline,
:host .mat-mdc-form-field.warning ::ng-deep .mat-mdc-form-field-error {
  color: #ff7d00;
}
:host .mat-mdc-form-field.warning.mat-form-field-invalid ::ng-deep mat-label {
  color: #ff7d00;
}
:host .background {
  overflow: hidden;
}
@media only screen and (max-width: 600px) {
  :host .small-card {
    width: 45%;
  }
}
/*# sourceMappingURL=RetailerSignContractView.css.map */
