// #region Imports

import { ChangeDetectionStrategy, Component, ElementRef, EventEmitter, HostBinding, Input, Output } from '@angular/core';
import { Appearance } from '../../Appearance';
import { ColorSet } from '../../Color';
import { HostBindingBuilder } from '../../HostBindingBuilder';

// #endregion

/**
 * The `BannerComponent` component.
 *
 * @public
 *
 * @example
 * ```html
 * <l7-banner header="Header" content="Content" icon="fa fa-info-circle" color="primary" appearance="filled"></l7-banner>
 * ```
 */
@Component({
    selector: 'l7-banner',
    templateUrl: './BannerComponent.html',
    styleUrls: ['./BannerComponent.scss'],
    exportAs: 'l7Banner',
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false,
})
export class BannerComponent {

    // #region Fields

    private _disabled: boolean;
    private _closable: boolean;
    private _color: ColorSet;
    private _appearance: Appearance;
    private _icon: string;
    private _header: string;
    private _content: string;
    private _block: boolean;
    private _iconFontSet: string;

    // #endregion

    // #region Ctor

    /**
     * Constructs a new instance of the `BannerComponent` class.
     *
     * @public
     */
    public constructor(element: ElementRef) {
        this._disabled = false;
        this._color = 'neutral';
        this._appearance = 'filled';
        this._icon = '';
        this._header = '';
        this._content = '';
        this._block = false;
        this._iconFontSet = '';
    }

    // #endregion

    // #region Properties

    /**
     * Gets or sets a value indicating whether the {@link Control} is disabled in the user interface(UI).
     * Sets the dom class property and the aria attribute as true value, otherwise nothing.
     *
     * @public
     * @type boolean
     * @default false
     */
    @Input()
    @HostBinding('class.disabled')
    @HostBinding('attr.disabled')
    @HostBinding('attr.aria-disabled')
    public get disabled(): boolean {
        return this._disabled;
    }

    public set disabled(value: boolean) {
        this._disabled = value;
    }

    /**
     * Gets or sets the color set type value. This will applied as dom class property.
     *
     * @public
     * @type ColorSet | string
     * @default ColorSet.Default
     */
    @Input()
    public get color(): ColorSet | string {
        return this._color;
    }

    public set color(value: ColorSet | string) {
        this._color = value as ColorSet;
    }

    /**
     * Gets or sets the `appearance` value. This property change the appearance.
     * Appears as dom class property.
     *
     * @public
     * @type Appearance
     * @default 'default'
     */
    @Input()
    public get appearance(): Appearance | string {
        return this._appearance;
    }

    public set appearance(value: Appearance | string) {
        this._appearance = value as Appearance;
    }

    /**
     * Gets or sets a value indicating whether this element is displayed as block in the user interface(UI).
     * Sets the `block` dom class property as true value, otherwise nothing.
     *
     * @public
     * @type boolean
     * @default false
     */
    @Input()
    @HostBinding('class.block')
    public get block(): boolean {
        return this._block;
    }

    public set block(value: boolean) {
        this._block = value;
    }

    /**
     * Gets or sets the `icon` property.
     *
     * @public
     */
    @Input()
    public get icon(): string {
        return this._icon;
    }

    public set icon(value: string) {
        this._icon = value;
    }

    /**
     * Gets or sets the `iconFontSet` property.
     *
     * @public
     */
    @Input()
    public get iconFontSet(): string {
        return this._iconFontSet;
    }

    public set iconFontSet(value: string) {
        this._iconFontSet = value;
    }

    /**
     * Gets or sets the `header` property.
     *
     * @public
     */
    @Input()
    public get header(): string {
        return this._header;
    }

    public set header(value: string) {
        this._header = value;
    }

    /**
     * Gets or sets the `content` property.
     *
     * @public
     */
    @Input()
    public get content(): string {
        return this._content;
    }

    public set content(value: string) {
        this._content = value;
    }

    /**
     * Returns the `classes` property.
     *
     * @public
     * @readonly
     */
    @HostBinding('class')
    public get classes(): string {
        return HostBindingBuilder.buildClasses({
            [`${this._color}`]: true,
            [`${this._appearance}`]: true,
        });
    }

    @Input()
    public get closable(): boolean {
        return this._closable;
    }

    public set closable(value: boolean) {
        this._closable = value;
    }

    @Output()
    public closed: EventEmitter<boolean> = new EventEmitter<boolean>();

    // #endregion

    // #region Methods

    public closeBanner(): void {
        this.closed.emit(true);
    }

    // #endregion

}
