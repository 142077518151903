<!--#region Templates -->

<ng-template #partTemplate>
    <ng-content select="[l7FilterPart]"></ng-content>
</ng-template>

<ng-template #clearAllTemplate>
    <ng-content select="[l7FilterClearAll]"></ng-content>
</ng-template>

<ng-template #nonExpandableTemplate>
    <div fxLayout="row"
         fxLayoutGap="16px">
        <ng-container *ngTemplateOutlet="partTemplate"></ng-container>
        <div fxLayoutAlign="start start">
            <div style="margin: 16px 0;">
                <ng-container *ngTemplateOutlet="clearAllTemplate"></ng-container>
            </div>
        </div>
    </div>
</ng-template>

<ng-template #expandableTemplate>
    <mat-expansion-panel class="mat-expansion-panel-outlined mat-elevation-z">
        <mat-expansion-panel-header matRipple>
            <mat-panel-title fxLayoutGap="16">
                <span>{{ this.title }}</span>
                <ng-container *ngIf="this.collectChangesCount() as count">
                    <span class="badge">{{ count }}</span>
                </ng-container>
            </mat-panel-title>

        </mat-expansion-panel-header>
        <div fxLayout="column">
            <ng-container *ngTemplateOutlet="partTemplate"></ng-container>
            <div fxLayoutAlign="end end">
                <ng-container *ngTemplateOutlet="clearAllTemplate"></ng-container>
            </div>
        </div>
    </mat-expansion-panel>
</ng-template>

<!-- #endregion -->

<ng-container *ngTemplateOutlet="this.expandable ? expandableTemplate : nonExpandableTemplate"></ng-container>