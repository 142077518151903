/* libs/presentation/src/Components/address-card/address-card.component.scss */
.mat-mdc-card {
  border-radius: 2px;
}
::ng-deep .content {
  width: 100%;
}
::ng-deep .content .row {
  display: flex;
  flex-direction: row;
  margin-bottom: 20px;
}
::ng-deep .content .row .col-50 {
  display: flex;
  flex-direction: column;
  width: 50%;
}
::ng-deep .content .row .col-50 h4 {
  font-weight: bold;
  margin: 0;
}
::ng-deep .content .row .col-50 h4,
::ng-deep .content .row .col-50 p {
  font-size: 14px;
}
/*# sourceMappingURL=address-card.component.css.map */
