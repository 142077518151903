<div>
    <div class="abcfinance-lettering-logo-wrapper">
        <img src="assets/images/abc_logo_white.svg"
             alt="Logo der abcfinance">
    </div>
    <mat-card fxFlex
              fxLayout="column"
              ngClass.lt-md="lt-md"
              l7Size="480px"
              maxSize="480px"
              class="container">
        <mat-card-header fxLayout="column-reverse">
            <l7-brand [compact]="false"></l7-brand>
            <p matCardTitle>{{ ((this.presenter.isInterveningNeeded | async) ? 'auth.account' : 'auth.login') | translate }}</p>
        </mat-card-header>
        <mat-card-content fxFlex>
            <form *ngIf="(this.presenter.isInterveningNeeded | async) === false"
                  fxLayout="column"
                  [formGroup]="this.presenter.form | async"
                  (ngSubmit)="this.presenter.onSubmit()">
                <div fxLayout="column"
                     fxLayoutGap="32px"
                     fxFlex>
                    <mat-form-field class="block"
                                    appearance="outline">
                        <mat-label>{{ 'auth.input.email.label' | translate }}</mat-label>
                        <input matInput
                               required
                              
                               type="email"
                               formControlName="email"
                               name="email"
                               autocomplete="email"
                               [attr.test-id]="'EmailInput'">
                        <mat-error *ngIf="(this.presenter.form  | async).controls['email'].hasError('required')">
                            <p>{{ 'auth.input.email.error.required' | translate }}</p>
                        </mat-error>
                        <mat-error *ngIf="(this.presenter.form  | async).controls['email'].hasError('email')">
                            <p>{{ 'auth.input.email.error.email' | translate }}</p>
                        </mat-error>
                    </mat-form-field>
                    <div fxLayoutAlign="center center">
                        <button mat-flat-button
                                type="submit"
                                [attr.test-id]="'SubmitButton'"
                                color="primary">{{ 'auth.next' | translate }}</button>
                    </div>
                </div>
            </form>
            <form *ngIf="this.presenter.isInterveningNeeded | async"
                  fxLayout="column">
                <mat-selection-list class="no-padding"
                                    [multiple]="false">
                    <mat-list-item *ngFor="let account of this.presenter.accounts | async"
                                   (click)="this.presenter.onAccount(account)">
                        <mat-icon matListItemIcon
                                  [fontSet]="'l7'"
                                  [fontIcon]="'User_Circle'"></mat-icon>
                        <p matListItemTitle>{{ ('auth.realm.' + (account.realm | lowercase))  | translate }}</p>
                    </mat-list-item>
                    <mat-list-item (click)="this.presenter.onBack()">
                        <mat-icon matListItemIcon
                                  [fontSet]="'l7'"
                                  [fontIcon]="'Plus'"></mat-icon>
                        <p matListItemTitle>{{ 'auth.otherAccount' | translate }}</p>
                    </mat-list-item>
                </mat-selection-list>
            </form>
        </mat-card-content>
        <mat-card-footer class="footer"
                         fxLayout="row"
                         fxLayoutAlign="center">
            <a class="link grey"
               [routerLink]="['/imprint']"
               target="_blank"
               routerLinkActive="active-link">{{ "global.impressum" | translate }}</a>
            <l7-text-block [text]="'•'"></l7-text-block>
            <a class="link grey"
               [routerLink]="['/privacy-notice']"
               target="_blank"
               routerLinkActive="active-link">{{ "global.data.protection" | translate }}</a>
        </mat-card-footer>
    </mat-card>
    <div class="abcfinance-circle-wrapper">
        <img src="assets/images/background_circle.png"
             alt="Logo der abcfinance">
    </div>
</div>