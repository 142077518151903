// #region Imports

import { Injectable } from '@angular/core';

// #endregion

// #region Imports

// #endregion

/**
 * Represents the `IMotionPreset` interface.
 *
 * @public
 */
export interface IMotionPreset {

    // #region Properties

    from: Record<string, string | Array<string> | number | null | Array<number | null> | undefined>;

    to: Record<string, string | Array<string> | number | null | Array<number | null> | undefined>;

    options?: KeyframeAnimationOptions;

    // #endregion

}

/**
 * The `MotionCache` class.
 *
 * @public
 */
@Injectable()
export class MotionCache {

    // #region Fields

    private readonly _registry: Map<string, IMotionPreset>;

    // #endregion

    // #region Ctor

    /**
     * Constructs a new instance of the `MotionCache` class.
     *
     * @public
     */
    public constructor() {
        this._registry = new Map();
    }

    // #endregion

    // #region Methods

    /**
     * @public
     */
    public set(key: string, value: IMotionPreset): void {
        this._registry.set(key, value);
    }

    /**
     * @public
     */
    public remove(key: string): void {
        this._registry.delete(key);
    }

    /**
     * @public
     */
    public get(name: string): IMotionPreset {
        return this._registry.get(name);
    }

    /**
     * @public
     */
    public all(): ReadonlyArray<IMotionPreset> {
        return Array.from(this._registry.values());
    }

    // #endregion

}
