import { CommonModule } from '@angular/common';
import { Component, Input, NgModule } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';

@Component({
    selector: 'l7-section-header',
    templateUrl: './section-header.component.html',
    styleUrls: ['./section-header.component.scss'],
    standalone: false,
})
export class SectionHeaderComponent {

    @Input() icon: any;
    @Input() title: string;

}

@NgModule({
    imports: [
        CommonModule,
        MatIconModule,
    ],
    declarations: [
        SectionHeaderComponent,
    ],
    exports: [
        SectionHeaderComponent,
    ],
    // providers: [],
})
export class SectionHeaderModule { }
