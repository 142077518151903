// #region Imports

import { Injectable, Injector, type InjectionToken } from '@angular/core';
import { StatelessInjector } from '../Injection/StatelessInjector';

// #endregion

/**
 * The `InjectorFactory` class.
 *
 * @public
 */
@Injectable({ providedIn: 'root' })
export class InjectorFactory {

    // #region Fields

    private readonly _injector: Injector;
    private readonly _injectors: WeakMap<Array<[InjectionToken<object>, object | null]>, Injector>;

    // #endregion

    // #region Ctor

    /**
     * Constructs a new instance of the `InjectorFactory` class.
     *
     * @public
     */
    public constructor(injector: Injector) {
        this._injector = injector;
        this._injectors = new WeakMap();
    }

    // #endregion

    // #region Methods

    /**
     * @public
     */
    public create<T extends object>(values: Array<[InjectionToken<T>, T | null]>): Injector {
        if (this._injectors.has(values)) {
            this._injectors.get(values);
        }

        const injector = new StatelessInjector(this._injector, values);
        this._injectors.set(values, injector);

        return injector;
    }

    /**
     * @public
     */
    public teardown<T extends object>(values: Array<[InjectionToken<T>, T | null]>): void {
        this._injectors.delete(values);
    }

    // #endregion

}
