/* libs/presentation/src/Components/signature/signature.component.scss */
::ng-deep signature-pad canvas {
  border-style: dashed;
  border-width: 1px;
  width: 100%;
  min-height: 300px;
  background-color: #CDD3D7;
}
.background {
  min-height: 300px;
}
.align-center {
  margin-top: 20px;
  text-align: center;
}
.align-center a {
  color: #19485A;
  font-size: 16px;
  font-weight: 300;
}
/*# sourceMappingURL=signature.component.css.map */
