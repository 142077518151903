<div fxLayout="row">
    <mat-form-field fxFlex.lt-md="100" fxFlex="50" appearance="outline" [formGroup]="formGroup">
        <mat-label>E-MAIL</mat-label>
        <input formControlName="email" [matChipInputFor]="chipList" [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
               (matChipInputTokenEnd)="addChip($event)" [errorStateMatcher]="errorMatcher" [attr.test-id]="'EmailInput'"
               matInput>
    </mat-form-field>
</div>
<div fxLayout="row">
    <mat-chip-grid #chipList aria-label="Emails list">
        <mat-chip-row *ngFor="let email of emails" [removable]="removable"
                  (removed)="removeChip(email)">
            {{email}}
            <mat-icon matChipRemove *ngIf="removable">cancel</mat-icon>
        </mat-chip-row>
    </mat-chip-grid>
</div>
