export enum UploadDocumentType {
    IDFRONT = 'IDFRONT',
    IDBACK = 'IDBACK',
    SIGNATURE = 'SIGNATURE',
    CONTRACT = 'CONTRACT',
    ACKNOWLEDGEMENT = 'ACKNOWLEDGEMENT',
    ACKNOWLEDGEMENT_SIGNED = 'ACKNOWLEDGEMENT_SIGNED',
    ACKNOWLEDGEMENT_SIGNED_PAPER = 'ACKNOWLEDGEMENT_SIGNED_PAPER',
    EXCLUSION_OF_WARRANTY = 'EXCLUSION_OF_WARRANTY',
    KUEV = 'KUEV',
    PURCHASE_ENTRY = 'PURCHASE_ENTRY',
    INVOICE = 'INVOICE',
    PRE_VENDOR_INVOICE = 'PRE_VENDOR_INVOICE',
    VENDOR_OFFER = 'VENDOR_OFFER',
    PROOF_OF_PAYMENT = 'PROOF_OF_PAYMENT',
    REGISTRATION_CERTIFICATE_PART_2 = 'REGISTRATION_CERTIFICATE_PART_2',
    DELIVERY_NOTE = 'DELIVERY_NOTE',
    OTHER = 'OTHER',
}
