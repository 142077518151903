<div class="wizard">
    <div class="wizard-step"
         *ngFor="let step of this.steps; let i = index"
         [class.active]="selectedIndex === i">
        <div class="wizard-step-label">{{step.label}}</div>
        <div class="wizard-step-content">
            <ng-container *ngTemplateOutlet="step.content"></ng-container>
        </div>
    </div>

    <!-- <div class="wizard-navigation">
        <button mat-button
                (click)="previous()"
                [disabled]="selectedIndex === 0">Previous</button>
        <button mat-button
                (click)="next()"
                [disabled]="selectedIndex === steps.length - 1">Next</button>
    </div> -->
</div>