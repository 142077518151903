/* libs/presentation/src/Components/object-value-changed/object-value-changed.component.scss */
:host {
  display: block;
  width: 100%;
  height: 100%;
  text-align: initial;
}
:host legend {
  font-size: 14px;
  line-height: 20px;
  font-weight: bold;
  color: #83929b;
}
:host fieldset {
  margin-bottom: 16px;
}
/*# sourceMappingURL=object-value-changed.component.css.map */
