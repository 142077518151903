import { UntypedFormGroup } from '@angular/forms';
import * as _ from 'underscore';

export const formValuesAreEqual = (values1, values2) => _.isEqual(values1, values2);

export const arraysAreEqual = (array1, array2) => _.isEqual(_.sortBy(array1), _.sortBy(array2));

export const getDirtyValues = (form: UntypedFormGroup): object => {
    const dirtyValues = {};

    Object.keys(form.controls)
        .forEach((key) => {
            const currentControl = form.controls[key];

            if (currentControl.dirty) {
                dirtyValues[key] = currentControl.value;
            }
        });

    return dirtyValues;
};
