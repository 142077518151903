import { VerificationService } from '@abcfinlab/api/global';
import { TranslationFacade } from '@abcfinlab/core';
import { BusyBoxService } from '@abcfinlab/ui';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { MatChipInputEvent } from '@angular/material/chips';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Subscription } from 'rxjs';
import { ErrorHandlerService } from '../../../../../apps/l7/src/app/shared/services/errorHandler/error-handler.service';
import { NotNullValeErrorStateMatcher } from '../../Helpers/NotNullValeErrorStateMatcher.class';

@UntilDestroy()
@Component({
    selector: 'l7-email-input-with-chips',
    templateUrl: './email-input-with-chips.component.html',
    styleUrls: ['./email-input-with-chips.component.scss'],
    standalone: false,
})
export class EmailInputWithChipsComponent {

    @Input() formGroup: UntypedFormGroup;

    @Input() set prefilledEmails(value: Array<string>) {
        if (value) {
            this.emails = value;
        }
    }

    @Output() emailList: EventEmitter<Array<string>> = new EventEmitter<Array<string>>();

    errorMatcher = new NotNullValeErrorStateMatcher();

    emails: Array<string> = [];

    removable = true;

    private _subscriptionEmailErrorDialog: Subscription;
    private _subscriptionEmailVerification: Subscription;

    readonly separatorKeysCodes: Array<number> = [ENTER, COMMA];

    constructor(private readonly emailVerification: VerificationService,
        private readonly errorHandler: ErrorHandlerService,
        private readonly _translate: TranslationFacade,
        private readonly _busyBoxService: BusyBoxService) { }

    addChip(event: MatChipInputEvent): void {
        const input = event.input;
        const email = event.value;

        const regexp = new RegExp('[A-Za-z0-9._%-]+@[A-Za-z0-9._%-]+\\.[a-z]{2,}');

        const isEmailValid = regexp.test(email) && this.formGroup.controls.email.valid;

        if ((email || '').trim() && isEmailValid) {
            if (this._subscriptionEmailVerification) {
                this._subscriptionEmailVerification.unsubscribe();
            }

            this._subscriptionEmailVerification = this._busyBoxService.show(null, null, this.emailVerification.verifyEmail({ emailAddress: email }))
                .pipe(untilDestroyed(this))
                .subscribe((_) => {
                    this.resetAndEmit(input, email);
                },
                (_error) => {
                    if (this._subscriptionEmailErrorDialog) {
                        this._subscriptionEmailErrorDialog.unsubscribe();
                    }
                    const dialogMessage = this._translate.instant(`error.${_error.error.error}`,
                        _error.error.error_params);
                    this.errorHandler.openDialogWithIdAndBody('error.email_not_valid', _error, dialogMessage);
                    this._subscriptionEmailErrorDialog = this.errorHandler.errorDialogEmailVerification$
                        .pipe(untilDestroyed(this))
                        .subscribe((value) => {
                            if (value) {
                                this.resetAndEmit(input, email);
                            }
                        });
                });
        }
    }

    resetAndEmit(input: HTMLInputElement, email: string) {
        this.emails.push(email.trim());
        this.emailList.emit(this.emails);
        // Reset the input value
        if (input) {
            input.value = null;
            this.formGroup.get('email').setValue(null);
        }
    }

    removeChip(email: string): void {
        const index = this.emails.indexOf(email);

        if (index >= 0) {
            this.emails.splice(index, 1);
            this.emailList.emit(this.emails);
        }
    }

}
