// #region Imports

import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { RoutingstateService } from '../../../../apps/l7/src/app/shared/services/routingstate/routingstate.service';

// #endregion

/**
 * The presenter of the {@link LogoutView} view.
 *
 * @internal
 */
@Injectable()
export class LogoutViewPresenter {

    // #region Fields

    private readonly _routingState: RoutingstateService;
    private readonly _router: Router;

    // #endregion

    // #region Ctor

    /**
     * Constructs a new instance of the `LogoutViewPresenter` class.
     *
     * @public
     */
    public constructor(routingState: RoutingstateService, router: Router) {
        this._routingState = routingState;
        this._router = router;
    }

    // #endregion

    // #region Methods

    /**
     * Called before the view first displays the data-bound properties and sets the view's input properties.
     *
     * @internal
     */
    public initialize(): void {
        const history = this._routingState.getHistory();
        const isLogoutUrl = history[0] === '/logout';

        if (isLogoutUrl) {
            this.navigateToLogin();
        }
    }

    public navigateToLogin(): void {
        void this._router.navigate(['login']);
    }

    /**
     * Called before the view will be destroyed.
     * Unsubscribe Observables and detach event handlers to avoid memory leaks.
     *
     * @internal
     */
    public dispose(): void {
    }

    // #endregion

}
