export var LogLevel;
(function (LogLevel) {
  LogLevel["TRACE"] = "trace";
  LogLevel["DEBUG"] = "debug";
  LogLevel["INFO"] = "info";
  LogLevel["LOG"] = "log";
  LogLevel["WARN"] = "warn";
  LogLevel["ERROR"] = "error";
})(LogLevel || (LogLevel = {}));
export const defaultLogLevel = LogLevel.LOG;
export const allLogLevels = [LogLevel.TRACE, LogLevel.DEBUG, LogLevel.INFO, LogLevel.LOG, LogLevel.WARN, LogLevel.ERROR];
