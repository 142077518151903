/* libs/presentation/src/Components/camera/web-camera/WebCameraComponent.scss */
.container {
  position: relative;
}
.camera-feed {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
}
.mask-overlay {
  height: 100%;
  min-width: 7%;
  background-color: rgba(0, 0, 0, 0.4);
  z-index: 1;
}
.clear-area {
  background-color: transparent;
  width: 100%;
  margin: auto;
  aspect-ratio: 1.586;
  border-radius: 8px;
  box-shadow: 0 0 0 4px rgba(0, 0, 4, 0.5);
}
.overlay-container {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 10;
  display: flex;
  flex-direction: column;
}
.rounded-corners-top {
  border-radius: 8px 8px 0 0;
}
.rounded-corners-bottom {
  border-radius: 0 0 8px 8px;
}
/*# sourceMappingURL=WebCameraComponent.css.map */
