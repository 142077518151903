// #region Imports

import { Observable, of } from 'rxjs';
import { GreetingType } from './GreetingType';

// #endregion

/**
 * @public
 */
export class GreetingService {

    /**
     * The greetings change depending on the time of the day.
     * For example,
     * “Good morning” is generally used from 5:00 a.m. to 12:00 p.m. whereas
     * “Good afternoon” is appropriate for a period from 12:00 p.m. to 6:00 p.m.
     * “Good evening” is often used after 6 p.m. or when the sun goes down.
     *
     * @public
     */
    public getGreetingType(date?: Date): Observable<GreetingType> {
        const hours = date?.getHours() ?? new Date(Date.now()).getHours();
        if (hours >= 5 && hours <= 11) {
            return of(GreetingType.Morning);
        }
        if (hours >= 11 && hours <= 18) {
            return of(GreetingType.Afternoon);
        }
        if (hours >= 18 && hours <= 22) {
            return of(GreetingType.Evening);
        }

        return of(GreetingType.None);
    }

}
