/* libs/ui/src/Controls/Components/Banner/BannerComponent.scss */
my-box {
  background-color: var(--l7-info-500-color);
}
:host {
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  max-width: 100%;
  color: #596068;
  padding: 16px 8px;
  border-radius: 8px;
}
:host .close-button {
  color: #1a232d !important;
}
:host .main-container {
  width: 100%;
}
:host .banner-label-container {
  box-sizing: inherit;
  display: inherit;
  flex-direction: column;
  justify-content: center;
}
:host .banner-label-container mat-icon {
  font-size: 18px;
}
:host .banner-label-container l7-text-block {
  margin: 0 16px;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
:host .banner-label-container .banner-header {
  box-sizing: inherit;
  color: #1a232d !important;
}
:host .banner-label-container .banner-content {
  box-sizing: inherit;
  color: #1a232d !important;
}
:host.outlined {
  border: 1px solid;
  border-left: 8px solid;
  align-items: center;
  padding-left: 16px;
  border-color: currentColor;
}
:host.outlined .mat-icon {
  color: currentColor;
}
:host.primary {
  color: #1b7e9a;
}
:host.accent {
  color: #1b7e9a;
}
:host.warn,
:host.warning {
  color: #ffa800;
}
:host.info {
  color: #1b7e9a;
}
:host.danger {
  color: #ad0149;
}
:host.success {
  color: #02cc83;
}
:host.textual {
  background-color: transparent;
  padding: 0;
  border: none;
}
:host.textual.primary {
  color: #1b7e9a;
}
:host.textual.accent {
  color: #1b7e9a;
}
:host.textual.warn,
:host.textual.warning {
  color: #ffa800;
}
:host.textual.info {
  color: #1b7e9a;
}
:host.textual.danger {
  color: #ad0149;
}
:host.textual.success {
  color: #02cc83;
}
/*# sourceMappingURL=BannerComponent.css.map */
