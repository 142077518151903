import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { SetLoadingState, SwitchViewMode } from '../Actions/Config.actions';

export interface IScreenStateConfig {
    mobile: boolean;
    loading: boolean;
}

export class ConfigStateModel {

    screen: IScreenStateConfig;

}

@State<ConfigStateModel>({
    name: 'config',
    defaults: {
        screen: {
            mobile: true,
            loading: false,
        },
    },
})

@Injectable()
export class ConfigState {

    @Selector()
    static getMobile(state: ConfigStateModel) {
        return state.screen.mobile;
    }

    @Selector()
    static getLoading(state: ConfigStateModel) {
        return state.screen.loading;
    }

    @Action(SwitchViewMode)
    switchViewMode(context: StateContext<ConfigStateModel>, { mobile }: SwitchViewMode) {
        const state = context.getState();
        context.setState({
            ...state,
            screen: {
                loading: state.screen.loading,
                mobile,
            },
        });
    }

    @Action(SetLoadingState)
    setLoadingState(context: StateContext<ConfigStateModel>, { loading }: SetLoadingState) {
        const state = context.getState();
        context.setState({
            ...state,
            screen: {
                loading,
                mobile: state.screen.mobile,
            },
        });
    }

}
