/* libs/ui/src/Controls/Components/Persona/PersonaComponent.scss */
my-box {
  background-color: var(--l7-info-500-color);
}
:host {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  font-size: 1em;
  text-transform: uppercase;
  border: 2px solid #fff;
  font-weight: bold;
}
:host.primary {
  background: #e4f0f3;
  color: #1b7e9a;
}
:host.accent {
  background: #e4f0f3;
  color: #1b7e9a;
}
:host.warn {
  background: #f5e1e9;
  color: #ad0149;
}
:host.info {
  background: #e4f0f3;
  color: #1b7e9a;
}
:host.danger {
  background: #f5e1e9;
  color: #ad0149;
}
:host.success {
  background: #e1f9f0;
  color: #02cc83;
}
/*# sourceMappingURL=PersonaComponent.css.map */
