export class SwitchViewMode {

    static readonly type = '[CONFIG] Switch View-Mode';
    constructor(public mobile: boolean) {
    }

}

export class SetLoadingState {

    static readonly type = '[CONFIG] Set loading state';
    constructor(public loading: boolean) {
    }

}
