/* libs/backoffice/src/Views/SettlementOverviewView.scss */
:host {
  display: block;
  width: 100%;
  height: 100%;
}
:host .clear-all-container {
  margin: 16px 0;
}
:host .sticky-banner {
  position: sticky;
  top: 0;
  z-index: 99;
  background-color: #fafafa;
  margin-bottom: 8px;
}
:host .row-detail-container {
  overflow: hidden;
  padding: 24px 16px;
}
:host .empty {
  width: 100wv;
  height: 100hv;
}
:host mat-table .mat-mdc-cell:nth-child(1),
:host mat-table .mat-mdc-header-cell:nth-child(1),
:host mat-table .mat-mdc-cell:nth-child(2),
:host mat-table .mat-mdc-header-cell:nth-child(2) {
  flex: 0 0 20%;
}
:host mat-table .mat-mdc-cell:nth-child(3),
:host mat-table .mat-mdc-header-cell:nth-child(3) {
  flex: 0 0 20%;
}
:host mat-table .mat-mdc-cell:nth-child(4),
:host mat-table .mat-mdc-header-cell:nth-child(4),
:host mat-table .mat-mdc-cell:nth-child(5),
:host mat-table .mat-mdc-header-cell:nth-child(5),
:host mat-table .mat-mdc-cell:nth-child(6),
:host mat-table .mat-header-cell:nth-child(6) {
  flex: 0 0 10%;
}
:host mat-table mat-row {
  cursor: pointer;
  border: none;
  position: relative;
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
}
:host mat-table mat-row.expanded {
  background-color: whitesmoke;
}
:host mat-table mat-row:hover {
  background: #efefef;
}
/*# sourceMappingURL=SettlementOverviewView.css.map */
