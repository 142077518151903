// #region Imports

import { AuthService, IRealmDto, IUserInfoDto, Oauth2Service } from '@abcfinlab/api/login';
import { USER_INFO_TOKEN } from '@abcfinlab/core';
import { Inject, Injectable } from '@angular/core';
import { IUserGroupLoginsDto } from 'libs/auth/src/Models/IUserGroupLoginsDto';
import { IUserGroupRetailerDto } from 'libs/auth/src/Models/IUserGroupRetailerDto';
import { BehaviorSubject, Observable, ReplaySubject } from 'rxjs';
import { tap } from 'rxjs/operators';

// #endregion

/**
 * The `UserService` class.
 *
 * @public
 */
@Injectable()
export class UserService {

    // #region Fields

    private readonly _userInfoSubject: ReplaySubject<IUserInfoDto>;
    private readonly _userGroupsSubject: BehaviorSubject<Array<IUserGroupLoginsDto> | Array<IUserGroupRetailerDto>> = new BehaviorSubject<Array<IUserGroupLoginsDto> | Array<IUserGroupRetailerDto>>(null);
    private readonly _auth2Service: AuthService;
    private readonly _oauth2Service: Oauth2Service;

    // #endregion

    // #region Ctor

    /**
     * Constructs a new instance of the `UserService` class.
     *
     * @public
     */
    public constructor(auth2Service: AuthService, oauth2Service: Oauth2Service, @Inject(USER_INFO_TOKEN) userInfo: ReplaySubject<IUserInfoDto>) {
        this._auth2Service = auth2Service;
        this._oauth2Service = oauth2Service;
        this._userInfoSubject = userInfo;
    }

    // #endregion

    // #region Properties

    /**
     * Returns the `user` property.
     *
     * @public
     * @readonly
     */
    public get userInfo(): Observable<IUserInfoDto> {
        return this._userInfoSubject.asObservable();
    }

    /**
     * Returns the `userGroups` property.
     *
     * @public
     * @readonly
     */
    public get userGroups(): Observable<Array<IUserGroupLoginsDto> | Array<IUserGroupRetailerDto>> {
        return this._userGroupsSubject.asObservable();
    }

    // #endregion

    // #region Methods

    /**
     * @public
     */
    public getUserInfo(): Observable<IUserInfoDto> {
        return this._auth2Service.getUserInfo().pipe(
            tap((x) => {
                this._userInfoSubject.next(x);
                if (x.realm === IRealmDto.Logins) {
                    this._userGroupsSubject.next(x.groups as Array<IUserGroupLoginsDto>);
                } else {
                    this._userGroupsSubject.next(x.groups as Array<IUserGroupRetailerDto>);
                }
            }),
        );
    }

    /**
     * @public
     */
    public openAccountPage(): Observable<{ account_url: string }> {
        return this._oauth2Service.accountsUrl() as any;
    }

    // #endregion

}
