import { TranslationModule } from '@abcfinlab/core';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatTooltipModule } from '@angular/material/tooltip';
import { DatePickerComponent } from './DatePickerComponent';

@NgModule({
    declarations: [DatePickerComponent],
    imports: [
        CommonModule,
        MatInputModule,
        MatIconModule,
        MatDatepickerModule,
        ReactiveFormsModule,
        MatTooltipModule,
        TranslationModule,
    ],
    exports: [DatePickerComponent],
})
export class DatePickerModule { }
