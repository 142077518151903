// #region Import

import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, CanActivateChild, Router, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { SessionService } from '../Services/SessionService';

// #endregion

/**
 * @public
 */
@Injectable()
export class LoginGuard implements CanActivate, CanActivateChild {

    // #region Fields

    private readonly _router: Router;
    private readonly _sessionService: SessionService;

    // #endregion

    // #region Ctor

    /**
     * Constructs a new instance of the `LoginGuard` class.
     *
     * @public
     */
    public constructor(router: Router, sessionService: SessionService) {
        this._router = router;
        this._sessionService = sessionService;
    }

    // #endregion

    // #region Methods

    public canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
        return this._sessionService.hasSession().pipe(
            map((x) => {
                if (x) {
                    if (route.url.join() === 'login') {
                        this._router.navigate(['']);
                        return false;
                    } else {
                        return true;
                    }
                } else {
                    if (route.url.join() === 'login') {
                        return true;
                    } else {
                        this._router.navigate(['login']);
                        return false;
                    }
                }
            }),
        );
    }

    public canActivateChild(childRoute: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
        return this.canActivate(childRoute, state);
    }

    // #endregion

}
