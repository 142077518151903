<ng-container *ngIf="initial_check_performed">
  <div class="camera-indicator"
       [ngClass]="classes">
    <button mat-icon-button
            [disabled]="camera_is_available"
            (click)="requestCameraPermission()">
      <mat-icon [fontSet]="'l7'"
                class="camera-indicator-icon"
                [fontIcon]="'Camera'"></mat-icon>
    </button>
  </div>
</ng-container>
