<!-- <ng-template #defaultContentTemplate
             let-data>
    <l7-text-block [truncate]="true"
                   [text]="data.row | memberValue:data.column"></l7-text-block>
</ng-template>


<mat-table [attr.test-id]="'OverviewTable'"
           [dataSource]="this.dataSource">
    <ng-container *ngFor="let column of this.columns"
                  [matColumnDef]="column">
        <mat-header-cell *matHeaderCellDef>
            <l7-text-block [truncate]="true"
                           [text]="column + '_translate'"></l7-text-block>
        </mat-header-cell>
        <mat-cell *matCellDef="let row">
            <ng-container *l7SlotOutlet="(slots | slot: column) ?? defaultContentTemplate; context: { $implicit: { data: { row: row, column: column }}}"></ng-container>
        </mat-cell>
    </ng-container>
    <mat-header-row *matHeaderRowDef="this.columns"></mat-header-row>
    <mat-row *matRowDef="let row; columns: this.columns;"
             matRipple
             (click)="this.onRowSelected(row)"></mat-row>
</mat-table> -->