/*
 * Copyright The OpenTelemetry Authors
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      https://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
/**
 * @deprecated use the one declared in @opentelemetry/sdk-trace-base instead.
 * A sampling decision that determines how a {@link Span} will be recorded
 * and collected.
 */
export var SamplingDecision;
(function (SamplingDecision) {
  /**
   * `Span.isRecording() === false`, span will not be recorded and all events
   * and attributes will be dropped.
   */
  SamplingDecision[SamplingDecision["NOT_RECORD"] = 0] = "NOT_RECORD";
  /**
   * `Span.isRecording() === true`, but `Sampled` flag in {@link TraceFlags}
   * MUST NOT be set.
   */
  SamplingDecision[SamplingDecision["RECORD"] = 1] = "RECORD";
  /**
   * `Span.isRecording() === true` AND `Sampled` flag in {@link TraceFlags}
   * MUST be set.
   */
  SamplingDecision[SamplingDecision["RECORD_AND_SAMPLED"] = 2] = "RECORD_AND_SAMPLED";
})(SamplingDecision || (SamplingDecision = {}));
