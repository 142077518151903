/* libs/ui/src/Controls/Components/Filter/FilterComponent.scss */
:host {
  display: block;
  width: 100%;
  height: 100%;
  margin: 0 0 16px;
}
:host .mat-expansion-panel-outlined {
  box-shadow:
    0px 0px 0px 0px rgba(0, 0, 0, 0.2),
    0px 0px 0px 0px rgba(0, 0, 0, 0.14),
    0px 0px 0px 0px rgba(0, 0, 0, 0.12) !important;
  border-width: 1px;
  border-style: solid;
  border-color: #e0e0e0;
}
:host .badge {
  color: white;
  background: #1b7e9a;
  width: 22px;
  height: 22px px;
  line-height: 22px;
  text-align: center;
  border-radius: 50%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  pointer-events: none;
  font-weight: 600;
  font-size: 12px;
  font-family: "Nunito Sans", sans-serif;
}
/*# sourceMappingURL=FilterComponent.css.map */
