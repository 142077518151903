// #region Imports

import { Directive, Input, OnChanges, SimpleChanges, TemplateRef, ViewContainerRef } from '@angular/core';
import { FeatureManager } from '../Services/FeatureManager';

// #endregion

/**
 * Structural directive for manipulating content of the template.
 *
 * @public
 */
@Directive({
    selector: '[l7Feature]',
    standalone: false,
})
export class FeatureDirective implements OnChanges {

    // #region Fields

    private readonly _viewContainerRef: ViewContainerRef;
    private readonly _templateRef: TemplateRef<unknown>;
    private readonly _featureManager: FeatureManager;

    private _feature: string;
    private _hasView: boolean;

    // #endregion

    // #region Ctor

    public constructor(viewContainerRef: ViewContainerRef, templateRef: TemplateRef<unknown>, featureManager: FeatureManager) {
        this._viewContainerRef = viewContainerRef;
        this._templateRef = templateRef;
        this._featureManager = featureManager;

        this._feature = '';
        this._hasView = false;
    }

    // #endregion

    // #region Properties

    /**
     * Gets or sets the `feature` property.
     *
     * @public
     */

    @Input('l7Feature')
    public get feature(): string {
        return this._feature;
    }

    public set feature(value: string) {
        if (this._feature !== value) {
            this._feature = value;
        }
    }

    // #endregion

    // #region Methods

    /**
     * @public
     */
    public ngOnChanges(changes: SimpleChanges): void {
        if (changes.feature) {
            this.invalidate();
        }
    }

    /**
     * @private
     */
    private invalidate(): void {
        if (this._featureManager.active(this._feature) && !this._hasView) {
            this._hasView = true;
            this._viewContainerRef.createEmbeddedView(this._templateRef);
        }
        if (!this._featureManager.active(this._feature) && this._hasView) {
            this._hasView = false;
            this._viewContainerRef.clear();
        }
    }

    // #endregion

}
