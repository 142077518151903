import { IUserInfoDto } from '@abcfinlab/api/login';
import { IUserGroupLoginsDto } from '@abcfinlab/auth';
import { FeatureManager, RoleManager, RoleModule, SESSION_AVAILABLE_TOKEN, TranslationFacade, TranslationModule, USER_INFO_TOKEN } from '@abcfinlab/core';
import { PresenterService } from '@abcfinlab/layout';
import { Inject, NgModule } from '@angular/core';
import { combineLatest, ReplaySubject, Subject } from 'rxjs';
import { first } from 'rxjs/operators';
import { CONFIGURATION_OVERVIEW_ROUTE_PATH } from '../Routing/RoutePaths';

@NgModule({
    imports: [
        TranslationModule,
        RoleModule,
    ],
})
export class ConfigurationNavigationModule {

    // #region Ctor

    /**
     * Constructs a new instance of the `ConfigurationNavigationModule` class.
     *
     * @public
     */
    public constructor(@Inject(SESSION_AVAILABLE_TOKEN) sessionAvailable: Subject<void>, @Inject(USER_INFO_TOKEN) userInfo: ReplaySubject<IUserInfoDto>, presenterService: PresenterService,
        roleManager: RoleManager, translationFacade: TranslationFacade, featureManager: FeatureManager) {
        combineLatest([sessionAvailable, userInfo])
            .pipe(first())
            .subscribe(([_, user]) => {
                if ((user.groups.includes(IUserGroupLoginsDto.Makler) || (user.groups.includes(IUserGroupLoginsDto.Sales) && user.groups.includes(IUserGroupLoginsDto.SelfService)))) {
                    presenterService.setNavigation({
                        label: 'Konfiguration',
                        icon: 'Gear',
                        order: 90,
                        route: CONFIGURATION_OVERVIEW_ROUTE_PATH,
                    });
                }
            });
    }

    // #endregion

}
