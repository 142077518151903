import { IUserInfoDto } from '@abcfinlab/api/global';
import { once } from '@abcfinlab/core';
import { ReplaySubject } from 'rxjs';

/**
 * A angular app initializer that set all user info groups as dom attributes.
 * It is needed for userGuiding to make coditional (role based) guidings.
 */
export function authInitializer(userInfo: ReplaySubject<IUserInfoDto>): () => void {
    return () => {
        once(userInfo, (userInfo: IUserInfoDto) => {
            const root = document.getElementsByTagName('l7-root')[0];
            userInfo.groups.forEach((group) => {
                root.setAttribute(`role-${group}`, '');
            });
        });
    };
}
