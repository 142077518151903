<div fxLayout="column"
     fxLayoutGap="24px">
    <div *ngIf="this.hasBackNavigation">
        <a fxLayout="row"
           fxLayoutAlign="start center"
           fxLayoutGap="10px"
           class="back-button"
           (click)="this.onNavigate()">
            <mat-icon [inline]="true">arrow_back</mat-icon>
            <span class="mat-body text">{{'zurück' | translate}}</span>
        </a>
    </div>
    <div fxLayout="column">
        <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="8px">
            <l7-text-block *ngIf="this.title"
                           class="title"
                           [text]="this.title"
                           [wrap]="true"
                           [maxLines]="2"></l7-text-block>
            <ng-content select="header-icon"></ng-content>
        </div>
        <l7-text-block *ngIf="this.subTitle"
                       class="title sub"
                       [text]="this.subTitle"
                       [wrap]="true"
                       [maxLines]="2"></l7-text-block>
        <l7-text-block *ngIf="this.supportiveTitle"
                       class="title supportive"
                       [text]="this.supportiveTitle"
                       [wrap]="true"
                       [maxLines]="2"></l7-text-block>
    </div>
    <ng-content></ng-content>
</div>
