<ng-container *ngIf="this.presenter.hasSession | async as hasSession">
    <div class="svg-container">
        <svg class="score-upload-success"
             [ngClass]="{'animate': hasSession}"
             xmlns="http://www.w3.org/2000/svg"
             preserveAspectRatio="xMidYMid"
             viewBox="0 0 127 85">
            <g transform="translate(10, 0)">
                <g class="score-upload-success__hand">
                    <path fill="#f6d9c1"
                          d="M59.342 58.943h-8.57c-3.364 0-6.227-2.12-7.303-5.073C42.74 51.983 36 52 36 52V41h6.997l.003.03V41c1.727 0 3.982-1.696 3.982-3.396l6.997-3.99c0 1.323 1.63 2.497 2.9 2.92.527.177 2.462.466 2.462.466v21.943z" />
                    <path fill="#7e4b26"
                          opacity="0.05"
                          d="M64.718 46.996h-8.434c-1.807 0-3.278-1.343-3.278-2.994 0-1.65 1.47-2.995 3.278-2.995h8.434c1.807 0 3.278 1.344 3.278 2.995 0 1.65-1.47 2.994-3.278 2.994zm0-6h-8.434c-1.807 0-3.278-1.343-3.278-2.994 0-1.65 1.47-2.995 3.278-2.995h8.434c1.807 0 3.278 1.344 3.278 2.995 0 1.65-1.47 2.994-3.278 2.994zm-8.434 6.01h8.434c1.807 0 3.278 1.345 3.278 2.996 0 1.65-1.47 2.994-3.278 2.994h-8.434c-1.807 0-3.278-1.343-3.278-2.994 0-1.65 1.47-2.995 3.278-2.995zm0 6h6.434c1.807 0 3.278 1.345 3.278 2.996 0 1.65-1.47 2.994-3.278 2.994h-6.434c-1.807 0-3.278-1.343-3.278-2.994 0-1.65 1.47-2.995 3.278-2.995z" />
                    <path fill="#f6d9c1"
                          class="score-upload-success__thumb"
                          d="M50.5 24c1.933 0 3.5 1.567 3.5 3.5v11c0 1.933-1.567 3.5-3.5 3.5S47 40.433 47 38.5v-11c0-1.933 1.567-3.5 3.5-3.5z" />
                    <path fill="#385463"
                          d="M38 56.004h-1.997c-2.202 0-3.993-1.92-3.993-4.28v-8.43c0-2.36 1.79-4.28 3.993-4.28H38c2.2 0 3.993 1.92 3.993 4.28v8.43c0 2.36-1.792 4.28-3.993 4.28z" />
                    <path fill="#faece1"
                          d="M67.718 46.996h-8.434c-1.807 0-3.278-1.343-3.278-2.994 0-1.65 1.47-2.995 3.278-2.995h8.434c1.807 0 3.278 1.344 3.278 2.995 0 1.65-1.47 2.994-3.278 2.994zm0-6h-8.434c-1.807 0-3.278-1.343-3.278-2.994 0-1.65 1.47-2.995 3.278-2.995h8.434c1.807 0 3.278 1.344 3.278 2.995 0 1.65-1.47 2.994-3.278 2.994zm-8.434 6.01h8.434c1.807 0 3.278 1.345 3.278 2.996 0 1.65-1.47 2.994-3.278 2.994h-8.434c-1.807 0-3.278-1.343-3.278-2.994 0-1.65 1.47-2.995 3.278-2.995zm0 6h6.434c1.807 0 3.278 1.345 3.278 2.996 0 1.65-1.47 2.994-3.278 2.994h-6.434c-1.807 0-3.278-1.343-3.278-2.994 0-1.65 1.47-2.995 3.278-2.995z" />
                    <path fill="#ffffff"
                          opacity="0.8"
                          d="M60 52c-1.105 0-2-.895-2-2s.895-2 2-2 2 .895 2 2-.895 2-2 2zm0-6c-1.105 0-2-.895-2-2s.895-2 2-2 2 .895 2 2-.895 2-2 2zm0-6c-1.105 0-2-.895-2-2s.895-2 2-2 2 .895 2 2-.895 2-2 2zm0 14c1.105 0 2 .895 2 2s-.895 2-2 2-2-.895-2-2 .895-2 2-2z" />
                </g>
            </g>
        </svg>
    </div>
</ng-container>