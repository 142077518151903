import { onCLS, onFCP, onFID, onINP, onLCP, onTTFB } from 'web-vitals';
export class WebVitalsBasic {
  constructor(pushMeasurement) {
    this.pushMeasurement = pushMeasurement;
  }
  initialize() {
    Object.entries(WebVitalsBasic.mapping).forEach(([indicator, executor]) => {
      executor(metric => {
        this.pushMeasurement({
          type: 'web-vitals',
          values: {
            [indicator]: metric.value
          }
        });
      });
    });
  }
}
WebVitalsBasic.mapping = {
  cls: onCLS,
  fcp: onFCP,
  fid: onFID,
  inp: onINP,
  lcp: onLCP,
  ttfb: onTTFB
};
