// #region Imports

import { NgModule } from '@angular/core';
import { HasRoleDirective } from './HasRoleDirective';
import { RoleGuard } from './RoleGuard';
import { RoleManager } from './RoleManager';

// #endregion

/**
 * The `RoleModule` module.
 *
 * @public
 */
@NgModule({
    declarations: [
        HasRoleDirective,
    ],
    exports: [
        HasRoleDirective,
    ],
    providers: [
        RoleManager,
        RoleGuard,
    ],
})
export class RoleModule { }
