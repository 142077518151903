/* libs/ui/src/Controls/Components/DialogHeader/DialogHeaderComponent.scss */
my-box {
  background-color: var(--l7-info-500-color);
}
:host {
  display: flex;
  justify-content: space-between;
  align-items: center;
  text-align: start;
  font: 600 24px/34px "Nunito Sans", sans-serif;
  letter-spacing: normal;
  flex-direction: row;
  margin-bottom: 20px;
  padding: 24px 24px 0 24px;
}
:host .title {
  margin: 0;
  font-weight: 600;
  font-size: 24px;
  line-height: 34px;
  color: #1a232d;
}
:host .title.sub {
  font-weight: bold;
  font-size: 16px;
  line-height: 24px;
}
:host .title.supportive {
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: rgba(26, 35, 45, 0.54);
}
:host .title:empty {
  margin: 0;
}
/*# sourceMappingURL=DialogHeaderComponent.css.map */
