// #region Imports

import { ChangeDetectionStrategy, Component, OnDestroy, OnInit, Renderer2 } from '@angular/core';
import { PresenterViewPresenter } from './PresenterViewPresenter';

// #endregion

/**
 * The `PresenterView` view.
 *
 * @public
 */
@Component({
    selector: 'l7-presenter-view',
    templateUrl: './PresenterView.html',
    styleUrls: ['./PresenterView.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [
        PresenterViewPresenter,
    ],
    standalone: false,
})
export class PresenterView implements OnInit, OnDestroy {

    // #region Fields

    private readonly _presenter: PresenterViewPresenter;

    private readonly _renderer: Renderer2;

    // #endregion

    // #region Ctor

    /**
     * Constructs a new instance of the `PresenterView` class.
     *
     * @public
     */
    public constructor(presenter: PresenterViewPresenter, renderer: Renderer2) {
        this._presenter = presenter;
        this._renderer = renderer;
    }

    // #endregion

    // #region Proeprties

    /**
     * Returns the presenter of the `PresenterView`
     *
     * @public
     * @readonly
     */
    public get presenter(): PresenterViewPresenter {
        return this._presenter;
    }

    // #endregion

    // #region Methods

    /**
     * @internal
     */
    public ngOnInit(): void {
        this._presenter.initialize();
    }

    /**
     * @internal
     */
    public ngOnDestroy(): void {
        this._presenter.dispose();
    }

    public hoverEffect(event: MouseEvent): void {
        if (event.type === 'mouseover') {
            this._renderer.addClass(event.target, 'hovered');
        } else {
            this._renderer.removeClass(event.target, 'hovered');
        }
    }

    // #endregion

}
