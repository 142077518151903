/* libs/contractManagement/src/Views/ContractOverviewView.scss */
::ng-deep .mat-mdc-tooltip.allow-cr {
  font-size: 12px;
  white-space: pre-wrap;
}
:host .clear-all-container {
  margin: 16px 0;
}
:host button i {
  padding: 8px;
}
:host button.mat-mdc-outlined-button {
  min-height: 36px;
}
:host mat-accordion mat-expansion-panel {
  border-radius: 0;
}
:host mat-accordion mat-expansion-panel.inner-expansion {
  margin-left: 24px;
  margin-right: 24px;
}
:host mat-accordion mat-expansion-panel mat-expansion-panel-header {
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
  border-radius: 0;
}
:host mat-accordion mat-expansion-panel mat-expansion-panel-header mat-panel-title .title-container mat-icon {
  font-size: 24px;
  color: #385463;
}
:host mat-accordion mat-expansion-panel mat-expansion-panel-header mat-panel-title .title-container p {
  color: #385463;
}
:host mat-accordion mat-expansion-panel mat-expansion-panel-header mat-panel-title mat-icon.status {
  font-size: 10px;
  position: absolute;
  left: 4px;
}
:host mat-table mat-header-cell[hidden],
:host mat-table mat-cell[hidden] {
  display: none;
}
:host mat-table .mat-mdc-cell:nth-child(1),
:host mat-table .mat-mdc-header-cell:nth-child(1),
:host mat-table .mat-mdc-cell:nth-child(2),
:host mat-table .mat-mdc-header-cell:nth-child(2) {
  flex: 0 0 20%;
}
:host mat-table .mat-mdc-cell:nth-child(3),
:host mat-table .mat-mdc-header-cell:nth-child(3) {
  flex: 0 0 15%;
}
:host mat-table .mat-mdc-cell:nth-child(4),
:host mat-table .mat-mdc-header-cell:nth-child(4),
:host mat-table .mat-mdc-cell:nth-child(5),
:host mat-table .mat-mdc-header-cell:nth-child(5),
:host mat-table .mat-mdc-cell:nth-child(6),
:host mat-table .mat-mdc-header-cell:nth-child(6),
:host mat-table .mat-mdc-cell:nth-child(7),
:host mat-table .mat-mdc-header-cell:nth-child(7) {
  flex: 0 0 10%;
}
:host mat-table .mat-mdc-cell:nth-child(8),
:host mat-table .mat-mdc-header-cell:nth-child(8) {
  flex: 1;
}
:host mat-table mat-row {
  cursor: pointer;
  border: none;
  position: relative;
}
:host mat-table mat-row.expanded {
  background-color: whitesmoke;
}
:host mat-table mat-row:hover {
  background: #efefef;
}
/*# sourceMappingURL=ContractOverviewView.css.map */
