export namespace Pattern {

    /**
     * Regular expression pattern for validating phone numbers in common formats.
     *
     * The pattern matches:
     * - An optional leading plus sign (`+`) for international dialing codes.
     * - A sequence of one or more digits.
     *
     * This regex does not validate the length of phone numbers or specific regional formats.
     *
     * @public
     * @example
     * // Valid phone numbers:
     * // "+1234567890"
     * // "1234567890"
     * // "123456789012"
     * // Usage
     * const isValidPhoneNumber = Pattern.phoneNumber.test('1234567890');
     * console.log(isValidPhoneNumber); // Output: true
     */
    export const phoneNumber: RegExp = /^\+?\d+$/;

    /**
     * Regular expression for validating email addresses according to typical email standards.
     *
     * Enforces constraints on both the local and domain parts of the email:
     * - Total length of 1-254 characters.
     * - The local part (before the `@` symbol):
     *   - Contains alphanumeric characters.
     *   - Allows special characters: `!#$%&'*+/=?^_`{|}~-`.
     *   - Allows dots (.) between characters, but not consecutively or at the start/end.
     * - The domain part (after the `@` symbol):
     *   - Starts and ends with an alphanumeric character.
     *   - Allows alphanumeric characters and hyphens (-) within each subdomain label.
     *   - Allows multiple subdomains separated by dots, each up to 63 characters.
     *
     * This regex does not handle all edge cases in email validation standards (e.g., quoted strings),
     * but is suitable for most typical email validation requirements.
     *
     * @public
     * @example
     * // Valid emails:
     * // "email@example.com"
     * // "user.name+tag+sorting@example.com"
     * // Usage
     * const isValidEmail = Pattern.email.test('email@example.com');
     * console.log(isValidEmail); // Output: true
     */
    export const email: RegExp = /^(?=.{1,254}$)(?=.{1,64}@)[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+)*@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/;

}
