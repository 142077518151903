// #region Imports

import { Injectable } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { combineLatest, defer, isObservable, merge, Observable, of } from 'rxjs';
import { distinctUntilChanged, map, shareReplay, startWith } from 'rxjs/operators';
import { equals } from '../Extensions/ObjectExtensions';

// #endregion

/**
 * The `FormChangesObserver` class.
 *
 * @public
 */
@Injectable({ providedIn: 'root' })
export class FormChangesObserver {

    // #region Ctor

    /**
     * Constructs a new instance of the `FormChangesObserver` class.
     *
     * @public
     */
    public constructor() {
        // Nothing to do here...
    }

    // #endregion

    // #region Methods

    /**
     * @public
     */
    public observe<TData extends object>(formGroup: FormGroup, source: Observable<TData> | TData): Observable<boolean> {
        const value = (): TData => formGroup.getRawValue();

        return new Observable((observer) => {
            const hasChanges: Observable<boolean> = combineLatest([
                isObservable(source) ? source : of(source),
                merge(
                    defer(() => of(value())),
                    formGroup.valueChanges.pipe(
                        distinctUntilChanged(),
                        map(value),
                    ),
                ),
            ]).pipe(
                map(([a, b]) => !equals(a, b)),
                startWith(false),
                shareReplay({ bufferSize: 1, refCount: true }),
            );

            return hasChanges.subscribe(observer);
        });
    }

    // #endregion

}
