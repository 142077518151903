import { CdkStep, CdkStepper } from '@angular/cdk/stepper';
import { Component, Inject, forwardRef } from '@angular/core';
import { WizardComponent } from './WizardComponent';

@Component({
    selector: 'l7-wizard-step',
    templateUrl: './WizardStepComponent.html',
    styleUrls: ['./WizardStepComponent.scss'],
    providers: [
        { provide: CdkStep, useExisting: WizardStepComponent },
    ],
    standalone: false,
})
export class WizardStepComponent extends CdkStep {

    public constructor(@Inject(forwardRef(() => WizardComponent)) wizard: WizardComponent) {
        super(wizard as unknown as CdkStepper);
    }

}
