// #region Imports

import { NgModule } from '@angular/core';
import { SizeDirective } from './SizeDirective';

// #endregion

/**
 * The `FormTrackModule`.
 *
 * @public
 */
@NgModule({
    declarations: [
        SizeDirective,
    ],
    exports: [
        SizeDirective,
    ],
})
export class SizeDirectiveModule { }
