import { IUserInfoDto } from '@abcfinlab/api/login';
import { AuthService, UserService } from '@abcfinlab/auth';
import { AppConfig, FeatureManager, TranslationFacade } from '@abcfinlab/core';
import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Observable } from 'rxjs';
import { ServerErrorDTO } from '../../../models/ServerErrorDTO.interface';

@UntilDestroy()
@Component({
    selector: 'l7-auth-error-dialog',
    templateUrl: './auth-error-dialog.component.html',
    styleUrls: ['./auth-error-dialog.component.scss'],
    standalone: false,
})
export class AuthErrorDialogComponent {

    serverData: ServerErrorDTO;

    translationWithParams: string;

    filterParamsForMiddleman: Array<any>;

    constructor(
        public dialogRef: MatDialogRef<AuthErrorDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: ServerErrorDTO,
        private readonly _translationFacade: TranslationFacade,
        private readonly _authService: AuthService,
        private readonly _userService: UserService,
        private readonly _router: Router,
        private readonly _appConfig: AppConfig,
        private readonly _featureManager: FeatureManager,
    ) {
        this.serverData = data;
        this._userService.getUserInfo().pipe(untilDestroyed(this)).subscribe();
        if (this.serverData.error_params) {
            this.filterParamsForMiddleman = Object.entries(this.serverData.error_params)
                .filter(([key, value]) => value === 'vatId' || value === 'localCourt' || value === 'registerNo');
            const translationKey = `error.${this.serverData.error}`;
            this.translationWithParams = this._translationFacade.instant(translationKey, this.serverData.error_params);
        }
    }

    public get user(): Observable<IUserInfoDto> {
        return this._userService.userInfo;
    }

    public onCloseDialog(params, isMiddleman?: boolean): void {
        if (params) {
            this._authService.logout();
        }
        if (isMiddleman) {
            this.dialogRef.afterClosed().subscribe(() => {
                this._userService.openAccountPage()
                    .pipe(untilDestroyed(this))
                    .subscribe((url) => {
                        window.open(url.account_url);
                    });
            });
        } else {
            const loginUrl = `${String(this._appConfig.get('host'))}/login-service/api/login`;

            this.dialogRef.afterClosed().subscribe(() => {
                console.debug('Redirect to login');

                if (this._featureManager.active('login')) {
                    this._router.navigate(['login']);
                } else {
                    window.location.assign(loginUrl);
                }
            });
        }

        this.dialogRef.close();
    }

}
