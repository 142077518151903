// #region Imports

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { WrapComponent } from './WrapComponent';

// #endregion

/**
 * @public
 */
@NgModule({
    imports: [
        CommonModule,
    ],
    exports: [
        WrapComponent,
    ],
    declarations: [
        WrapComponent,
    ],
})
export class WrapModule {
}
