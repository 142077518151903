import { AuthService, UserService } from '@abcfinlab/auth';
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { combineLatest, Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { ErrorHandlerService } from '../services/errorHandler/error-handler.service';

@Injectable({
    providedIn: 'root',
})
export class AuthGuard implements CanActivate {

    constructor(private readonly _authService: AuthService, private readonly _userService: UserService, private readonly _router: Router, private readonly _errorHandler: ErrorHandlerService) {

    }

    /**
     * Protects the 'private'-Route from being access by a non authorized user
     */
    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
        return combineLatest([
            this._authService.getSession(),
            this._userService.getUserInfo(),
        ]).pipe(
            map(([session, userInfo]) => {
                if (userInfo.userId) {
                    return true;
                } else {
                    this._authService.removeSession();
                }
            }),
            catchError((err) => {
                this._authService.removeSession();
                this._router.navigate([''], { queryParams: { returnUrl: state.url } }).then();
                return of(false);
            }),
        );
    }

}
