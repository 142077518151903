<div>
    <div fxLayout="row" fxLayoutGap="8px" fxLayoutAlign="center center" *ngIf="titleIcon">
        <mat-icon matSuffix
                  *ngIf="titleIcon"
                  [color]="titleIconColor"
                  [fontSet]="'l7'"
                  [fontIcon]="titleIcon"></mat-icon>
        <l7-text-block *ngIf="this.title"
                       class="title"
                       [text]="this.title"
                       [wrap]="true"
                       [maxLines]="2"></l7-text-block>
    </div>
    <l7-text-block *ngIf="this.title && !this.titleIcon"
                   class="title"
                   [text]="this.title"
                   [wrap]="true"
                   [maxLines]="2"></l7-text-block>
    <l7-text-block *ngIf="this.subTitle"
                   class="title sub"
                   [text]="this.subTitle"
                   [wrap]="true"
                   [maxLines]="2"></l7-text-block>
    <l7-text-block *ngIf="this.supportiveTitle"
                   class="title supportive"
                   [text]="this.supportiveTitle"
                   [wrap]="true"
                   [maxLines]="2"></l7-text-block>
</div>
<button *ngIf="this.closeable"
        mat-icon-button
        (click)="this.closed.emit()">
    <mat-icon [fontSet]="'l7'"
              [fontIcon]="'Cross'"></mat-icon>
</button>
