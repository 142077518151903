<div fxLayout="row"
     fxLayout.lt-sm="column"
     fxLayoutAlign="space-evenly center"
     fxLayoutGap="0px"
     class="carousel-container"
     *cdkBreakpoint="let bp">
    <button mat-icon-button
            type="button"
            (click)="prev()"
            *ngIf="showControls && bp('gt-xs')"
            class="left-button">
        <mat-icon [fontSet]="'l7'"
                  class="double-sized"
                  [color]="'primary'"
                  [fontIcon]="'Arrow_Left_Circle_Filled'"></mat-icon>
    </button>
    <section fxLayout="row nowrap"
             fxLayout.lt-sm="row wrap"
             class="carousel-wrapper"
             [ngClass]="showControls ? '' : 'carousel-wrapper-justify-content'"
             [fxFlex]="carouselWrapperStyle | async">
        <ul class="carousel-inner"
            [ngClass]="{'carousel-inner-padding-left': numItems === maxShowedItems && bp('gt-md')}"
            [ngStyle.gt-xs]="numItems > maxShowedItems ? 'margin-left: -' + translationX + 'px; margin-right: ' + translationX + 'px' : ''"
            #carousel
            [fxLayoutGap]="gapBetweenItems + 'px'"
            [fxLayout.lt-sm]="'row wrap'"
            [fxLayoutGap.lt-sm]="'16px'">

            <li *ngFor="let item of items; let i = index"
                [style]="'margin-right: ' + gapBetweenItems + 'px; width: ' + elemWidth + 'px;'"
                #carouselItem
                class="carousel-item"
                [attr.data-position]="i + 1">
                <ng-container [ngTemplateOutlet]="item.tpl"></ng-container>
            </li>
        </ul>
        <ng-container *ngIf="(items).length === 0">
            <l7-text-block [text]="placeholder"></l7-text-block>
        </ng-container>
    </section>
    <button mat-icon-button
            type="button"
            (click)="next()"
            *ngIf="showControls && bp('gt-xs')"
            class="right-button">
        <mat-icon [fontSet]="'l7'"
                  class="double-sized"
                  [color]="'primary'"
                  [fontIcon]="'Arrow_Right_Circle_Filled'"></mat-icon>
    </button>
</div>
