import { Injectable } from '@angular/core';
import { MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';

/**
 * @deprecated - Please use MatDialog instead.
 */
@Injectable({
    providedIn: 'root',
})
export class DialogService {

    private readonly _dialogConfig: MatDialogConfig = {
        autoFocus: true,
        closeOnNavigation: false,
        disableClose: true,
    // minHeight: '400px',
    // width: '80%',
    // minWidth: '320px',
    // maxWidth: '640px'
    };

    constructor(
        private readonly _dialog: MatDialog,
    ) {
    }

    /**
 * Open a dialog by passing a component. Optionally a config file.
 * @param component           The component to be opened.
 * @param dialogConfig        The optional DialogConfig object.
 * @param data                Optional data passed to the dialog component
 */
    public openDialog(component: any, dialogConfig?: Partial<MatDialogConfig>, data?: any): MatDialogRef<any> {
        if (typeof component === 'undefined' || component === null) {
            throw new Error('Please provide a component to be opened.');
        }
        const config = { ...this._dialogConfig, ...dialogConfig, data };
        return this._dialog.open(component, config);
    }

}
