/* libs/ui/src/Controls/Components/Tag/TagComponent.scss */
my-box {
  background-color: var(--l7-info-500-color);
}
:host {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  font-size: 12px;
  text-transform: uppercase;
  font-weight: bold;
  padding: 4px;
  margin: 4px;
  height: 22px;
}
:host.primary {
  background: #e4f0f3;
  color: #084e6c;
}
:host.accent {
  background: #e4f0f3;
  color: #084e6c;
}
:host.warn {
  background: #fff5e0;
  color: #ff7d00;
}
:host.info {
  background: #e4f0f3;
  color: #084e6c;
}
:host.danger {
  background: #f5e1e9;
  color: #830021;
}
:host.success {
  background: #e1f9f0;
  color: #01ad53;
}
/*# sourceMappingURL=TagComponent.css.map */
