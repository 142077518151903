import { TranslationModule } from '@abcfinlab/core';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { FlexModule } from '@ngbracket/ngx-layout';
import { WebcamModule } from 'ngx-webcam';
import { CameraIndicatorComponent } from './camera-indicator/camera-indicator.component';
import { CameraStreamComponent } from './camera-stream/camera-stream.component';
import { WebCameraComponent } from './web-camera/WebCameraComponent';

@NgModule({
    declarations: [CameraIndicatorComponent, CameraStreamComponent, WebCameraComponent],
    imports: [
        MatIconModule,
        MatButtonModule,
        WebcamModule,
        CommonModule,
        FlexModule,
        TranslationModule,
        MatProgressSpinnerModule,
    ],
    exports: [CameraIndicatorComponent, CameraStreamComponent, WebCameraComponent],
})
export class CameraModule {
}
