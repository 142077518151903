/* libs/layout/src/Controls/Components/Brand/BrandComponent.scss */
my-box {
  background-color: var(--l7-info-500-color);
}
:host svg {
  width: 64px;
  height: 64px;
}
:host svg.compact {
  width: 32px;
  height: 32px;
}
:host .lettering {
  display: flex;
  justify-content: flex-end;
  margin-left: 16px;
}
:host .lettering h1 {
  font-weight: 800;
  font-size: 24px;
  line-height: 1;
  margin-bottom: 0;
}
:host .lettering span {
  font-weight: 300;
  font-size: 14px;
}
/*# sourceMappingURL=BrandComponent.css.map */
