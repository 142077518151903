<l7-busy-box *ngIf="!(data | async)"></l7-busy-box>
<h2 mat-dialog-title
    fxLayoutAlign="flex-start center"
    *ngIf="showKuevData | async">
    <mat-icon [attr.test-id]="'AlertIcon'"
              [color]="'warning'"
              [fontSet]="'l7'"
              [style.margin-right]="'8px'"
              [fontIcon]="'Alert_Triangle'"></mat-icon>
    {{'Wirklich zur Abrechnung senden?' | translate}}
</h2>
<mat-dialog-content fxLayout="column"
                    *ngIf="(showKuevData | async) && data | async as data">
    <l7-spacer [thickness]="'horizontal'">
        <l7-text-block [text]="'slb.contractManagement.textAccountingDialog' | translate"
                       [maxLines]="3"
                       [wrap]="true"></l7-text-block>
    </l7-spacer>

    <l7-kuev-comparison [objectCondition]="data.slb.objectCondition"
                        [isMKN]="data.quote.inhouseQuoteCalculation.contractType === 'MKN'"
                        [inactiveVersion]="data.slb.inactiveSlbResponse"
                        [activeVersion]="data.slb.activeSlbResponse">
    </l7-kuev-comparison>
</mat-dialog-content>
<h2 mat-dialog-title
    *ngIf="(data | async) && (showKuevData | async) === false">{{'Dokumente an Abrechner senden' | translate}}</h2>
<mat-dialog-content fxLayout="column"
                    fxLayoutGap="16px"
                    *ngIf="(showKuevData | async) === false && data | async as data">
    <ng-template #headerTemplate>
        <mat-card-header>
            <h3 matCardTitle>{{'Abrechnungsinformationen' | translate}}</h3>
        </mat-card-header>
    </ng-template>
    <ng-template #beforeQuoteTemplate>
        <fieldset *ngIf="data.contract?.startOfContract"
                  fxLayout="column"
                  fxFlex="calc(25% - 16px)"
                  fxFlex.lt-md="calc(50% - 16px)">
            <legend>{{ 'settlement.details.start_date' | translate }}</legend>
            <l7-text-block [truncate]="true"
                           [text]="data.contract.startOfContract | date: 'shortDate'"></l7-text-block>
        </fieldset>
        <fieldset fxLayout="column"
                  fxFlex="calc(25% - 16px)"
                  fxFlex.lt-md="calc(50% - 16px)">
            <legend>{{ 'settlement.details.pre_rent' | translate }}</legend>
            <l7-text-block *ngIf="data.accounting?.pre_rent === 'NEXT_MONTH_CALCULATED'"
                           [truncate]="true"
                           [block]="false"
                           [text]="'states.pre_rent.' + data.accounting?.pre_rent | translate">
            </l7-text-block>
            <l7-text-block *ngIf="data.accounting?.pre_rent === 'NEXT_MONTH_WITHOUT'"
                           [truncate]="true"
                           [block]="false"
                           [text]="'states.pre_rent.' + data.accounting?.pre_rent | translate">
            </l7-text-block>
            <l7-text-block *ngIf="data.accounting?.pre_rent === 'NEXT_MONTH_MANUAL_ENTERED'"
                           [truncate]="true"
                           [wrap]="true"
                           [block]="false"
                           [text]="'states.pre_rent.' + data.accounting?.pre_rent | translate: {value: data.accounting?.pre_rent_value | currency}">
            </l7-text-block>
            <l7-text-block *ngIf="data.accounting?.pre_rent === 'FIRST_DAY_OF_THIS_MONTH'"
                           [truncate]="true"
                           [block]="false"
                           [text]="'states.pre_rent.' + data.accounting?.pre_rent | translate">
            </l7-text-block>
        </fieldset>
    </ng-template>
    <l7-quote-summary-card [leasingQuote]="data.quote"
                           [headerTemplate]="headerTemplate"
                           [beforeQuoteTemplate]="beforeQuoteTemplate"
                           [quoteName]="data.details?.object.name"
                           [hasSlbCode]="data.quote?.saleAndLeaseBack !== null"
                           [hasTransparencyRegister]="(hasTransparencyRegister | async)"
                           [calculation]="data.calculation"
                           [creditCheck]="data.creditCheck"
                           [refinancingInterest]="data.refinancingInterest"
                           [maxColumns]="4"
                           [minColumns]="2">
    </l7-quote-summary-card>

    <l7-object-value-changed *ngIf="(changedPurchasePriceValues | async)"
                             [contractType]="data.quote.inhouseQuoteCalculation.contractType"
                             [calculationValues]="changedPurchasePriceValues | async"
                             [insuranceValues]="{insuranceType: data.insuranceAndHandlingFee?.insurance_type, insuranceValue: data.insuranceAndHandlingFee?.insurance_value}"
                             [slbCalculation]="data.slb?.activeSlbResponse?.calculation"
                             [isPurchasePriceChangedInactive]="false">
    </l7-object-value-changed>

    <mat-card *ngIf="data.changedCalculation && data.changedCalculation?.yearlyInterest !== data.calculation.yearly_interest">
        <mat-card-header>
            <p matCardTitle>{{'Ratenanpassung nach Zinsänderung' | translate}}</p>
        </mat-card-header>
        <mat-card-content>
            <l7-summary [maxColumns]="3"
                        [minColumns]="1">
                <l7-summary-item [title]="'Alter Nominalzins' | translate"
                                 [content]="(data.calculation.yearly_interest | number) + ' %'"></l7-summary-item>
                <l7-summary-item [title]="'Neuer Nominalzins' | translate"
                                 [content]="(data.changedCalculation?.yearlyInterest | number) + ' %'"></l7-summary-item>
                <l7-summary-item [title]="'Neue mtl. Rate' | translate"
                                 [content]="data.changedCalculation?.instalment | currency"></l7-summary-item>
            </l7-summary>
        </mat-card-content>
    </mat-card>

    <mat-card>
        <mat-card-header>
            <h3 matCardTitle>Anmerkung</h3>
        </mat-card-header>
        <mat-card-content>
            <form [formGroup]="accountingForm"
                  fxLayout="column"
                  fxLayoutGap="10px">
                <h3>Empfänger-Adressen</h3>
                <mat-chip-listbox aria-label="Emails list"
                               [attr.test-id]="'EmailsAssistants'">
                    <ng-container *ngFor="let email of emailsAssistant; let i = index">
                        <mat-chip-option [selectable]="true"
                                  [removable]="true"
                                  (removed)="removeEmail(email)">
                            <span>{{email}}</span>
                            <mat-icon matChipRemove>cancel</mat-icon>
                        </mat-chip-option>
                    </ng-container>
                </mat-chip-listbox>
                <mat-card-subtitle>{{ 'Weitere Adressaten hinzufügen (optional)' | translate }}</mat-card-subtitle>
                <l7-email-input-with-chips [formGroup]="accountingForm"
                                           (emailList)="addEmails($event)"></l7-email-input-with-chips>
                <mat-form-field fxFlex
                                appearance="fill">
                    <mat-label>Anmerkung</mat-label>
                    <textarea rows="3"
                              matInput
                              cdkTextareaAutosize
                              formControlName="annotation_in_mail"
                              cdkAutosizeMinRows="4"
                              cdkAutosizeMaxRows="8"
                              [attr.test-id]="'AnnotationText'"></textarea>
                </mat-form-field>
            </form>
        </mat-card-content>
    </mat-card>
</mat-dialog-content>
<mat-dialog-actions *ngIf="data | async as data">
    <button mat-button
            color="accent"
            [attr.test-id]="'CancelButton'"
            (click)="this.onClose(true)">{{'Abbrechen' | translate}}</button>
    <button mat-flat-button
            color="accent"
            *ngIf="(showKuevData | async)"
            [attr.test-id]="'ContinueButton'"
            (click)="this.hideKuevData()">{{'Trotzdem senden' | translate}}</button>
    <button mat-flat-button
            color="accent"
            *ngIf="(showKuevData | async) === false"
            [attr.test-id]="'SendButton'"
            [disabled]="this.emails.length === 0 && !accountingForm.valid"
            (click)="this.onClose(false)">{{'Senden' | translate}}</button>
</mat-dialog-actions>

