import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class TraceparentHttpInterceptor implements HttpInterceptor {

    public intercept(req: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
        req = req.clone({
            headers: req.headers
                .set('X-B3-TraceId', this.generateUUID(32))
                .set('X-B3-SpanId', this.generateUUID(16))
                .set('X-B3-Sampled', '1'),
        });

        return next.handle(req);
    }

    private readonly generateUUID = (length: 16 | 32): string => {
        const byteCount = length / 2;
        const bytes = new Uint8Array(byteCount);

        crypto.getRandomValues(bytes);

        let uuid = '';
        bytes.forEach((byte) => {
            uuid += byte.toString(16).padStart(2, '0');
        });

        return uuid;
    };

}
