/*
 * Copyright The OpenTelemetry Authors
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      https://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
/**
 * Defines the available internal logging levels for the diagnostic logger, the numeric values
 * of the levels are defined to match the original values from the initial LogLevel to avoid
 * compatibility/migration issues for any implementation that assume the numeric ordering.
 */
export var DiagLogLevel;
(function (DiagLogLevel) {
  /** Diagnostic Logging level setting to disable all logging (except and forced logs) */
  DiagLogLevel[DiagLogLevel["NONE"] = 0] = "NONE";
  /** Identifies an error scenario */
  DiagLogLevel[DiagLogLevel["ERROR"] = 30] = "ERROR";
  /** Identifies a warning scenario */
  DiagLogLevel[DiagLogLevel["WARN"] = 50] = "WARN";
  /** General informational log message */
  DiagLogLevel[DiagLogLevel["INFO"] = 60] = "INFO";
  /** General debug log message */
  DiagLogLevel[DiagLogLevel["DEBUG"] = 70] = "DEBUG";
  /**
   * Detailed trace level logging should only be used for development, should only be set
   * in a development environment.
   */
  DiagLogLevel[DiagLogLevel["VERBOSE"] = 80] = "VERBOSE";
  /** Used to set the logging level to include all logging */
  DiagLogLevel[DiagLogLevel["ALL"] = 9999] = "ALL";
})(DiagLogLevel || (DiagLogLevel = {}));
