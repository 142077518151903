// #region Imports

import { Injectable } from '@angular/core';
import { ITileInfo } from '../Models/Interfaces/ITileInfo';

// #endregion

@Injectable({ providedIn: 'root' })
export class TileRegistry {

    // #region Fields

    private _registry: Map<string, ITileInfo>;

    // #endregion

    // #region Ctor

    /**
     * Constructs a new instance of the `TileRegistry` class.
     *
     * @public
     */
    public constructor() {
        this._registry = new Map();
    }

    // #endregion

    // #region Methods

    /**
     * @public
     */
    public register(tile: ITileInfo): void {
        this._registry.set(tile.key, tile);
        this._registry = new Map([...this._registry].sort(([k, v], [k2, v2]) => {
            if (v.order > v2.order) {
                return 1;
            }
            if (v.order < v2.order) {
                return -1;
            }
            return 0;
        }));
    }

    /**
     * @public
     */
    public unregister(key: string): void {
        this._registry.delete(key);
    }

    /**
     * @public
     */
    public all(): ReadonlyArray<ITileInfo> {
        return Array.from(this._registry.values());
    }

    // #endregion

}
