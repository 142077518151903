// #region Imports

import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

// #endregion

@Injectable({ providedIn: 'root' })
export class WithCredentialsHttpInterceptor implements HttpInterceptor {

    // #region Methods

    public intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        req = req.clone({
            withCredentials: true,
        });

        return next.handle(req);
    }

    // #endregion

}
