// #region Imports

import { Inject, Injectable } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AppbarAction } from '../Models/AppbarAction';
import { INavigationInfo } from '../Models/INavigationInfo';
import { ILayoutModuleConfig } from '../Modules/ILayoutModuleConfig';
import { LAYOUT_MODULE_CONFIG } from '../Modules/LayoutModuleTokens';
import { IPresenterService } from './Interfaces/IPresenterService';

// #endregion

/**
 * The `PresenterService` class.
 *
 * @public
 */
@Injectable()
export class PresenterService implements IPresenterService {

    // #region Fields

    private readonly _title: Title;
    private readonly _layoutModuleConfig: ILayoutModuleConfig;
    private readonly _isBusySubject: BehaviorSubject<boolean>;
    private readonly _titleSubject: BehaviorSubject<string>;
    private readonly _navigationSubject: BehaviorSubject<Array<INavigationInfo>>;
    private readonly _actionSubject: BehaviorSubject<Array<AppbarAction>>;

    // #endregion

    // #region Ctor

    // #region Ctor

    /**
     * Constructs a new instance of the `PresenterService` class.
     *
     * @public
     */
    public constructor(title: Title, @Inject(LAYOUT_MODULE_CONFIG) layoutModuleConfig: ILayoutModuleConfig) {
        this._title = title;
        this._layoutModuleConfig = layoutModuleConfig;

        this._isBusySubject = new BehaviorSubject(false);
        this._titleSubject = new BehaviorSubject('');
        this._navigationSubject = new BehaviorSubject([]);
        this._actionSubject = new BehaviorSubject([]);
    }

    // #endregion

    // #endregion

    // #region Properties

    /**
     * Returns the `isBusy` property.
     *
     * @public
     * @readonly
     */
    public get isBusy(): Observable<boolean> {
        return this._isBusySubject.asObservable();
    }

    /**
     * Returns the `title` property.
     *
     * @public
     * @readonly
     */
    public get title(): Observable<string> {
        return this._titleSubject.asObservable();
    }

    /**
     * Returns the `navigation` property.
     *
     * @public
     * @readonly
     */
    public get navigation(): Observable<Array<INavigationInfo>> {
        return this._navigationSubject.asObservable();
    }

    /**
     * Returns the `actions` property.
     *
     * @public
     * @readonly
     */
    public get actions(): Observable<Array<AppbarAction>> {
        return this._actionSubject.asObservable().pipe(
            map(x => x.sort((a, b) => a.order - b.order)),
        );
    }

    // #endregion

    // #region Methods

    /**
     * @public
     */
    public setNavigation(navItem: INavigationInfo): void {
        const items = this._navigationSubject.value;
        items.push(navItem);

        this._navigationSubject.next(items);
    }

    /**
     * @public
     */
    public setAction(actionItem: AppbarAction): void {
        const items = this._actionSubject.value;
        items.push(actionItem);

        this._actionSubject.next(items);
    }

    /**
     * @public
     */
    public setBusy(operation: Observable<any>): Observable<void> {
        throw new Error('Method not implemented.');
    }

    /**
     * @public
     */
    public setTitle(title: string): void {
        this._titleSubject.next(title);
        this._title.setTitle(`${this._layoutModuleConfig.titlePrefix} - ${title}`);
    }

    // #endregion

}
