<!--#region Templates -->

<ng-template #defaultEmptyTemplate>
      <div fxFlex
           fxLayout="column"
           fxLayoutAlign="center center">
            <svg *cdkBreakpoint="let bp"
                 [attr.width]="bp('lt-md') ? 256 : 384"
                 [attr.height]="bp('lt-md') ? 256 : 384"
                 viewBox="0 0 512 512"
                 fill="none"
                 xmlns="http://www.w3.org/2000/svg">
                  <path d="M128.037 448H383.957C390.991 448.017 397.772 445.359 402.94 440.559C424.499 420.478 441.746 396.172 453.621 369.137C465.496 342.101 471.747 312.908 471.991 283.351C473.095 163.171 375.986 64.2842 256.521 64.0006C136.988 63.7168 40.0001 161.115 40.0001 281.302C39.966 311.216 46.0886 340.812 57.9823 368.229C69.876 395.645 87.2832 420.287 109.108 440.603C114.265 445.381 121.026 448.023 128.037 448Z"
                        fill="url(#paint0_linear_61_661)" />
                  <path d="M218.905 395.316C219.721 385.779 214.747 377.566 207.795 376.971C200.843 376.376 194.545 383.625 193.729 393.162C192.913 402.699 197.888 410.912 204.84 411.507C211.792 412.102 218.089 404.853 218.905 395.316Z"
                        fill="#417A93" />
                  <path d="M211.03 407.13C212.786 400.377 206.701 392.949 197.437 390.54C188.174 388.13 179.241 391.651 177.484 398.404C175.728 405.157 181.813 412.584 191.077 414.994C200.34 417.403 209.274 413.882 211.03 407.13Z"
                        fill="#417A93" />
                  <path d="M209.267 411.202C215.572 406.743 215.726 396.117 209.609 387.467C203.493 378.818 193.423 375.42 187.118 379.879C180.813 384.337 180.659 394.964 186.776 403.613C192.892 412.263 202.962 415.661 209.267 411.202Z"
                        fill="#417A93" />
                  <path d="M323.947 88.8781C327.344 88.8781 330.707 89.5471 333.844 90.8468C336.982 92.1465 339.833 94.0516 342.235 96.4531C344.636 98.8547 346.541 101.706 347.841 104.844C349.141 107.981 349.81 111.344 349.81 114.741C349.81 114.758 349.809 114.775 349.809 114.792L369.186 110.938V140.603H323.947C317.091 140.599 310.517 137.872 305.67 133.023C300.823 128.173 298.101 121.597 298.101 114.741C298.101 107.884 300.823 101.309 305.67 96.4588C310.517 91.6091 317.091 88.8824 323.947 88.8781Z"
                        fill="#D4D9E3" />
                  <path d="M369.186 53.6969C379.028 53.6979 388.579 57.0393 396.276 63.1744C403.973 69.3096 409.36 77.8752 411.555 87.47C415.224 86.7566 419.002 86.8183 422.646 87.651C426.29 88.4838 429.72 90.0692 432.715 92.3056C435.71 94.542 438.204 97.38 440.038 100.637C441.872 103.895 443.004 107.499 443.362 111.22C443.721 114.941 443.297 118.695 442.119 122.242C440.94 125.79 439.034 129.052 436.52 131.819C434.007 134.585 430.943 136.796 427.525 138.309C424.107 139.822 420.41 140.603 416.672 140.603H369.186C357.661 140.603 346.609 136.025 338.46 127.876C330.31 119.727 325.732 108.675 325.732 97.1502C325.732 85.6257 330.31 74.5732 338.46 66.4241C346.609 58.275 357.661 53.6969 369.186 53.6969Z"
                        fill="#D4D9E3" />
                  <path d="M332.712 324.344H288.179C282.477 324.344 277.008 322.079 272.976 318.047C268.944 314.015 266.679 308.546 266.679 302.844C266.679 297.142 268.944 291.673 272.976 287.641C277.008 283.609 282.477 281.344 288.179 281.344H332.712C339.628 281.336 346.258 278.585 351.148 273.695C356.038 268.805 358.789 262.175 358.797 255.259V220.931C358.797 215.229 361.062 209.76 365.094 205.728C369.126 201.696 374.595 199.431 380.297 199.431C385.999 199.431 391.468 201.696 395.5 205.728C399.532 209.76 401.797 215.229 401.797 220.931V255.259C401.776 273.575 394.491 291.135 381.539 304.087C368.588 317.038 351.028 324.323 332.712 324.344Z"
                        fill="#D4D9E3" />
                  <path d="M260.954 66.6706H260.954C274.527 66.6706 287.544 72.0624 297.142 81.6599C306.739 91.2574 312.131 104.274 312.131 117.847V433.097H209.777V117.847C209.777 104.274 215.169 91.2574 224.767 81.6599C234.364 72.0624 247.381 66.6706 260.954 66.6706Z"
                        fill="#E5E9F1" />
                  <path d="M150.386 240.823C146.99 240.823 143.627 241.492 140.489 242.791C137.351 244.091 134.5 245.996 132.099 248.398C129.697 250.799 127.792 253.65 126.492 256.788C125.192 259.926 124.524 263.289 124.524 266.685C124.524 266.703 124.525 266.72 124.525 266.737L105.147 262.882V292.548H150.386C157.245 292.548 163.824 289.823 168.674 284.973C173.524 280.123 176.249 273.545 176.249 266.685C176.249 259.826 173.524 253.248 168.674 248.398C163.824 243.548 157.245 240.823 150.386 240.823Z"
                        fill="#D4D9E3" />
                  <path d="M105.147 205.642C95.304 205.643 85.7529 208.984 78.0562 215.119C70.3594 221.254 64.9727 229.82 62.7772 239.415C59.108 238.701 55.3302 238.763 51.6862 239.596C48.0422 240.428 44.6126 242.014 41.6176 244.25C38.6225 246.487 36.128 249.325 34.2944 252.582C32.4608 255.839 31.3285 259.444 30.9702 263.165C30.6118 266.885 31.0354 270.64 32.2137 274.187C33.392 277.734 35.2989 280.996 37.8122 283.763C40.3254 286.53 43.3894 288.741 46.8075 290.254C50.2256 291.767 53.9222 292.548 57.6601 292.548H105.147C116.668 292.543 127.715 287.962 135.859 279.814C144.004 271.665 148.579 260.616 148.579 249.095C148.579 237.574 144.004 226.525 135.859 218.376C127.715 210.228 116.668 205.647 105.147 205.642Z"
                        fill="#D4D9E3" />
                  <path d="M245.97 264.132L209.778 319.643V259.303L245.97 264.132Z"
                        fill="#D4D9E3" />
                  <path d="M234.249 267.612H189.716C171.4 267.591 153.84 260.306 140.888 247.355C127.937 234.403 120.652 216.843 120.631 198.527V164.199C120.631 158.497 122.896 153.029 126.928 148.997C130.96 144.965 136.429 142.699 142.131 142.699C147.833 142.699 153.302 144.965 157.334 148.997C161.366 153.029 163.631 158.497 163.631 164.199V198.527C163.639 205.443 166.389 212.073 171.28 216.963C176.17 221.853 182.8 224.604 189.716 224.612H234.249C239.951 224.612 245.42 226.877 249.452 230.909C253.484 234.941 255.749 240.41 255.749 246.112C255.749 251.814 253.484 257.283 249.452 261.315C245.42 265.347 239.951 267.612 234.249 267.612L234.249 267.612Z"
                        fill="#E5E9F1" />
                  <path opacity="0.3"
                        d="M221.497 96.7004L221.497 96.7004C219.672 98.2401 219.441 100.968 220.98 102.793L227.041 109.976C228.581 111.801 231.308 112.032 233.133 110.492L233.133 110.492C234.958 108.952 235.189 106.225 233.65 104.4L227.589 97.2168C226.049 95.3918 223.322 95.1607 221.497 96.7004Z"
                        fill="white" />
                  <path opacity="0.3"
                        d="M378.395 226.528C376.856 224.703 374.128 224.472 372.303 226.012C370.478 227.552 370.247 230.279 371.787 232.104L377.848 239.287C379.387 241.112 382.115 241.343 383.94 239.804C385.765 238.264 385.996 235.536 384.456 233.712L378.395 226.528Z"
                        fill="white" />
                  <path opacity="0.3"
                        d="M290.118 303.113L290.118 303.113C288.293 304.653 288.062 307.381 289.601 309.206L295.662 316.389C297.202 318.214 299.929 318.445 301.754 316.905L301.754 316.905C303.579 315.366 303.81 312.638 302.271 310.813L296.21 303.63C294.67 301.805 291.943 301.574 290.118 303.113Z"
                        fill="white" />
                  <path opacity="0.3"
                        d="M310.17 216.673L310.17 216.673C309.451 214.396 307.022 213.133 304.745 213.852L295.783 216.681C293.506 217.4 292.243 219.828 292.962 222.105L292.962 222.105C293.68 224.382 296.109 225.645 298.386 224.927L307.348 222.097C309.625 221.379 310.888 218.95 310.17 216.673Z"
                        fill="white" />
                  <path opacity="0.3"
                        d="M160.123 202.44L160.123 202.44C159.404 200.163 156.975 198.9 154.699 199.618L145.736 202.447C143.459 203.166 142.196 205.595 142.915 207.872L142.915 207.872C143.633 210.148 146.062 211.412 148.339 210.693L157.301 207.864C159.578 207.145 160.841 204.717 160.123 202.44Z"
                        fill="white" />
                  <path opacity="0.3"
                        d="M259.617 362.089L259.617 362.089C261.864 361.281 263.03 358.805 262.222 356.558L259.041 347.714C258.233 345.467 255.756 344.301 253.51 345.109L253.51 345.109C251.263 345.918 250.097 348.394 250.905 350.641L254.086 359.485C254.894 361.731 257.371 362.897 259.617 362.089Z"
                        fill="white" />
                  <path opacity="0.3"
                        d="M242.517 203.412C244.899 203.243 246.693 201.176 246.525 198.794C246.356 196.413 244.289 194.618 241.907 194.787L232.532 195.45C230.151 195.619 228.356 197.686 228.525 200.068C228.693 202.45 230.761 204.244 233.142 204.075L242.517 203.412Z"
                        fill="white" />
                  <path opacity="0.3"
                        d="M383.114 283.801C385.496 283.632 387.29 281.565 387.122 279.183C386.953 276.802 384.886 275.007 382.504 275.176L373.129 275.839C370.747 276.008 368.953 278.075 369.122 280.457C369.29 282.839 371.357 284.633 373.739 284.464L383.114 283.801Z"
                        fill="white" />
                  <path opacity="0.3"
                        d="M141.647 179.975L141.647 179.975C143.472 178.436 143.703 175.708 142.163 173.883L136.103 166.7C134.563 164.875 131.835 164.644 130.01 166.183L130.01 166.184C128.186 167.723 127.954 170.451 129.494 172.276L135.555 179.459C137.094 181.284 139.822 181.515 141.647 179.975Z"
                        fill="white" />
                  <path opacity="0.3"
                        d="M284.455 271.925L284.455 271.925C285.017 269.605 283.591 267.268 281.271 266.706L272.136 264.495C269.816 263.933 267.479 265.359 266.917 267.679L266.917 267.68C266.355 270 267.781 272.337 270.102 272.899L279.236 275.11C281.557 275.672 283.894 274.246 284.455 271.925Z"
                        fill="white" />
                  <path opacity="0.3"
                        d="M205.76 261.213C208.141 261.045 209.936 258.977 209.767 256.596C209.599 254.214 207.531 252.42 205.149 252.588L195.774 253.252C193.393 253.42 191.599 255.487 191.767 257.869C191.936 260.251 194.003 262.045 196.385 261.876L205.76 261.213Z"
                        fill="white" />
                  <path opacity="0.3"
                        d="M265.213 148.683C263.831 150.629 264.288 153.328 266.235 154.711C268.182 156.093 270.88 155.636 272.263 153.689L277.705 146.026C279.087 144.08 278.63 141.381 276.683 139.998C274.736 138.616 272.038 139.073 270.655 141.02L265.213 148.683Z"
                        fill="white" />
                  <path opacity="0.3"
                        d="M154.927 248.179L154.927 248.179C156.874 249.562 159.573 249.104 160.955 247.157L166.397 239.495C167.78 237.548 167.322 234.849 165.376 233.467L165.376 233.467C163.429 232.084 160.73 232.542 159.347 234.488L153.906 242.151C152.523 244.098 152.981 246.797 154.927 248.179Z"
                        fill="white" />
                  <path opacity="0.3"
                        d="M327.992 312.922C326.609 314.868 327.067 317.567 329.013 318.95C330.96 320.332 333.659 319.875 335.041 317.928L340.483 310.265C341.866 308.319 341.408 305.62 339.461 304.237C337.515 302.855 334.816 303.312 333.433 305.259L327.992 312.922Z"
                        fill="white" />
                  <path opacity="0.3"
                        d="M222.09 304.984L222.09 304.984C224.037 306.367 226.736 305.909 228.118 303.963L233.56 296.3C234.943 294.353 234.485 291.654 232.539 290.272L232.539 290.272C230.592 288.889 227.893 289.347 226.511 291.293L221.069 298.956C219.686 300.903 220.144 303.602 222.09 304.984Z"
                        fill="white" />
                  <path d="M335.185 376.478C340.763 371.867 342.56 364.833 339.198 360.767C335.837 356.702 328.591 357.144 323.013 361.755C317.436 366.366 315.639 373.4 319 377.466C322.362 381.531 329.608 381.089 335.185 376.478Z"
                        fill="#417A93" />
                  <path d="M324.641 378.487C329.215 375.858 329.999 368.641 326.392 362.366C322.786 356.092 316.155 353.138 311.581 355.767C307.007 358.396 306.223 365.613 309.83 371.887C313.437 378.161 320.068 381.116 324.641 378.487Z"
                        fill="#417A93" />
                  <path d="M334.497 367.306C335.924 359.425 332.423 352.192 326.678 351.152C320.933 350.112 315.118 355.657 313.691 363.539C312.264 371.42 315.765 378.652 321.51 379.693C327.255 380.733 333.069 375.187 334.497 367.306Z"
                        fill="#417A93" />
                  <path d="M452.181 447.064C453.455 447.067 454.704 446.717 455.79 446.052C456.877 445.388 457.758 444.435 458.335 443.3C458.913 442.165 459.164 440.892 459.062 439.623C458.959 438.354 458.507 437.138 457.755 436.11C428.493 396.809 379.349 371.019 323.649 371.019C267.95 371.019 218.806 396.809 189.544 436.11C188.792 437.138 188.34 438.354 188.237 439.623C188.135 440.892 188.386 442.165 188.964 443.3C189.541 444.435 190.422 445.388 191.508 446.052C192.595 446.717 193.844 447.067 195.118 447.064H452.181Z"
                        fill="#D4D9E3" />
                  <path d="M267.764 436.866C272.165 433.228 273.582 427.678 270.93 424.47C268.278 421.262 262.56 421.61 258.16 425.249C253.759 428.887 252.341 434.437 254.994 437.645C257.646 440.853 263.363 440.504 267.764 436.866Z"
                        fill="#417A93" />
                  <path d="M259.444 438.451C263.053 436.376 263.671 430.682 260.826 425.731C257.98 420.781 252.748 418.45 249.139 420.524C245.531 422.598 244.912 428.293 247.758 433.243C250.603 438.194 255.836 440.525 259.444 438.451Z"
                        fill="#417A93" />
                  <path d="M267.22 429.629C268.346 423.41 265.584 417.704 261.051 416.883C256.518 416.062 251.93 420.438 250.804 426.656C249.678 432.875 252.44 438.581 256.973 439.402C261.506 440.223 266.094 435.847 267.22 429.629Z"
                        fill="#417A93" />
                  <path d="M267.413 447.064C245.581 419.538 204.123 400.952 156.563 400.952C114.855 400.952 77.8401 415.246 54.6387 437.327C53.8267 438.112 53.2679 439.121 53.034 440.226C52.8001 441.331 52.9017 442.48 53.3259 443.527C53.7501 444.574 54.4774 445.469 55.4145 446.1C56.3515 446.73 57.4556 447.066 58.5849 447.064L267.413 447.064Z"
                        fill="#E5E9F1" />
                  <defs>
                        <linearGradient id="paint0_linear_61_661"
                                        x1="256"
                                        y1="64"
                                        x2="256"
                                        y2="448"
                                        gradientUnits="userSpaceOnUse">
                              <stop stop-color="#FBFDFE" />
                              <stop offset="1"
                                    stop-color="#F0F5FA" />
                        </linearGradient>
                  </defs>
            </svg>

            <l7-text-block [text]= "this.isEmptyText | translate"></l7-text-block>
      </div>
</ng-template>

<!-- #endregion -->

<ng-content select="l7-page-header"></ng-content>
<ng-content select="l7-page-pre-content"></ng-content>
<ng-content *ngIf="!this.isEmpty else defaultEmptyTemplate"
            select="l7-page-content"></ng-content>
<ng-content></ng-content>
<ng-content select="l7-page-footer"></ng-content>
