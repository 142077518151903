/* libs/presentation/src/Components/file-uploader/file-uploader.component.scss */
:host .disable-upload {
  position: absolute;
  opacity: 0.75;
  top: -2px;
  left: -2px;
  width: 101% !important;
  height: 102% !important;
  z-index: 9999;
  background: white;
}
:host .uploadfilecontainer {
  position: relative;
  transition: background-color 250ms ease-in-out;
  padding: 16px;
  border: 1px dashed #1b7e9a;
  border-radius: 5px;
}
:host .dragndrop {
  width: 36px;
  height: 36px;
  font-size: 36px;
  object-fit: contain;
  z-index: 0;
}
:host .label-upload {
  font-size: 14px;
  font-stretch: normal;
  font-style: normal;
  text-align: center;
  color: #83929b;
}
:host .label-upload span {
  float: left;
}
:host .label-upload a {
  float: left;
}
:host .link-button {
  font-size: 16px;
  color: #2b7e95;
}
:host .uploadfilecontainer:hover {
  cursor: pointer;
  background-color: #e4f0f3 !important;
  opacity: 0.8;
}
:host .files-list {
  justify-content: center;
  background: #ffffff;
  max-height: 52px;
}
:host .files-list .file-content {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
:host .button.button-blue {
  margin-top: 10px;
  background-color: #197e9a;
  color: #fff;
  border: none;
  border-radius: 0;
  min-width: 130px;
}
:host .files-list.mat-mdc-card {
  box-shadow: none;
  background: whitesmoke;
  color: #83929b;
  border-radius: 0;
  padding: 0 8px;
}
:host .files-list.mat-mdc-card .mat-icon {
  color: #83929b;
}
:host .files-list.mat-mdc-card.new-style {
  background: none;
  border-radius: 5px;
  border: 1px solid #e0e0e0;
  color: black;
}
:host .files-list.mat-mdc-card.new-style .mat-icon {
  font-size: 18px;
}
:host .files-list.mat-mdc-card.new-style .mat-icon.Trash {
  color: black;
}
:host .files-list *,
:host .files-list *:focus,
:host .files-list *:hover,
:host .files-list *:active {
  outline: none;
}
:host .files-list .delete-file img {
  width: 30px;
}
:host .error-message {
  font-size: 17px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  text-align: center;
  color: #a0161e;
  float: left;
}
/*# sourceMappingURL=file-uploader.component.css.map */
