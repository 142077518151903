export var TransportItemType;
(function (TransportItemType) {
  TransportItemType["EXCEPTION"] = "exception";
  TransportItemType["LOG"] = "log";
  TransportItemType["MEASUREMENT"] = "measurement";
  TransportItemType["TRACE"] = "trace";
  TransportItemType["EVENT"] = "event";
})(TransportItemType || (TransportItemType = {}));
export const transportItemTypeToBodyKey = {
  [TransportItemType.EXCEPTION]: 'exceptions',
  [TransportItemType.LOG]: 'logs',
  [TransportItemType.MEASUREMENT]: 'measurements',
  [TransportItemType.TRACE]: 'traces',
  [TransportItemType.EVENT]: 'events'
};
