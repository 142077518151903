import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

export type Theme = 'dark' | 'light';

@Injectable({
    providedIn: 'root',
})
export class ThemingService {

    public static THEMES = new Array<Theme>('light', 'dark');
    public static DEFAULT_THEME: Theme = 'light';
    public constructor(@Inject(DOCUMENT) document: Document) {
        // this.theme.subscribe(x => {
        //     document.body.classList.add(x);
        // })

        if (window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches) {
            this.theme.next('dark');
        }

        window.matchMedia('(prefers-color-scheme: dark)').addEventListener('change', e =>
            this.theme.next(e.matches ? 'dark' : 'light'),
        );
    }

    public theme = new BehaviorSubject(ThemingService.DEFAULT_THEME);

}
