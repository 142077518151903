<h2 mat-dialog-title>{{ this.data.title | of | async }}</h2>
<mat-dialog-content *ngIf="this.data.message | of | async"
                    fxLayout="column">
    <l7-text-block [wrap]="true"
                   [text]="this.data.message | of | async"></l7-text-block>
    <mat-accordion *ngIf="this.data.options?.details"
                   [displayMode]="'flat'">
        <mat-expansion-panel [expanded]="false"
                             class="mat-elevation-z">
            <mat-expansion-panel-header matRipple>
                <mat-panel-title fxLayout="row"
                                 fxLayoutAlign="start center">
                    <p>{{ this.data.options?.details?.label | of | async }}</p>
                </mat-panel-title>
            </mat-expansion-panel-header>
            <mat-form-field appearance="outline">
                <textarea matInput
                          disabled="true"
                          rows="5"
                          [value]="this.data.options?.details?.text | of | async"></textarea>
            </mat-form-field>
            <!-- TODO: <button matSuffix
                    mat-icon-button
                    [cdkCopyToClipboard]="this.data.options?.details?.text">
                <mat-icon matSuffix
                          [inline]="true"
                          [fontSet]="'fa'"
                          [fontIcon]="'fa-copy'"></mat-icon>
            </button> -->
        </mat-expansion-panel>
    </mat-accordion>
</mat-dialog-content>
<mat-dialog-actions fxLayout="row"
                    fxLayoutAlign="end center">
    <ng-container *ngIf="this.data.button === 0">
        <button mat-flat-button
                color="accent"
                (click)="onClose(1)">{{ this.data.options?.labels?.ok | of | async }}</button>
    </ng-container>
    <ng-container *ngIf="this.data.button === 1">
        <button mat-button
                color="accent"
                (click)="onClose(2)">{{ this.data.options?.labels?.cancel | of | async }}</button>
        <button mat-flat-button
                color="accent"
                (click)="onClose(1)">{{ this.data.options?.labels?.ok | of | async }}</button>
    </ng-container>
    <ng-container *ngIf="this.data.button === 3">
        <button mat-button
                color="accent"
                (click)="onClose(2)">{{ this.data.options?.labels?.cancel | of | async }}</button>
        <button mat-button
                color="accent"
                (click)="onClose(7)">{{ this.data.options?.labels?.no | of | async }}</button>
        <button mat-flat-button
                color="accent"
                (click)="onClose(6)">{{ this.data.options?.labels?.yes | of | async }}</button>
    </ng-container>
    <ng-container *ngIf="this.data.button === 4">
        <button mat-button
                color="accent"
                (click)="onClose(7)">{{ this.data.options?.labels?.no | of | async }}</button>
        <button mat-flat-button
                color="accent"
                (click)="onClose(6)">{{ this.data.options?.labels?.yes | of | async }}</button>
    </ng-container>
</mat-dialog-actions>
