/* libs/presentation/src/Components/camera/camera-indicator/camera-indicator.component.scss */
:host .camera-indicator {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}
:host .camera-indicator.disabled mat-icon {
  color: #bf4128;
}
:host .camera-indicator.enabled mat-icon {
  color: #55ed9c;
}
:host mat-icon {
  color: #84939d;
}
/*# sourceMappingURL=camera-indicator.component.css.map */
