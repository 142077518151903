// #region Imports

import { MessageBoxButton, MessageBoxResult, MessageBoxService } from '@abcfinlab/ui';
import { Inject, Injectable } from '@angular/core';
import { SwUpdate, UnrecoverableStateEvent, VersionDetectedEvent, VersionReadyEvent } from '@angular/service-worker';
import { Subscription } from 'rxjs';
import { WINDOW } from '../Index';

// #endregion

/**
 * The `UpdateHandler` class handles all service worker update events.
 *
 * @internal
 */
@Injectable()
export class UpdateHandler {

    // #region Fields

    private readonly _update: SwUpdate;
    private readonly _messageBoxService: MessageBoxService;
    private readonly _window: Window;
    private readonly _subscriptions: Subscription;

    // #endregion

    // #region Ctor

    /**
     * Constructs a new instance of the `UpdateHandler` class.
     *
     * @public
     */
    public constructor(update: SwUpdate, messageBoxService: MessageBoxService, @Inject(WINDOW) window: any) {
        this._update = update;
        this._messageBoxService = messageBoxService;
        this._window = window;
        this._subscriptions = new Subscription();
    }

    // #endregion

    // #region Properties
    // #endregion

    // #region Methods

    /**
     * @public
     */
    public initialize(): void {
        if (this._update.isEnabled) {
            this._subscriptions.add(this._update.versionUpdates.subscribe((event) => {
                if (event.type === 'VERSION_DETECTED') {
                    this.updateAvailable(event);
                }
                if (event.type === 'VERSION_READY') {
                    this.updateActivated(event);
                }
            }));
            this._subscriptions.add(this._update.unrecoverable.subscribe(event => this.updateUnrecoverable(event)));
        }
    }

    /**
     * @public
     */
    public dispose(): void {
        this._subscriptions.unsubscribe();
    }

    /**
     * @private
     */
    private updateAvailable(event: VersionDetectedEvent): void {
        console.info('UPDATE: Current version is', event.version);
        console.info('UPDATE: Available version is', event.version);

        this._messageBoxService.show('Update', 'An update is available. Do you want to update?', MessageBoxButton.OK, {
            details: {
                label: 'Details',
                text: `Version ${String(event.version)} is Available for Update`,
            },
            labels: {
                ok: 'OK',
            },
        }).subscribe((x) => {
            if (x === MessageBoxResult.OK) {
                void this._update.activateUpdate().then(() => document.location.reload());
            }
        });
    }

    /**
     * @private
     */
    private updateActivated(event: VersionReadyEvent): void {
        console.info('UPDATE: Old version was', event.latestVersion.hash);
        console.info('UPDATE: New version is', event.currentVersion.hash);
    }

    /**
     * @private
     */
    private updateUnrecoverable(event: UnrecoverableStateEvent): void {
        console.error(`UPDATE: An error occurred that we cannot recover from:\n${event.reason}\n\nPlease reload the page.`);

        this._messageBoxService.show('Error', `An error occurred that we cannot recover from:\n${event.reason}\n\nPlease reload the page.`, MessageBoxButton.OK, {
            details: {
                label: 'Details',
                text: event.reason,
            },
            labels: {
                ok: 'Reload now',
            },
        }).subscribe((x) => {
            if (x === MessageBoxResult.OK) {
                this._window.location.reload();
            }
        });
    }

    // #endregion

}
