// #region Imports

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatRippleModule } from '@angular/material/core';
import { TileComponent } from './TileComponent';

// #endregion

/**
 * @public
 */
@NgModule({
    imports: [
        CommonModule,
        MatRippleModule,
    ],
    declarations: [
        TileComponent,
    ],
    exports: [
        TileComponent,
    ],
})
export class TileModule {
}
