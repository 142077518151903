/**
 * @public
 *
 * `DisplayDensity` - Enum which describes 3 possible density options.
 */
export enum DisplayDensity {
    /**
     * `compact` - small
     */
    Compact = 'compact',
    /**
     * `comfortable` - normal or medium
     */
    Comfortable = 'comfortable',
    /**
     * `cosy` - large
     */
    Cosy = 'cosy',
}
