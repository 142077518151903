/* libs/ui/src/Controls/Components/Wizard/WizardComponent.scss */
.wizard {
  display: flex;
  flex-direction: column;
}
.wizard .wizard-step {
  display: none;
}
.wizard .wizard-step.active {
  display: block;
}
.wizard .wizard-step .wizard-step-label {
  font-weight: bold;
}
.wizard .wizard-step .wizard-step-content {
  margin-top: 1em;
}
.wizard .wizard-navigation {
  margin-top: 2em;
  display: flex;
  justify-content: space-between;
}
/*# sourceMappingURL=WizardComponent.css.map */
