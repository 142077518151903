// #region Imports

import { ChangeDetectionStrategy, Component, ContentChildren, Input, QueryList } from '@angular/core';
import { SummaryItemComponent } from './SummaryItemComponent';

// #endregion

/**
 * The `SummaryComponent` component.
 *
 * @public
 */
@Component({
    selector: 'l7-summary',
    templateUrl: './SummaryComponent.html',
    styleUrls: ['./SummaryComponent.scss'],
    exportAs: 'l7summary',
    changeDetection: ChangeDetectionStrategy.Default,
    standalone: false,
})
export class SummaryComponent {

    // #region Fields

    @ContentChildren(SummaryItemComponent, { descendants: true })
    private readonly _items: QueryList<SummaryItemComponent>;

    private _maxColumns: number;
    private _minColumns: number;

    // #endregion

    // #region Ctor

    /**
     * Constructs a new instance of the `SummaryComponent` class.
     *
     * @public
     */
    public constructor() {
        this._items = new QueryList();
        this._maxColumns = 1;
        this._minColumns = 1;
    }

    // #endregion

    // #region Methods

    /**
     * Gets or sets the `maxColumns` property.
     *
     * @public
     */
    @Input()
    public get maxColumns(): number {
        return this._maxColumns;
    }

    public set maxColumns(value: number) {
        this._maxColumns = value;
    }

    /**
     * Gets or sets the `minColumns` property.
     *
     * @public
     */
    @Input()
    public get minColumns(): number {
        return this._minColumns;
    }

    public set minColumns(value: number) {
        this._minColumns = value;
    }

    /**
     * Returns the `items` property.
     *
     * @public
     * @readonly
     */
    public get items(): QueryList<SummaryItemComponent> {
        return this._items;
    }

    // #endregion

}
