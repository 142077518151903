/* libs/ui/src/Controls/Components/Wrap/WrapComponent.scss */
:host {
  display: flex;
  width: 100%;
  height: 100%;
  overflow: hidden;
  flex-flow: row wrap;
  box-sizing: border-box;
  display: flex;
  place-content: stretch flex-start;
  align-items: stretch;
}
:host.horizontal {
  flex-flow: row wrap;
}
:host.vertical {
  flex-flow: column wrap;
}
/*# sourceMappingURL=WrapComponent.css.map */
