// #region Imports

import { IRealmDto, IUserInfoDto } from '@abcfinlab/api/login';

// #endregion

/**
 * @public
 */
export function isUserInfoRetailer(userInfo: IUserInfoDto): boolean {
    return userInfo.realm === IRealmDto.Retailer;
}

/**
 * @public
 */
export function isUserInfoLogins(userInfo: IUserInfoDto): boolean {
    return userInfo.realm === IRealmDto.Logins;
}
