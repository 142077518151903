// #region Imports

import { NgModule, Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer, SafeHtml, SafeResourceUrl, SafeScript, SafeStyle, SafeUrl } from '@angular/platform-browser';

// #endregion

export type BypassSecurityType = 'html' | 'style' | 'script' | 'url' | 'resource';

/**
 * Transforms a `string` to a `SafeHtml`, `SafeStyle`, `SafeScript`, `SafeUrl` or `SafeResourceUrl`.
 * @public
 */
@Pipe({
    name: 'safe',
    standalone: false,
})
export class SafePipe implements PipeTransform {

    // #region Fields

    private readonly _sanitizer: DomSanitizer;

    // #endregion

    // #region Ctor

    public constructor(sanitizer: DomSanitizer) {
        this._sanitizer = sanitizer;
    }

    // #endregion

    // #region Methods

    public transform(value: any, type: BypassSecurityType): SafeHtml | SafeStyle | SafeScript | SafeUrl | SafeResourceUrl {
        switch (type) {
            case 'html':
                return this._sanitizer.bypassSecurityTrustHtml(value);
            case 'style':
                return this._sanitizer.bypassSecurityTrustStyle(value);
            case 'script':
                return this._sanitizer.bypassSecurityTrustScript(value);
            case 'url':
                return this._sanitizer.bypassSecurityTrustUrl(value);
            case 'resource':
                return this._sanitizer.bypassSecurityTrustResourceUrl(value);
            default:
                throw new Error('Argument type is invalid.');
        }
    }

    // #endregion

}

/**
 * The `SafePipeModule` provides the {@link SafePipe}.
 * @public
 */
@NgModule({
    declarations: [
        SafePipe,
    ],
    exports: [
        SafePipe,
    ],
})
export class SafePipeModule {
}
