// #region Imports

import { TranslationModule } from '@abcfinlab/core';
import { BreakpointObserverModule, MenuGroupModule, OfPipeModule, OrderByPipeModule, PersonaModule, RouterLinkActiveModule, SpacerModule, TagModule, TextBlockModule } from '@abcfinlab/ui';
import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { MatBadgeModule } from '@angular/material/badge';
import { MatButtonModule } from '@angular/material/button';
import { MatCommonModule, MatRippleModule } from '@angular/material/core';
import { MatDividerModule } from '@angular/material/divider';
import { MatIconModule } from '@angular/material/icon';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { RouterModule } from '@angular/router';
import { FlexLayoutModule } from '@ngbracket/ngx-layout';
import { BrandModule } from '../Controls/Components/Brand/BrandModule';
import { PageModule } from '../Controls/Components/Page/PageModule';
import { BreakpointService } from '../Services/BreakpointService';
import { PresenterView } from '../Views/PresenterView';
import { ILayoutModuleConfig } from './ILayoutModuleConfig';
import { LAYOUT_MODULE_CONFIG } from './LayoutModuleTokens';

// #endregion

/**
 * @internal
 * @factory
 */
export function layoutModuleConfigFactory(): ILayoutModuleConfig {
    return {
        titlePrefix: '',
    };
}

/**
 * The `LayoutModule` module.
 *
 * @public
 */
@NgModule({
    imports: [
        CommonModule,
        RouterModule,
        // mat
        MatCommonModule,
        MatToolbarModule,
        MatSidenavModule,
        MatIconModule,
        MatButtonModule,
        MatListModule,
        MatTooltipModule,
        MatBadgeModule,
        MatDividerModule,
        MatProgressBarModule,
        FlexLayoutModule,
        MatRippleModule,
        MatMenuModule,
        // l7
        PageModule.forRoot({
            hasBackNavigation: false,
        }),
        BrandModule,
        SpacerModule,
        PersonaModule,
        TextBlockModule,
        TagModule,
        MenuGroupModule,
        OfPipeModule,
        OrderByPipeModule,
        TranslationModule,
        RouterLinkActiveModule,
        BreakpointObserverModule,
    ],
    declarations: [
        PresenterView,
    ],
    exports: [
        PresenterView,
        PageModule,
    ],
    providers: [
        { provide: LAYOUT_MODULE_CONFIG, useFactory: layoutModuleConfigFactory },
        BreakpointService,
    ],
})
export class LayoutModule {

    // #region Methods

    public static forRoot(config: ILayoutModuleConfig): ModuleWithProviders<LayoutModule> {
        return {
            ngModule: LayoutModule,
            providers: [
                { provide: LAYOUT_MODULE_CONFIG, useValue: config },
            ],
        };
    }

    // #endregion

}
