// #region Imports

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { GreetingService } from './GreetingService';

// #endregion

/**
 * The `GreetingModule` module.
 *
 * @public
 */
@NgModule({
    imports: [
        CommonModule,
    ],
    providers: [
        GreetingService,
    ],
})
export class GreetingModule { }
