<h2 mat-dialog-title
    *ngIf="data?.title ?? false"
    [innerHTML]="data?.title | translate"></h2>
<mat-dialog-content>
  <!-- <img *ngIf="data.image"
       src="{{data.image}}"
       alt=""> -->
  <p *ngIf="data.body"
     [innerHTML]="data.body | translate"></p>
</mat-dialog-content>

<!--
  todo: aw -
  talk with QA.alse
  change the id naming to (negative/positive).
-->

<mat-dialog-actions>
  <button *ngIf="data.negativeText"
          mat-button
          [color]="'accent'"
          id="button_right_{{data.id}}"
          (click)="onConfirm(false)">
    {{data.negativeText | translate}}
  </button>
  <button *ngIf="data.positiveText"
          mat-flat-button
          [color]="'accent'"
          id="button_left_{{data.id}}"
          (click)="onConfirm(true)">
    {{data.positiveText | translate}}
  </button>
</mat-dialog-actions>