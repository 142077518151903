<div fxFlex
     fxLayout="column"
     fxLayoutAlign="center"
     mat-menu-item
     fxLayoutGap="8px">
     <div fxLayout="row"
          fxLayoutGap="16px"
          fxLayoutAlign="start center">
          <l7-persona [color]="this.color"
                      [text]="this.name"></l7-persona>
          <div fxFlex
               fxLayout="column"
               fxLayoutAlign="center">
               <l7-text-block *ngIf="this.name"
                              [wrap]="true"
                              [maxLines]="3"
                              [text]="this.name"></l7-text-block>
               <l7-text-block *ngIf="this.role"
                              class="mat-subtitle-2"
                              [wrap]="true"
                              [maxLines]="3"
                              [text]="this.role"
                              [readonly]="true"></l7-text-block>
          </div>
     </div>
     <div fxLayoutGap="8px"
          fxLayout="column">
          <legend>Kontakt</legend>
          <div *ngIf="this.email"
               fxLayout="row"
               fxLayoutGap="8px">
               <mat-icon [inline]="true"
                         [fontSet]="'l7'"
                         [fontIcon]="'Email'"></mat-icon>
               <a class="link primary"
                  [href]="'mailto:' + this.email">
                    <l7-text-block [wrap]="true"
                                   [maxLines]="3"
                                   [text]="this.email"
                                   class="mat-subtitle-2"
                                   style="color: inherit;"></l7-text-block>
               </a>
          </div>
          <div *ngIf="this.phone"
               fxLayout="row"
               fxLayoutGap="8px">
               <mat-icon [inline]="true"
                         [fontSet]="'l7'"
                         [fontIcon]="'Phone'"></mat-icon>
               <a class="link primary"
                  [href]="'tel:' + this.phone">
                    <l7-text-block class="mat-subtitle-2"
                                   [wrap]="true"
                                   [maxLines]="3"
                                   [text]="this.phone"
                                   style="color: inherit;"></l7-text-block>
               </a>
          </div>
     </div>

</div>