import { noop } from '../utils';
export var InternalLoggerLevel;
(function (InternalLoggerLevel) {
  InternalLoggerLevel[InternalLoggerLevel["OFF"] = 0] = "OFF";
  InternalLoggerLevel[InternalLoggerLevel["ERROR"] = 1] = "ERROR";
  InternalLoggerLevel[InternalLoggerLevel["WARN"] = 2] = "WARN";
  InternalLoggerLevel[InternalLoggerLevel["INFO"] = 3] = "INFO";
  InternalLoggerLevel[InternalLoggerLevel["VERBOSE"] = 4] = "VERBOSE";
})(InternalLoggerLevel || (InternalLoggerLevel = {}));
export const defaultInternalLoggerPrefix = 'Faro';
export const defaultInternalLogger = {
  debug: noop,
  error: noop,
  info: noop,
  prefix: defaultInternalLoggerPrefix,
  warn: noop
};
export const defaultInternalLoggerLevel = InternalLoggerLevel.ERROR;
