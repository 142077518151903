// #region Imports

import { InjectionToken } from '@angular/core';
import { IPageModuleConfig } from './IPageModuleConfig';

// #endregion

/**
 * @public
 */
export const PAGE_MODULE_CONFIG = new InjectionToken<IPageModuleConfig>('PAGE_MODULE_CONFIG');
