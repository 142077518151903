/* libs/presentation/src/Components/boni-check-icon/boni-check-icon.component.scss */
.mat-icon ::ng-deep .mat-badge-content {
  background: #ffb500;
  color: #b81931;
  width: 18px;
  height: 18px;
  top: 0px;
  right: -8px !important;
  line-height: 17px;
  font-size: 12px;
  text-align: center;
}
/*# sourceMappingURL=boni-check-icon.component.css.map */
