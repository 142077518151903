/* libs/presentation/src/Components/logo-upload/logo-upload.component.css */
image-cropper {
  height: 10vh;
}
.logo-crop-container {
  width: 30vw;
}
.uploadfilecontainer {
  height: fit-content;
}
/*# sourceMappingURL=logo-upload.component.css.map */
