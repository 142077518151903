<div class="block align-center">
    <div class="background"
         id="div_signature-container">
        <l7-signature-pad #signaturePad
                          [options]="signaturePadOptions"
                          (beginSignEvent)="drawStart()"
                          (endSignEvent)="drawComplete()">
        </l7-signature-pad>
    </div>
</div>
<div class="align-center">
    <a (click)="clearSignaturePad()"
       id="anchor_clear-signature-pad">Unterschrift unleserlich? Erneut
        unterschreiben</a>
</div>
