import { ScrollDispatcher, ViewportRuler } from '@angular/cdk/scrolling';
import { Directive, ElementRef, HostListener, Input } from '@angular/core';
import { FormGroupDirective } from '@angular/forms';
import { fromEvent } from 'rxjs';
import { debounceTime, take } from 'rxjs/operators';

@Directive({
    selector: '[l7ScrollToError]',
    standalone: false,
})
export class ScrollToErrorDirective {

    // #region Fields

    private readonly _element: ElementRef<HTMLElement>;
    private readonly _formGroup: FormGroupDirective;
    private readonly _scrollDispatcher: ScrollDispatcher;

    // private readonly _viewportRuler: ViewportRuler;
    private readonly _errorSelector: string;

    @Input() isScrollOnWindow: boolean;
    // #endregion

    // #region Ctor

    public constructor(element: ElementRef, formGroup: FormGroupDirective, scrollDispatcher: ScrollDispatcher, viewportRuler: ViewportRuler) {
        this._element = element;
        this._formGroup = formGroup;
        this._scrollDispatcher = scrollDispatcher;
        // this._viewportRuler = viewportRuler;
        this._errorSelector = '.ng-invalid';
    }

    // #endregion

    // #region Methods

    @HostListener('ngSubmit')
    public onSubmit(): void {
        setTimeout(() => {
            if (this._formGroup.control.invalid) {
                if (this.isScrollOnWindow) {
                    this.scrollToFirstInvalidControlOnWindow();
                } else {
                    this.scrollToFirstInvalidControl();
                }
            }
        }, 300);
    }

    private scrollToFirstInvalidControl(): void {
        setTimeout(() => {
            const scrollable = this._scrollDispatcher.getAncestorScrollContainers(this._element).at(0);
            const child = this._element.nativeElement.querySelector<HTMLElement>(this._errorSelector);

            if (scrollable && child) {
                const scrollRect = scrollable.getElementRef().nativeElement.getBoundingClientRect();
                const childRect = child.getBoundingClientRect();

                if (childRect.top >= 0 && childRect.bottom <= scrollRect.height) {
                    // we dont need a scroll
                    child.focus();
                } else {
                    const parent = child.localName === 'mat-checkbox' ? child.parentElement.parentElement.offsetParent : child.parentElement;
                    parent.scrollIntoView({ behavior: 'smooth' });
                    // scrollable.scrollTo({
                    //     top: this.getTopOffset(scrollable.getElementRef().nativeElement, child),
                    //     left: 0,
                    //     behavior: 'smooth'
                    // });

                    fromEvent(scrollable.getElementRef().nativeElement, 'scroll').pipe(
                        debounceTime(100),
                        take(1),
                    ).subscribe(() => child.focus());
                }
            }
        }, 500);
    }

    private getTopOffset(container: HTMLElement, child: HTMLElement): number {
        const labelOffset = 16;
        const controlElTop = child.getBoundingClientRect().top;
        const containerTop = container.getBoundingClientRect().top;
        const absoluteControlElTop = controlElTop + container.scrollHeight;

        return absoluteControlElTop - containerTop - labelOffset;
    }

    private scrollToFirstInvalidControlOnWindow(): void {
        const scrollable = this._scrollDispatcher.getAncestorScrollContainers(this._element).at(0);
        const child = this._element.nativeElement.querySelector<HTMLElement>(this._errorSelector);

        if (scrollable && child) {
            const childRect = child.getBoundingClientRect();

            if (childRect.top >= 0 && childRect.bottom <= window.innerHeight) {
                // we don't need a scroll
                child.focus();
                window.scrollTo({
                    top: window.scrollY - 60,
                    left: 0,
                    behavior: 'smooth',
                });
            } else {
                window.scrollTo({
                    top: this.getTopOffsetFromWindow(child),
                    left: 0,
                    behavior: 'smooth',
                });

                fromEvent(window, 'scroll').pipe(
                    debounceTime(100),
                    take(1),
                ).subscribe(() => child.focus());
            }
        }
    }

    private getTopOffsetFromWindow(child: HTMLElement): number {
        const labelOffset = 16;
        const controlElTop = child.getBoundingClientRect().top + window.scrollY - (window.innerHeight / 2);

        return controlElTop - labelOffset;
    }

    // #endregion

}
