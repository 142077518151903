// #region Imports

import { ChangeDetectionStrategy, Component, HostBinding, Inject, Input } from '@angular/core';
import { DisplayDensity } from '../../DisplayDensity';
import { HostBindingBuilder } from '../../HostBindingBuilder';
import { ISpacerModuleConfig } from './ISpacerModuleConfig';
import { SPACER_MODULE_CONFIG } from './SpacerModuleTokens';
import { Spacing } from './Spacing';

// #endregion

/**
 * Spacer - A layout component used to create empty space or gaps between other elements.
 *
 * @description
 * The Spacer component is a versatile layout element used to add spacing, margins, or gaps between other UI elements.
 * It helps control the visual layout and alignment of components in a user interface, providing consistent spacing between elements.
 * Spacers can be applied both horizontally and vertically to achieve precise spacing and alignment.
 *
 * @remarks
 * The default value of the `multiplier` property is 8. This value is used to calculate the spacing applied by the Spacer component.
 * For example, if the `multiplier` property is set to 8, the Spacer component will apply 8px of spacing.
 * Internaly, the value of the `multiplier` property will multiplied by the `density` property to calculate the spacing.
 * For example, if the `multiplier` property is set to 8 and the `density` property is set to `comfortable`, the Spacer component will apply (multiplier=8 * density=comfortable=2) 16px of spacing.
 * Desity values are: `compact` = 1, `comfortable` = 2, `cosy` = 3.
 *
 * @public
 */
@Component({
    selector: 'l7-spacer',
    templateUrl: './SpacerComponent.html',
    styleUrls: ['./SpacerComponent.scss'],
    exportAs: 'l7Spacer',
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false,
})
export class SpacerComponent {

    // #region Fields

    private readonly _spacerModuleConfig: ISpacerModuleConfig;

    private _multiplier: number;
    private _density: DisplayDensity;
    private _thicknesses: Array<Spacing>;

    // #endregion

    // #region Ctor

    public constructor(@Inject(SPACER_MODULE_CONFIG) spacerModuleConfig: ISpacerModuleConfig) {
        this._spacerModuleConfig = spacerModuleConfig;

        this._multiplier = this._spacerModuleConfig.defaultMultiplier;
        this._density = DisplayDensity.Compact;
        this._thicknesses = new Array<Spacing>();
    }

    // #endregion

    // #region Properties

    /**
     * Gets or sets the `multiplier` property.
     * The multiplier factor for spacing. Adjusts the space based on a numeric value.
     * Do not use this property directly, use `density` and/or `thickness` property instead.
     *
     * @public
     * @deprecated Use `thickness` and/or `density` property instead.
     */
    @Input()
    public get multiplier(): number {
        return this._multiplier;
    }

    public set multiplier(value: number) {
        if (this._spacerModuleConfig.multiplierValidator(value)) {
            this._multiplier = value;
        }
    }

    /**
     * Gets or sets the `density` property.
     * The density of the Spacer, controlling the amount of space. Accepts values: 'compact', 'comfortable', 'cosy'.
     * The default is `compact`.
     *
     * @public
     */
    @Input()
    public get density(): DisplayDensity | string {
        return this._density;
    }

    public set density(value: DisplayDensity | string) {
        this._density = value as DisplayDensity;
    }

    /**
     * Gets or sets the `thickness` property.
     * The thickness or sides for which the Spacer applies. Accepts values: 'top', 'right', 'bottom', 'left', 'vertical', 'horizontal', 'all'.
     * The default is none.
     *
     * @public
     */
    @Input()
    public get thickness(): Spacing | Array<Spacing> {
        return this._thicknesses;
    }

    public set thickness(value: Spacing | Array<Spacing>) {
        this._thicknesses = this.parseThickness(value);
    }

    /**
     * Returns all styles that applied to the host.
     *
     * @public
     * @readonly
     */
    @HostBinding('style')
    public get styles(): string {
        return HostBindingBuilder.buildStyles({
            ['margin-top']: this.tryGetThickness('top', 'vertical', 'all'),
            ['margin-right']: this.tryGetThickness('right', 'horizontal', 'all'),
            ['margin-bottom']: this.tryGetThickness('bottom', 'vertical', 'all'),
            ['margin-left']: this.tryGetThickness('left', 'horizontal', 'all'),
        });
    }

    // #endregion

    // #region Methods

    /**
     * @private
     */
    private parseThickness(thickness: string | Array<string>): Array<Spacing> {
        const thicknesses = typeof thickness === 'string'
            ? thickness.split(/\s+/)
            : thickness ?? [];
        const knownThicknesses = new Array<Spacing>();

        this.validateTickness(thicknesses);

        thicknesses.forEach((x) => {
            if (['top', 'right', 'bottom', 'left', 'vertical', 'horizontal', 'all'].includes(x as Spacing)) {
                knownThicknesses.push(x as Spacing);
            }
        });

        return knownThicknesses;
    }

    /**
     * @private
     */
    private tryGetThickness(...thicknesses: Array<Spacing>): string | boolean {
        let thickness: string | boolean = false;

        for (const t of thicknesses) {
            const thicknessValue = this._thicknesses.includes(t)
                ? this.parseSize(this._multiplier * this.getDensityAsNumber(this._density))
                : false;

            if (thicknessValue !== false) {
                thickness = thicknessValue;
                break;
            }
        }

        return thickness;
    }

    /**
     * @private
     */
    private validateTickness(thickness: Array<string>): void {
        // validate all
        if (thickness.includes('all')) {
            if (thickness.length > 1) {
                throw new Error('Error while parsing thickness.');
            }
        }

        // validate vertical
        if (thickness.includes('vertical')) {
            if (thickness.includes('top') || thickness.includes('bottom')) {
                throw new Error('Error while parsing thickness');
            }
        }

        // validate horizontal
        if (thickness.includes('horizontal')) {
            if (thickness.includes('left') || thickness.includes('right')) {
                throw new Error('Error while parsing thickness.');
            }
        }

        // validate vertical and horizontal
        if (thickness.includes('vertical') && thickness?.includes('horizontal')) {
            if (thickness.length > 2) {
                throw new Error('Error while parsing thickness.');
            }
        }
    }

    /**
     * @private
     */
    private getDensityAsNumber(density: DisplayDensity): number {
        switch (density) {
            case DisplayDensity.Compact:
                return 1;
            case DisplayDensity.Comfortable:
                return 2;
            case DisplayDensity.Cosy:
                return 3;
            default:
                throw new Error();
        }
    }

    /**
     * @private
     */
    private parseSize(size: string | number | undefined): string {
        if (typeof size === 'string') {
            return size;
        } else if (typeof size === 'number') {
            return `${size}px`;
        }

        return '';
    }

    // #endregion

}
