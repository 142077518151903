// #region Imports

import { Directive, Input, NgModule } from '@angular/core';
import { AbstractControl, NG_VALIDATORS, ValidationErrors, Validator, Validators } from '@angular/forms';

// #endregion

/**
 * @public
 */
@Directive({
    selector: '[l7Min]',
    providers: [{ provide: NG_VALIDATORS, useExisting: MinValidatorDirective, multi: true }],
    standalone: false,
})
export class MinValidatorDirective implements Validator {

    @Input('l7Min')
    public min: number;

    public validate(control: AbstractControl): ValidationErrors | null {
        return this.min
            ? Validators.min(this.min)(control)
            : null;
    }

}

/**
 * @public
 */
@NgModule({
    declarations: [
        MinValidatorDirective,
    ],
    exports: [
        MinValidatorDirective,
    ],
})
export class MinValidatorModule { }
