// #region Imports

import { ChangeDetectionStrategy, Component, HostBinding, Input } from '@angular/core';

// #endregion

/**
 * The `TileComponent` component.
 *
 * @public
 */
@Component({
    selector: 'l7-tile',
    templateUrl: './TileComponent.html',
    styleUrls: ['./TileComponent.scss'],
    exportAs: 'tile',
    host: {
        '[attr.tabindex]': 'disabled ? false : 0',
    },
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false,
})
export class TileComponent {

    // #region Fields

    private _disabled: boolean;
    private _readonly: boolean;
    private _selected: boolean;
    private _inline: boolean;

    // #endregion

    // #region Ctor

    /**
     * Constructs a new instance of the `TileComponent` class.
     *
     * @public
     */
    public constructor() {
    }

    // #endregion

    // #region Properties

    /**
     * Gets or sets the `disabled` property.
     *
     * @public
     */
    @Input()
    @HostBinding('class.disabled')
    @HostBinding('attr.disabled')
    @HostBinding('attr.aria-disabled')
    public get disabled(): boolean {
        return this._disabled;
    }

    public set disabled(value: boolean) {
        this._disabled = value;
    }

    /**
     * Gets or sets the `readonly` property.
     *
     * @public
     */
    @Input()
    @HostBinding('class.readonly')
    @HostBinding('attr.readonly')
    @HostBinding('attr.aria-readonly')
    public get readonly(): boolean {
        return this._readonly;
    }

    public set readonly(value: boolean) {
        this._readonly = value;
    }

    /**
     * Gets or sets the `readonly` property.
     *
     * @public
     */
    @Input()
    @HostBinding('class.selected')
    @HostBinding('attr.selected')
    @HostBinding('attr.aria-selected')
    public get selected(): boolean {
        return this._selected;
    }

    public set selected(value: boolean) {
        this._selected = value;
    }

    /**
     * Gets or sets the `inline` property.
     *
     * @public
     */
    @Input()
    @HostBinding('class.inline')
    public get inline(): boolean {
        return this._inline;
    }

    public set inline(value: boolean) {
        this._inline = value;
    }

    // #endregion

}
