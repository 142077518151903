// #region Imports

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { CheckboxGroupControlValueAccessorDirective } from './Accessors/CheckboxGroupControlValueAccessorDirective';
import { CheckboxGroupComponent } from './CheckboxGroupComponent';

// #endregion

/**
 * @public
 */
@NgModule({
    imports: [
        CommonModule,
        MatCheckboxModule,
    ],
    declarations: [
        CheckboxGroupComponent,
        CheckboxGroupControlValueAccessorDirective,
    ],
    exports: [
        CheckboxGroupComponent,
        CheckboxGroupControlValueAccessorDirective,
    ],
})
export class CheckboxGroupModule { }
