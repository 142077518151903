var t,
  e,
  n = function () {
    var t = self.performance && performance.getEntriesByType && performance.getEntriesByType("navigation")[0];
    if (t && t.responseStart > 0 && t.responseStart < performance.now()) return t;
  },
  r = function (t) {
    if ("loading" === document.readyState) return "loading";
    var e = n();
    if (e) {
      if (t < e.domInteractive) return "loading";
      if (0 === e.domContentLoadedEventStart || t < e.domContentLoadedEventStart) return "dom-interactive";
      if (0 === e.domComplete || t < e.domComplete) return "dom-content-loaded";
    }
    return "complete";
  },
  i = function (t) {
    var e = t.nodeName;
    return 1 === t.nodeType ? e.toLowerCase() : e.toUpperCase().replace(/^#/, "");
  },
  a = function (t, e) {
    var n = "";
    try {
      for (; t && 9 !== t.nodeType;) {
        var r = t,
          a = r.id ? "#" + r.id : i(r) + (r.classList && r.classList.value && r.classList.value.trim() && r.classList.value.trim().length ? "." + r.classList.value.trim().replace(/\s+/g, ".") : "");
        if (n.length + a.length > (e || 100) - 1) return n || a;
        if (n = n ? a + ">" + n : a, r.id) break;
        t = r.parentNode;
      }
    } catch (t) {}
    return n;
  },
  o = -1,
  c = function () {
    return o;
  },
  u = function (t) {
    addEventListener("pageshow", function (e) {
      e.persisted && (o = e.timeStamp, t(e));
    }, !0);
  },
  s = function () {
    var t = n();
    return t && t.activationStart || 0;
  },
  f = function (t, e) {
    var r = n(),
      i = "navigate";
    c() >= 0 ? i = "back-forward-cache" : r && (document.prerendering || s() > 0 ? i = "prerender" : document.wasDiscarded ? i = "restore" : r.type && (i = r.type.replace(/_/g, "-")));
    return {
      name: t,
      value: void 0 === e ? -1 : e,
      rating: "good",
      delta: 0,
      entries: [],
      id: "v4-".concat(Date.now(), "-").concat(Math.floor(8999999999999 * Math.random()) + 1e12),
      navigationType: i
    };
  },
  d = function (t, e, n) {
    try {
      if (PerformanceObserver.supportedEntryTypes.includes(t)) {
        var r = new PerformanceObserver(function (t) {
          Promise.resolve().then(function () {
            e(t.getEntries());
          });
        });
        return r.observe(Object.assign({
          type: t,
          buffered: !0
        }, n || {})), r;
      }
    } catch (t) {}
  },
  l = function (t, e, n, r) {
    var i, a;
    return function (o) {
      e.value >= 0 && (o || r) && ((a = e.value - (i || 0)) || void 0 === i) && (i = e.value, e.delta = a, e.rating = function (t, e) {
        return t > e[1] ? "poor" : t > e[0] ? "needs-improvement" : "good";
      }(e.value, n), t(e));
    };
  },
  m = function (t) {
    requestAnimationFrame(function () {
      return requestAnimationFrame(function () {
        return t();
      });
    });
  },
  p = function (t) {
    document.addEventListener("visibilitychange", function () {
      "hidden" === document.visibilityState && t();
    });
  },
  v = function (t) {
    var e = !1;
    return function () {
      e || (t(), e = !0);
    };
  },
  g = -1,
  h = function () {
    return "hidden" !== document.visibilityState || document.prerendering ? 1 / 0 : 0;
  },
  T = function (t) {
    "hidden" === document.visibilityState && g > -1 && (g = "visibilitychange" === t.type ? t.timeStamp : 0, E());
  },
  y = function () {
    addEventListener("visibilitychange", T, !0), addEventListener("prerenderingchange", T, !0);
  },
  E = function () {
    removeEventListener("visibilitychange", T, !0), removeEventListener("prerenderingchange", T, !0);
  },
  S = function () {
    return g < 0 && (g = h(), y(), u(function () {
      setTimeout(function () {
        g = h(), y();
      }, 0);
    })), {
      get firstHiddenTime() {
        return g;
      }
    };
  },
  b = function (t) {
    document.prerendering ? addEventListener("prerenderingchange", function () {
      return t();
    }, !0) : t();
  },
  L = [1800, 3e3],
  C = function (t, e) {
    e = e || {}, b(function () {
      var n,
        r = S(),
        i = f("FCP"),
        a = d("paint", function (t) {
          t.forEach(function (t) {
            "first-contentful-paint" === t.name && (a.disconnect(), t.startTime < r.firstHiddenTime && (i.value = Math.max(t.startTime - s(), 0), i.entries.push(t), n(!0)));
          });
        });
      a && (n = l(t, i, L, e.reportAllChanges), u(function (r) {
        i = f("FCP"), n = l(t, i, L, e.reportAllChanges), m(function () {
          i.value = performance.now() - r.timeStamp, n(!0);
        });
      }));
    });
  },
  M = [.1, .25],
  D = function (t, e) {
    !function (t, e) {
      e = e || {}, C(v(function () {
        var n,
          r = f("CLS", 0),
          i = 0,
          a = [],
          o = function (t) {
            t.forEach(function (t) {
              if (!t.hadRecentInput) {
                var e = a[0],
                  n = a[a.length - 1];
                i && t.startTime - n.startTime < 1e3 && t.startTime - e.startTime < 5e3 ? (i += t.value, a.push(t)) : (i = t.value, a = [t]);
              }
            }), i > r.value && (r.value = i, r.entries = a, n());
          },
          c = d("layout-shift", o);
        c && (n = l(t, r, M, e.reportAllChanges), p(function () {
          o(c.takeRecords()), n(!0);
        }), u(function () {
          i = 0, r = f("CLS", 0), n = l(t, r, M, e.reportAllChanges), m(function () {
            return n();
          });
        }), setTimeout(n, 0));
      }));
    }(function (e) {
      var n = function (t) {
        var e,
          n = {};
        if (t.entries.length) {
          var i = t.entries.reduce(function (t, e) {
            return t && t.value > e.value ? t : e;
          });
          if (i && i.sources && i.sources.length) {
            var o = (e = i.sources).find(function (t) {
              return t.node && 1 === t.node.nodeType;
            }) || e[0];
            o && (n = {
              largestShiftTarget: a(o.node),
              largestShiftTime: i.startTime,
              largestShiftValue: i.value,
              largestShiftSource: o,
              largestShiftEntry: i,
              loadState: r(i.startTime)
            });
          }
        }
        return Object.assign(t, {
          attribution: n
        });
      }(e);
      t(n);
    }, e);
  },
  w = function (t, e) {
    C(function (e) {
      var i = function (t) {
        var e = {
          timeToFirstByte: 0,
          firstByteToFCP: t.value,
          loadState: r(c())
        };
        if (t.entries.length) {
          var i = n(),
            a = t.entries[t.entries.length - 1];
          if (i) {
            var o = i.activationStart || 0,
              u = Math.max(0, i.responseStart - o);
            e = {
              timeToFirstByte: u,
              firstByteToFCP: t.value - u,
              loadState: r(t.entries[0].startTime),
              navigationEntry: i,
              fcpEntry: a
            };
          }
        }
        return Object.assign(t, {
          attribution: e
        });
      }(e);
      t(i);
    }, e);
  },
  x = 0,
  I = 1 / 0,
  k = 0,
  A = function (t) {
    t.forEach(function (t) {
      t.interactionId && (I = Math.min(I, t.interactionId), k = Math.max(k, t.interactionId), x = k ? (k - I) / 7 + 1 : 0);
    });
  },
  F = function () {
    return t ? x : performance.interactionCount || 0;
  },
  P = function () {
    "interactionCount" in performance || t || (t = d("event", A, {
      type: "event",
      buffered: !0,
      durationThreshold: 0
    }));
  },
  B = [],
  O = new Map(),
  R = 0,
  j = function () {
    var t = Math.min(B.length - 1, Math.floor((F() - R) / 50));
    return B[t];
  },
  q = [],
  H = function (t) {
    if (q.forEach(function (e) {
      return e(t);
    }), t.interactionId || "first-input" === t.entryType) {
      var e = B[B.length - 1],
        n = O.get(t.interactionId);
      if (n || B.length < 10 || t.duration > e.latency) {
        if (n) t.duration > n.latency ? (n.entries = [t], n.latency = t.duration) : t.duration === n.latency && t.startTime === n.entries[0].startTime && n.entries.push(t);else {
          var r = {
            id: t.interactionId,
            latency: t.duration,
            entries: [t]
          };
          O.set(r.id, r), B.push(r);
        }
        B.sort(function (t, e) {
          return e.latency - t.latency;
        }), B.length > 10 && B.splice(10).forEach(function (t) {
          return O.delete(t.id);
        });
      }
    }
  },
  N = function (t) {
    var e = self.requestIdleCallback || self.setTimeout,
      n = -1;
    return t = v(t), "hidden" === document.visibilityState ? t() : (n = e(t), p(t)), n;
  },
  W = [200, 500],
  z = function (t, e) {
    "PerformanceEventTiming" in self && "interactionId" in PerformanceEventTiming.prototype && (e = e || {}, b(function () {
      var n;
      P();
      var r,
        i = f("INP"),
        a = function (t) {
          N(function () {
            t.forEach(H);
            var e = j();
            e && e.latency !== i.value && (i.value = e.latency, i.entries = e.entries, r());
          });
        },
        o = d("event", a, {
          durationThreshold: null !== (n = e.durationThreshold) && void 0 !== n ? n : 40
        });
      r = l(t, i, W, e.reportAllChanges), o && (o.observe({
        type: "first-input",
        buffered: !0
      }), p(function () {
        a(o.takeRecords()), r(!0);
      }), u(function () {
        R = F(), B.length = 0, O.clear(), i = f("INP"), r = l(t, i, W, e.reportAllChanges);
      }));
    }));
  },
  U = [],
  V = [],
  _ = 0,
  G = new WeakMap(),
  J = new Map(),
  K = -1,
  Q = function (t) {
    U = U.concat(t), X();
  },
  X = function () {
    K < 0 && (K = N(Y));
  },
  Y = function () {
    J.size > 10 && J.forEach(function (t, e) {
      O.has(e) || J.delete(e);
    });
    var t = B.map(function (t) {
        return G.get(t.entries[0]);
      }),
      e = V.length - 50;
    V = V.filter(function (n, r) {
      return r >= e || t.includes(n);
    });
    for (var n = new Set(), r = 0; r < V.length; r++) {
      var i = V[r];
      nt(i.startTime, i.processingEnd).forEach(function (t) {
        n.add(t);
      });
    }
    var a = U.length - 1 - 50;
    U = U.filter(function (t, e) {
      return t.startTime > _ && e > a || n.has(t);
    }), K = -1;
  };
q.push(function (t) {
  t.interactionId && t.target && !J.has(t.interactionId) && J.set(t.interactionId, t.target);
}, function (t) {
  var e,
    n = t.startTime + t.duration;
  _ = Math.max(_, t.processingEnd);
  for (var r = V.length - 1; r >= 0; r--) {
    var i = V[r];
    if (Math.abs(n - i.renderTime) <= 8) {
      (e = i).startTime = Math.min(t.startTime, e.startTime), e.processingStart = Math.min(t.processingStart, e.processingStart), e.processingEnd = Math.max(t.processingEnd, e.processingEnd), e.entries.push(t);
      break;
    }
  }
  e || (e = {
    startTime: t.startTime,
    processingStart: t.processingStart,
    processingEnd: t.processingEnd,
    renderTime: n,
    entries: [t]
  }, V.push(e)), (t.interactionId || "first-input" === t.entryType) && G.set(t, e), X();
});
var Z,
  $,
  tt,
  et,
  nt = function (t, e) {
    for (var n, r = [], i = 0; n = U[i]; i++) if (!(n.startTime + n.duration < t)) {
      if (n.startTime > e) break;
      r.push(n);
    }
    return r;
  },
  rt = function (t, n) {
    e || (e = d("long-animation-frame", Q)), z(function (e) {
      var n = function (t) {
        var e = t.entries[0],
          n = G.get(e),
          i = e.processingStart,
          o = n.processingEnd,
          c = n.entries.sort(function (t, e) {
            return t.processingStart - e.processingStart;
          }),
          u = nt(e.startTime, o),
          s = t.entries.find(function (t) {
            return t.target;
          }),
          f = s && s.target || J.get(e.interactionId),
          d = [e.startTime + e.duration, o].concat(u.map(function (t) {
            return t.startTime + t.duration;
          })),
          l = Math.max.apply(Math, d),
          m = {
            interactionTarget: a(f),
            interactionTargetElement: f,
            interactionType: e.name.startsWith("key") ? "keyboard" : "pointer",
            interactionTime: e.startTime,
            nextPaintTime: l,
            processedEventEntries: c,
            longAnimationFrameEntries: u,
            inputDelay: i - e.startTime,
            processingDuration: o - i,
            presentationDelay: Math.max(l - o, 0),
            loadState: r(e.startTime)
          };
        return Object.assign(t, {
          attribution: m
        });
      }(e);
      t(n);
    }, n);
  },
  it = [2500, 4e3],
  at = {},
  ot = function (t, e) {
    !function (t, e) {
      e = e || {}, b(function () {
        var n,
          r = S(),
          i = f("LCP"),
          a = function (t) {
            e.reportAllChanges || (t = t.slice(-1)), t.forEach(function (t) {
              t.startTime < r.firstHiddenTime && (i.value = Math.max(t.startTime - s(), 0), i.entries = [t], n());
            });
          },
          o = d("largest-contentful-paint", a);
        if (o) {
          n = l(t, i, it, e.reportAllChanges);
          var c = v(function () {
            at[i.id] || (a(o.takeRecords()), o.disconnect(), at[i.id] = !0, n(!0));
          });
          ["keydown", "click"].forEach(function (t) {
            addEventListener(t, function () {
              return N(c);
            }, !0);
          }), p(c), u(function (r) {
            i = f("LCP"), n = l(t, i, it, e.reportAllChanges), m(function () {
              i.value = performance.now() - r.timeStamp, at[i.id] = !0, n(!0);
            });
          });
        }
      });
    }(function (e) {
      var r = function (t) {
        var e = {
          timeToFirstByte: 0,
          resourceLoadDelay: 0,
          resourceLoadDuration: 0,
          elementRenderDelay: t.value
        };
        if (t.entries.length) {
          var r = n();
          if (r) {
            var i = r.activationStart || 0,
              o = t.entries[t.entries.length - 1],
              c = o.url && performance.getEntriesByType("resource").filter(function (t) {
                return t.name === o.url;
              })[0],
              u = Math.max(0, r.responseStart - i),
              s = Math.max(u, c ? (c.requestStart || c.startTime) - i : 0),
              f = Math.max(s, c ? c.responseEnd - i : 0),
              d = Math.max(f, o.startTime - i);
            e = {
              element: a(o.element),
              timeToFirstByte: u,
              resourceLoadDelay: s - u,
              resourceLoadDuration: f - s,
              elementRenderDelay: d - f,
              navigationEntry: r,
              lcpEntry: o
            }, o.url && (e.url = o.url), c && (e.lcpResourceEntry = c);
          }
        }
        return Object.assign(t, {
          attribution: e
        });
      }(e);
      t(r);
    }, e);
  },
  ct = [800, 1800],
  ut = function t(e) {
    document.prerendering ? b(function () {
      return t(e);
    }) : "complete" !== document.readyState ? addEventListener("load", function () {
      return t(e);
    }, !0) : setTimeout(e, 0);
  },
  st = function (t, e) {
    e = e || {};
    var r = f("TTFB"),
      i = l(t, r, ct, e.reportAllChanges);
    ut(function () {
      var a = n();
      a && (r.value = Math.max(a.responseStart - s(), 0), r.entries = [a], i(!0), u(function () {
        r = f("TTFB", 0), (i = l(t, r, ct, e.reportAllChanges))(!0);
      }));
    });
  },
  ft = function (t, e) {
    st(function (e) {
      var n = function (t) {
        var e = {
          waitingDuration: 0,
          cacheDuration: 0,
          dnsDuration: 0,
          connectionDuration: 0,
          requestDuration: 0
        };
        if (t.entries.length) {
          var n = t.entries[0],
            r = n.activationStart || 0,
            i = Math.max((n.workerStart || n.fetchStart) - r, 0),
            a = Math.max(n.domainLookupStart - r, 0),
            o = Math.max(n.connectStart - r, 0),
            c = Math.max(n.connectEnd - r, 0);
          e = {
            waitingDuration: i,
            cacheDuration: a - i,
            dnsDuration: o - a,
            connectionDuration: c - o,
            requestDuration: t.value - c,
            navigationEntry: n
          };
        }
        return Object.assign(t, {
          attribution: e
        });
      }(e);
      t(n);
    }, e);
  },
  dt = {
    passive: !0,
    capture: !0
  },
  lt = new Date(),
  mt = function (t, e) {
    Z || (Z = e, $ = t, tt = new Date(), gt(removeEventListener), pt());
  },
  pt = function () {
    if ($ >= 0 && $ < tt - lt) {
      var t = {
        entryType: "first-input",
        name: Z.type,
        target: Z.target,
        cancelable: Z.cancelable,
        startTime: Z.timeStamp,
        processingStart: Z.timeStamp + $
      };
      et.forEach(function (e) {
        e(t);
      }), et = [];
    }
  },
  vt = function (t) {
    if (t.cancelable) {
      var e = (t.timeStamp > 1e12 ? new Date() : performance.now()) - t.timeStamp;
      "pointerdown" == t.type ? function (t, e) {
        var n = function () {
            mt(t, e), i();
          },
          r = function () {
            i();
          },
          i = function () {
            removeEventListener("pointerup", n, dt), removeEventListener("pointercancel", r, dt);
          };
        addEventListener("pointerup", n, dt), addEventListener("pointercancel", r, dt);
      }(e, t) : mt(e, t);
    }
  },
  gt = function (t) {
    ["mousedown", "keydown", "touchstart", "pointerdown"].forEach(function (e) {
      return t(e, vt, dt);
    });
  },
  ht = [100, 300],
  Tt = function (t, e) {
    e = e || {}, b(function () {
      var n,
        r = S(),
        i = f("FID"),
        a = function (t) {
          t.startTime < r.firstHiddenTime && (i.value = t.processingStart - t.startTime, i.entries.push(t), n(!0));
        },
        o = function (t) {
          t.forEach(a);
        },
        c = d("first-input", o);
      n = l(t, i, ht, e.reportAllChanges), c && (p(v(function () {
        o(c.takeRecords()), c.disconnect();
      })), u(function () {
        var r;
        i = f("FID"), n = l(t, i, ht, e.reportAllChanges), et = [], $ = -1, Z = null, gt(addEventListener), r = a, et.push(r), pt();
      }));
    });
  },
  yt = function (t, e) {
    Tt(function (e) {
      var n = function (t) {
        var e = t.entries[0],
          n = {
            eventTarget: a(e.target),
            eventType: e.name,
            eventTime: e.startTime,
            eventEntry: e,
            loadState: r(e.startTime)
          };
        return Object.assign(t, {
          attribution: n
        });
      }(e);
      t(n);
    }, e);
  };
export { M as CLSThresholds, L as FCPThresholds, ht as FIDThresholds, W as INPThresholds, it as LCPThresholds, ct as TTFBThresholds, D as onCLS, w as onFCP, yt as onFID, rt as onINP, ot as onLCP, ft as onTTFB };