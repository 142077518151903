/* libs/presentation/src/Components/section-header/section-header.component.scss */
.section-header {
  display: flex;
  flex-direction: row;
  color: #83929b;
  padding-left: 6px;
}
.section-header .header-icon,
.section-header .field-description,
.section-header a {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.section-header .header-icon {
  font-size: 19px;
  width: 33px;
  line-height: 60px;
}
.section-header .field-description {
  width: 75%;
  font-weight: bold;
}
.section-header ::ng-deep a {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 40%;
  color: darkred;
  text-decoration: underline;
}
/*# sourceMappingURL=section-header.component.css.map */
