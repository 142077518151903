/* libs/presentation/src/Components/carousel/CarouselComponent.scss */
:host .carousel {
  list-style: none;
  margin: 0;
  padding: 0;
  width: 6000px;
}
:host .carousel-inner {
  display: flex;
  padding-left: 1px;
}
:host ul {
  list-style-type: none;
}
:host ul.carousel-inner-padding-left {
  padding-left: 24px;
}
:host ul li {
  display: flex;
  margin-right: 24px;
}
:host .slider-container-transition {
  transition: transform 0.2s ease-in-out;
}
:host .fake-item {
  height: 146px;
}
:host .left-button {
  margin-left: auto;
}
:host .right-button {
  margin-right: auto;
}
:host section {
  margin-right: 32px !important;
  margin-left: 32px;
  overflow: hidden;
}
:host section.carousel-wrapper-justify-content {
  justify-content: center;
}
/*# sourceMappingURL=CarouselComponent.css.map */
