/* libs/ui/src/Controls/Components/Persona/PersonaGroupComponent.scss */
:host {
  display: block;
}
:host ::ng-deep l7-persona {
  float: left;
  margin-left: -8px;
  margin-right: 0px;
  transition: margin-right 0.2s;
}
:host ::ng-deep l7-persona:hover {
  margin-right: 8px;
}
/*# sourceMappingURL=PersonaGroupComponent.css.map */
