// #region Imports

import { type Injector } from '@angular/core';

// #endregion

/**
 * @public
 */
export class StatelessInjector implements Injector {

    // #region Fields

    private readonly _parentInjector: Injector;

    private _injectionTokens: WeakMap<any, any> | null;

    // #endregion

    // #region Ctor

    public constructor(parentInjector: Injector, injectionTokens?: Array<[any, any]>) {
        this._parentInjector = parentInjector;
        this._injectionTokens = injectionTokens ? new WeakMap<any, any>(injectionTokens) : null;
    }

    // #endregion

    // #region Methods

    /**
     * @public
     */
    public dispose(): void {
        this._injectionTokens = null;
    }

    /**
     * @public
     */
    public get(token: any, notFoundValue?: any): any {
        if (this._injectionTokens) {
            const value = this._injectionTokens.get(token);
            if (value !== undefined) {
                return value;
            }
        }

        return this._parentInjector.get<any>(token, notFoundValue);
    }

    // #endregion

}
