import { IObjectGroupDto } from '@abcfinlab/api/global';
import { CalculatedInsuranceRangeDTO } from '../models/CalculatedInsuranceRangeDTO.interface';
import { ContractTypeId } from '../models/enums/ContractTypeId.enum';
import { InsuranceType } from '../models/enums/InsuranceType.enum';
import { ObjectGroupDTO } from '../models/ObjectGroupDTO.interface';

export function toDecimal(value: any, digits: number = 2, up: boolean = false): number {
    try {
        const factor = Math.pow(10, digits); // This is used to shift the decimal places
        let result = Math.floor(value * factor) / factor; // Default rounding down

        // If the 'up' flag is true, we use Math.round to round up
        if (up) {
            result = Math.round((value + Number.EPSILON) * factor) / factor;
        }

        return Number(result.toFixed(digits));
    } catch (e) {
    // In case of error, return a rounded 0
        return Number(Math.round(0).toFixed(digits));
    }
}

export function getPercent(from: number, of: number, digits: number = 2, up: boolean = false): number {
    try {
        const percent = (of * 100) / from;
        return toDecimal(percent, digits, up);
    } catch (e) {
        console.error(e);
    }
}

export function getPercentageValue(percentage: number, basis: number): number {
    try {
        const result = (basis * percentage) / 100;
        return toDecimal(result, 2, true);
    } catch (e) {
        console.error(e);
    }
}

export function contractTypeRestriction(contractTypes: Array<ContractTypeId>, env: boolean) {
    if (env) {
        return [ContractTypeId.VA, ContractTypeId.TA] as Array<ContractTypeId>;
    } else {
        return contractTypes;
    }
}

/**
 * Decide if a insurance is possible in general. This decision is made base on the 'insurance_value' of an object-group.
 * @param objectGroupInsuranceFactor      The insurance value of a given object group
 */
export function insuranceIsPossible(objectGroupInsuranceFactor: number): boolean {
    if (typeof objectGroupInsuranceFactor === 'undefined' || objectGroupInsuranceFactor === null) {
        return false;
    }
    if (objectGroupInsuranceFactor <= 0) {
        return false;
    }
    if (objectGroupInsuranceFactor > 0) {
        return true;
    }
}

export function is100ProInsurance(contractType: ContractTypeId, insuranceType: InsuranceType = null): boolean {
    if (insuranceType === InsuranceType.STANDARD) {
        return false;
    }
    // return insuranceType === InsuranceType.HUNDRED_PRO
    return contractType === ContractTypeId.KFZ || contractType === ContractTypeId.MIETKAUF;
}

export function isOrdinaryInsurance(contractType: ContractTypeId, insuranceType: InsuranceType = null): boolean {
    if (insuranceType === InsuranceType.HUNDRED_PRO) {
        return false;
    }
    // return insuranceType === InsuranceType.STANDARD;
    return contractType === ContractTypeId.TA || contractType === ContractTypeId.VA;
}

/**
 * Get the insurance factor for a selected object-group. The factor is used to calculate the min/max insurance value.
 */
export function getInsuranceFactorFromObjectGroup(objectGroup: Partial<ObjectGroupDTO>) {
    if (typeof objectGroup === 'undefined' || objectGroup === null || objectGroup.insurance_value === null || typeof objectGroup.insurance_value === 'undefined') {
        return 0;
    }
    return objectGroup.insurance_value;
}

/**
 * Get the calculated insurance value range provided by the corresponding object group according to a object value.
 */
export function calculateInsuranceValueRange(objectValue: number, insuranceFactor: number): CalculatedInsuranceRangeDTO {
    if (insuranceFactor === null || typeof insuranceFactor === 'undefined' || objectValue === null || typeof objectValue === 'undefined') {
        throw new Error('Cannot calculate insurance value! Missing parameters.');
    }
    const multipliedValue = objectValue * insuranceFactor;
    const maxValue = multipliedValue * 2;
    const minValue = multipliedValue / 2;

    return {
        minValue: toDecimal(minValue, 2, true),
        maxValue: toDecimal(maxValue, 2, true),
    };
}

export function calculateDefaultInsuranceValue(objectValue: number, insuranceFactor: number): number {
    if (insuranceFactor === null || typeof insuranceFactor === 'undefined' || objectValue === null || typeof objectValue === 'undefined') {
        throw new Error('Cannot calculate insurance value! Missing parameters.');
    }
    const minMaxValues = calculateInsuranceValueRange(objectValue, insuranceFactor);
    return toDecimal((minMaxValues.maxValue + minMaxValues.minValue) / 2);
}

/**
 * Get the calculated insurance pro mille value according to a object value and insurance value.
 */
export function calculateInsuranceProMilleValue(objectValue: number, insuranceValue: number): number {
    if (insuranceValue === null || typeof insuranceValue === 'undefined' || objectValue === null || typeof objectValue === 'undefined') {
        throw new Error('Cannot calculate insurance pro mille value! Missing parameters.');
    }

    return toDecimal((insuranceValue / objectValue) * 1000, 4, true);
}

export function contractTypeHasResidualValue(contractType: ContractTypeId): boolean {
    if (contractType === ContractTypeId.KFZ) {
        return true;
    }
    if (contractType === ContractTypeId.TA) {
        return true;
    }
    if (contractType === ContractTypeId.VA) {
        return false;
    }
}

export function findObjectGroupById(groups: Partial<Array<ObjectGroupDTO>>, needle: string): ObjectGroupDTO {
    return groups.find(group => group.code === needle);
}

/**
 *  Filter the object group array by a given search term.
 */
export function filterObjectGroupsForCalculation(objectGroup: ObjectGroupDTO | IObjectGroupDto, searchTerm: string): boolean {
    return objectGroup.name.toLowerCase().indexOf(searchTerm) > -1 || objectGroup.code.toString().indexOf(searchTerm) > -1;
}

export function hundredProMustBeDisabled(totalLeasingValue?: number, objectGroupCode?: number) {
    if (totalLeasingValue && objectGroupCode) {
        return (objectGroupCode === 2190) || (objectGroupCode === 2290)
            || ((objectGroupCode < 2120) && totalLeasingValue >= 100000)
            || ((objectGroupCode >= 2120) && totalLeasingValue >= 130000);
    }
    return false;
}
/**
 * Prevent values to be displayed as "0" or "0,00" etc.
 * @param value   The value to be
 */
export const preventNullValue = (value: number) => value === 0 ? null : value;
