/* libs/layout/src/Views/PresenterView.scss */
my-box {
  background-color: var(--l7-info-500-color);
}
:host {
  background-color: #e5e5e5;
  width: 100%;
  height: 100%;
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 2;
}
:host .busy-bar {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
}
:host .sidenav-toolbar {
  background-color: white;
  height: 160px;
}
:host .sidenav-toolbar.xs,
:host .sidenav-toolbar.sm {
  height: 120px;
}
:host .sidenav {
  overflow: initial;
}
:host .sidenav.collapse {
  transform: translate3d(0, 0, 0);
}
:host a {
  text-decoration: none;
  margin-top: 4px;
}
:host a .stage {
  font-size: 14px;
  line-height: 14px;
  color: rgba(48, 59, 69, 0.54);
}
:host .badge {
  margin: 0 16px;
}
:host .content-toolbar {
  background-color: white;
  z-index: 1;
  border-bottom: solid 1px rgba(48, 59, 69, 0.12);
}
:host .sidenav-toggle {
  position: absolute;
  top: 120px;
  right: -20px;
  box-shadow:
    0 3px 1px -2px rgba(0, 0, 0, 0.2),
    0 2px 2px rgba(0, 0, 0, 0.1411764706),
    0 1px 5px rgba(0, 0, 0, 0.1215686275);
  z-index: 100;
}
:host .content {
  display: block;
  height: inherit;
  margin: 0 auto;
  max-width: 1280px;
  min-width: 550px;
  width: 1280px;
}
:host .content.xs {
  padding-right: 16px;
  padding-left: 16px;
  max-width: initial;
  min-width: initial;
  width: initial;
}
:host .content.sm {
  padding-right: 16px;
  padding-left: 16px;
  max-width: initial;
  min-width: initial;
  width: initial;
}
:host .content.md {
  padding-right: 24px;
  padding-left: 24px;
  max-width: initial;
  min-width: initial;
  width: initial;
}
:host .content.md.more-padding {
  padding-left: 64px;
}
:host .content.lg {
  padding-right: 24px;
  padding-left: 24px;
}
:host .content.lg.more-padding {
  padding-left: 64px;
}
:host .content.xl {
  padding-right: 24px;
  padding-left: 24px;
}
:host .content.xl.more-padding {
  padding-left: 64px;
}
/*# sourceMappingURL=PresenterView.css.map */
