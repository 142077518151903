/* libs/ui/src/Controls/Components/TextBlock/TextBlockComponent.scss */
my-box {
  background-color: var(--l7-info-500-color);
}
:host {
  display: -webkit-inline-box;
  -webkit-box-orient: vertical;
  white-space: nowrap;
  overflow: hidden;
  box-sizing: border-box;
  word-break: normal;
  line-break: normal;
  letter-spacing: -0.1px;
  line-height: 1.15;
}
:host .text {
  display: -webkit-inline-box;
}
:host.truncate {
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
:host.truncate .text {
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
:host .text {
  white-space: normal;
}
:host .text.wrap {
  white-space: pre-wrap;
}
:host .text.breakLine {
  line-break: anywhere;
}
:host.block {
  display: -webkit-box;
}
:host.readonly {
  color: rgba(48, 59, 69, 0.54);
}
:host.disabled {
  color: rgba(0, 0, 0, 0.38);
}
:host .text {
  white-space: pre;
  line-height: 1.15;
  font-size: unset;
  color: unset;
}
:host.bold {
  font-weight: 700;
}
:host.primary {
  color: #1b7e9a;
}
:host.accent {
  color: #1b7e9a;
}
:host.warn {
  color: #ffa800;
}
:host.info {
  color: #1b7e9a;
}
:host.danger {
  color: #ad0149;
}
:host.success {
  color: #02cc83;
}
/*# sourceMappingURL=TextBlockComponent.css.map */
