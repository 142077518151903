/* libs/layout/src/Controls/Components/Page/PageComponent.scss */
:host {
  display: block;
  flex: 1 1 0%;
  flex-direction: column;
  flex-wrap: nowrap;
  align-items: stretch;
  align-content: flex-start;
  justify-content: flex-start;
  padding: 16px 0;
  height: 100%;
  width: 100%;
}
/*# sourceMappingURL=PageComponent.css.map */
