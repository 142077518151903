/**
 * An enumeration of status codes.
 */
export var SpanStatusCode;
(function (SpanStatusCode) {
  /**
   * The default status.
   */
  SpanStatusCode[SpanStatusCode["UNSET"] = 0] = "UNSET";
  /**
   * The operation has been validated by an Application developer or
   * Operator to have completed successfully.
   */
  SpanStatusCode[SpanStatusCode["OK"] = 1] = "OK";
  /**
   * The operation contains an error.
   */
  SpanStatusCode[SpanStatusCode["ERROR"] = 2] = "ERROR";
})(SpanStatusCode || (SpanStatusCode = {}));
