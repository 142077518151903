<l7-dialog-header [title]="'contract_management.signatures.title' | translate"
                  [closeable]="true" (closed)="onClose()"></l7-dialog-header>
<l7-busy-box *ngIf="!(signaturesData | async)"></l7-busy-box>
<mat-dialog-content fxLayout="column"
                    fxLayoutGap="16px" *ngIf="signaturesData | async">
    <mat-card appearance="outlined" class="mat-card-outlined" *ngFor="let signatureData of (signaturesData | async).signatureInfos">
        <mat-card-header>
            <l7-text-block matCardTitle
                           [wrap]="true"
                           [text]="'contract_management.signatures.' + signatureData.documentType | translate">
            </l7-text-block>
        </mat-card-header>
        <mat-card-content fxLayout="column">
            <div fxLayout="row"
                 fxLayoutGap="32px">
                <div fxFlex="0 1 calc(65% - 32px)"
                     fxLayout="column">
                    <mat-card appearance="outlined" class="mat-card-flat">
                        <mat-card-content class="more-padding grey center">
                            <img [src]="signatureData.signatureUrl" alt="signature">
                        </mat-card-content>
                    </mat-card>
                </div>
                <div fxFlex="0 1 calc(35% - 32px)"
                     [style.padding-top]="'16px'"
                     fxLayout="column">
                    <fieldset fxLayout="column">
                        <legend>{{ 'Name' | translate }}</legend>
                        <l7-text-block [truncate]="true"
                                       [text]="signatureData.signerName"></l7-text-block>
                    </fieldset>
                    <fieldset fxLayout="column">
                        <legend>{{ 'Datum' | translate }}</legend>
                        <l7-text-block [truncate]="true"
                                       [text]="signatureData.signingDate | date"></l7-text-block>
                    </fieldset>
                </div>
            </div>
        </mat-card-content>
    </mat-card>
</mat-dialog-content>
