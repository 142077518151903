<div fxFlex
     [fxLayout]="this.orientation === 'horizontal' ? 'row' : 'column'"
     fxLayout.sm="column"
     fxLayout.xs="column"
     fxLayoutGap="16px">
    <div fxFlex style="position: relative;">
        <div class="disable-upload"
             fxFill
             fxFlex
             fxLayout="column"
             fxLayoutAlign="center center"
             *ngIf="this.files.length === this.maxFiles"></div>

        <div fxFlex
             class="uploadfilecontainer"
             (click)="fileInput.click()"
             l7LibDragDrop
             (fileDropped)="uploadFile($event)">
            <div fxFill
                 fxFlex
                 fxLayout="column"
                 fxLayoutAlign="center center"
                 fxLayoutGap="8px">
                <mat-icon class="dragndrop"
                          [color]="'primary'"
                          [fontSet]="'l7'"
                          [fontIcon]="'Cloud_Upload'"></mat-icon>
                <span class="label-upload">Datei hierher ziehen oder</span>
                <button type="button"
                        mat-flat-button
                        [attr.test-id]="'uploadButton'"
                        class="link-button">
                    Datei hochladen
                </button>
                <p class="error-message align-center"
                   *ngIf="errorMessage"
                   [innerHTML]="errorMessage"></p>
                <input hidden
                       type="file"
                       #fileInput
                       (change)="uploadFile($event.target.files)"
                       id="uploaded-file"
                       [accept]="allowedFileTypes.join(',')"
                       [formControl]="documentInput">
            </div>
        </div>
    </div>

    <l7-text-block fxLayout="row"
                   *ngIf="showMessage"
                   [alignment]="'center'"
                   [text]="'Folgende Dateiformate sind zulässig: PDF, JP(E)G, PNG. Die zulässige Dateigröße beträgt 10 MB.' | translate">
    </l7-text-block>
    <div fxFlex
         *ngIf="files?.length">
        <div fxLayout="row"
             *ngFor="let file of files;let i = index"
             fxLayoutGap="8px">
            <mat-card fxFlex
                      fxLayout="column"
                      [ngClass]="fileListClass"
                      class="files-list">
                <div class="file-content" fxLayoutGap="4px">
                    <mat-icon [inline]="true"
                              fxFlex="1 1 6%"
                              [fontSet]="'l7'"
                              [fontIcon]="'Note_Text'"></mat-icon>
                    <p fxFlex="1 1 85%"> {{ file.name }} </p>
                    <div class="actions"
                         fxFlex="1 1 9%"
                         fxLayoutAlign="flex-end center">
                        <button mat-icon-button
                                (click)="uploadAttachment(i)"
                                *ngIf="showUploadIcon">
                            <mat-icon [inline]="true"
                                      [fontSet]="'l7'"
                                      [fontIcon]="'File_Upload'"></mat-icon>
                        </button>
                        <button mat-icon-button
                                [attr.test-id]="'deleteButton' + i"
                                (click)="deleteAttachment(i)">
                            <mat-icon [inline]="true"
                                      [fontSet]="'l7'"
                                      [fontIcon]="'Trash'"></mat-icon>
                        </button>
                    </div>
                </div>
                <div *ngIf="showProgressBar">
                    <mat-progress-bar mode="determinate"
                                      value="0"></mat-progress-bar>
                </div>
            </mat-card>
            <ng-container *ngIf="optionTemplate">
                <ng-container [ngTemplateOutlet]="optionTemplate"
                              [ngTemplateOutletContext]="{ $implicit: {option: option, index: i}}">
                </ng-container>
            </ng-container>
        </div>

        <div fxLayout="row"
             *ngIf="useUploadButton">
            <button class="button button-blue"
                    (click)="sendFiles($event)"
                    [attr.test-id]="'ButtonUploadFiles'"
                    [disabled]="!files.length || !canUpload"
                    mat-button
                    [color]="'accent'">
                {{'global.upload' | translate}}
            </button>
        </div>
    </div>
</div>
