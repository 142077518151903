// #region Imports

import { TranslationModule } from '@abcfinlab/core';
import { BreakpointObserverModule, TextBlockModule } from '@abcfinlab/ui';
import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { FlexLayoutModule } from '@ngbracket/ngx-layout';
import { PresenterService } from '../../../Services/PresenterService';
import { IPageModuleConfig } from './IPageModuleConfig';
import { PageComponent } from './PageComponent';
import { PageContentComponent } from './PageContentComponent';
import { PageFooterComponent } from './PageFooterComponent';
import { PageHeaderComponent } from './PageHeaderComponent';
import { PAGE_MODULE_CONFIG } from './PageModuleTokens';
import { PagePreContentComponent } from './PagePreContentComponent';
import { PageSectionComponent } from './PageSectionComponent';

// #endregion

/**
 * The `PageModule` module.
 *
 * @public
 */
@NgModule({
    imports: [
        CommonModule,
        MatIconModule,
        FlexLayoutModule,
        TextBlockModule,
        BreakpointObserverModule,
        TranslationModule,
    ],
    declarations: [
        PageComponent,
        PageHeaderComponent,
        PagePreContentComponent,
        PageContentComponent,
        PageSectionComponent,
        PageFooterComponent,
    ],
    exports: [
        PageComponent,
        PageHeaderComponent,
        PagePreContentComponent,
        PageContentComponent,
        PageSectionComponent,
        PageFooterComponent,
    ],
    providers: [
        PresenterService,
    ],
})
export class PageModule {

    // #region Methods

    public static forRoot(config: IPageModuleConfig): ModuleWithProviders<PageModule> {
        return {
            ngModule: PageModule,
            providers: [
                { provide: PAGE_MODULE_CONFIG, useValue: config },
            ],
        };
    }

    // #endregion

}
