import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import * as blobUtil from 'blob-util';
import { SignaturePadOptions } from 'libs/legacy/gwg/src/lib/signature/signature/signature.component';
import { SignaturePadComponent } from '../signature-pad/SignaturePadComponent';

@Component({
    selector: 'l7-signature',
    templateUrl: './signature.component.html',
    styleUrls: ['./signature.component.scss'],
    standalone: false,
})
export class SignatureComponent {

    @ViewChild('signaturePad') signaturePad: SignaturePadComponent;

    @Input() signaturePadOptions: SignaturePadOptions = {
        minWidth: 3,
        canvasWidth: 1000,
        canvasHeight: 402,
    };

    @Input() set resetSignature(value: boolean) {
        if (value) {
            this.clearSignaturePad();
        }
    }

    @Output() canvasDirty: EventEmitter<boolean> = new EventEmitter<boolean>(false);

    @Output() signatureBlob: EventEmitter<Blob> = new EventEmitter<Blob>();

    constructor() { }

    clearSignaturePad(): void {
        this.signaturePad.clear();
        this.canvasDirty.emit(false);
        this.signatureBlob.emit(null);
    }

    drawComplete(): void {
        let canvas = (this.signaturePad as any).elementRef.nativeElement.querySelector('canvas');
        canvas = this.trim(canvas);

        this.signatureBlob.emit(blobUtil.dataURLToBlob(canvas.toDataURL('image/jpg')));
    }

    drawStart(): void {
        this.canvasDirty.emit(true);
    }

    private trim(c: HTMLCanvasElement) {
        const ctx = c.getContext('2d');
        const copy = document.createElement('canvas').getContext('2d');
        const pixels = ctx.getImageData(0, 0, c.width, c.height);
        const l = pixels.data.length;
        const bound = {
            top: null,
            left: null,
            right: null,
            bottom: null,
        };
        let x;
        let y;

        for (let i = 0; i < l; i += 4) {
            if (pixels.data[i + 3] !== 0) {
                x = (i / 4) % c.width;
                y = ~~((i / 4) / c.width);

                if (bound.top === null) {
                    bound.top = y;
                }

                if (bound.left === null) {
                    bound.left = x;
                } else if (x < bound.left) {
                    bound.left = x;
                }

                if (bound.right === null) {
                    bound.right = x;
                } else if (bound.right < x) {
                    bound.right = x;
                }

                if (bound.bottom === null) {
                    bound.bottom = y;
                } else if (bound.bottom < y) {
                    bound.bottom = y;
                }
            }
        }

        const trimHeight = bound.bottom - bound.top;
        const trimWidth = bound.right - bound.left;
        const trimmed = ctx.getImageData(bound.left, bound.top, trimWidth, trimHeight);

        copy.canvas.width = trimWidth;
        copy.canvas.height = trimHeight;
        copy.putImageData(trimmed, 0, 0);

        return copy.canvas;
    }

}
