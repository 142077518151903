// #region Imports

import { IMotionPreset } from './MotionCache';

// #endregion

export namespace Motions {

    export const fadeSlideIn: { name: string; preset: IMotionPreset } = {
        name: 'fadeSlideIn',
        preset: {
            from: { opacity: 0, transform: 'translateY(16px)' },
            to: { opacity: 1, transform: 'translateY(0)' },
            options: { duration: 800 },
        },
    };

}
