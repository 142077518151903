/* libs/layout/src/Controls/Components/Page/PagePreContentComponent.scss */
:host {
  display: block;
}
:host.sticky {
  position: sticky;
  top: 0;
  z-index: 2;
}
/*# sourceMappingURL=PagePreContentComponent.css.map */
