import {
    Directive,
    EventEmitter,
    forwardRef,
    HostBinding,
    HostListener,
    Inject,
    Input,
    OnDestroy,
    OnInit,
    Output,
    TemplateRef,
    ViewContainerRef,
} from '@angular/core';
import { MatTable } from '@angular/material/table';
import { DetailRowService } from './DetailRowService';

@Directive({
    selector: '[l7DetailRow]',
    standalone: false,
})
export class DetailRowDirective implements OnInit, OnDestroy {

    // #region Fields

    private readonly _table: MatTable<any>;
    private readonly _vcRef: ViewContainerRef;
    private readonly _detailRowService: DetailRowService;
    private readonly _expanded: EventEmitter<void>;
    private readonly _collapsed: EventEmitter<void>;

    private row: any;
    private tRef: TemplateRef<any>;
    private opened: boolean;

    private readonly _context = { $implicit: { row: null, ref: this } };

    // #endregion

    // #region Ctor

    public constructor(@Inject(forwardRef(() => MatTable)) table: MatTable<any>, vcRef: ViewContainerRef, detailRowService: DetailRowService) {
        this._table = table;
        this._vcRef = vcRef;
        this._detailRowService = detailRowService;

        this._expanded = new EventEmitter();
        this._collapsed = new EventEmitter();
    }

    // #endregion

    // #region Properties

    @HostBinding('class.expanded')
    public get expended(): boolean {
        return this.opened;
    }

    @Input('l7DetailRow')
    public set detailRow(value: any) {
        if (value !== this.row) {
            this.row = value;
        }
    }

    @Input('l7DetailRowTemplate')
    public set template(value: TemplateRef<any>) {
        if (value !== this.tRef) {
            this.tRef = value;
        }
    }

    /**
     * Provides reference to `void` as event argument.
     *
     * @public
     * @readonly
     * @eventProperty
     * @type EventEmitter<void>
     */
    @Output('l7DetailRowExpanded')
    public get expanded(): EventEmitter<void> {
        return this._expanded;
    }

    /**
     * Provides reference to `void` as event argument.
     *
     * @public
     * @readonly
     * @eventProperty
     * @type EventEmitter<void>
     */
    @Output('l7DetailRowCollapsed')
    public get collapsed(): EventEmitter<void> {
        return this._collapsed;
    }

    // #endregion

    // #region Methods

    public ngOnInit(): void {
        this._detailRowService.register(this);
    }

    public ngOnDestroy(): void {
        this._detailRowService.unregister(this);
    }

    @HostListener('click')
    public onClick(): void {
        this.toggle();
    }

    public toggle(): void {
        if (this.opened) {
            this.collapse();
        } else {
            this._detailRowService.expand(this);
        }
    }

    public collapse(): void {
        if (this.opened) {
            this._vcRef.clear();
            this.opened = false;
            this._collapsed.emit();
        }
    }

    public expand(): void {
        if (!this.opened) {
            this._vcRef.clear();
            if (this.tRef && this.row) {
                this._context.$implicit.row = this.row;
                this._context.$implicit.ref = this;
                this._vcRef.createEmbeddedView(this.tRef, this._context).detectChanges();
            }
            this.opened = true;
            this._expanded.emit();
        }
    }

    // #endregion

}
