// #region Imports

import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { ISpacerModuleConfig } from './ISpacerModuleConfig';
import { SpacerComponent } from './SpacerComponent';
import { SPACER_MODULE_CONFIG } from './SpacerModuleTokens';

// #endregion

// #region Factories

/**
 * @internal
 * @factory
 */
export function spacerModuleConfigFactory(): ISpacerModuleConfig {
    const multiplierValidator = (multiplier: number): boolean => {
        const isValid = (multiplier / 4) % 1 ? false : true;

        if (!isValid) {
            throw new Error('Invalid multiplier.');
        }

        return isValid;
    };

    return {
        defaultMultiplier: 8,
        multiplierValidator: multiplierValidator,
    };
}

// #endregion

/**
 * The Spacer module.
 *
 * @public
 */
@NgModule({
    imports: [
        CommonModule,
    ],
    exports: [
        SpacerComponent,
    ],
    declarations: [
        SpacerComponent,
    ],
    providers: [
        { provide: SPACER_MODULE_CONFIG, useFactory: spacerModuleConfigFactory },
    ],
})
export class SpacerModule {

    // #region Methods

    public static forRoot(config: ISpacerModuleConfig): ModuleWithProviders<SpacerModule> {
        return {
            ngModule: SpacerModule,
            providers: [
                { provide: SPACER_MODULE_CONFIG, useValue: config },
            ],
        };
    }

    // #endregion

}
