// #region Imports

import { DataSource } from '@angular/cdk/collections';
import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';

// #endregion

/**
 * The `DataTableComponent` component.
 *
 * @public
 */
@Component({
    selector: 'l7-data-table',
    templateUrl: './DataTableComponent.html',
    styleUrls: ['./DataTableComponent.scss'],
    exportAs: 'dataTable',
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false,
})
export class DataTableComponent<TData> {

    // #region Fields

    private readonly _rowSelected: EventEmitter<TData>;

    private _dataSource: DataSource<TData>;
    private _columns: Array<string>;

    // #endregion

    // #region Ctor

    public constructor() {
        this._rowSelected = new EventEmitter<TData>();
    }

    // #endregion

    // #region Properties

    /**
     * Gets or sets the `dataSource` property.
     *
     * @public
     */
    @Input()
    public get dataSource(): DataSource<TData> {
        return this._dataSource;
    }

    public set dataSource(value: DataSource<TData>) {
        this._dataSource = value;
    }

    /**
     * Gets or sets the `columns` property.
     *
     * @public
     */
    @Input()
    public get columns(): Array<string> {
        return this._columns;
    }

    public set columns(value: Array<string>) {
        this._columns = value;
    }

    /**
     * Called when <ACTION>.
     * Provides reference to `TData` as event argument.
     *
     * @public
     * @readonly
     * @eventProperty
     * @type EventEmitter<TData>
     */
    @Output()
    public get rowSelected(): EventEmitter<TData> {
        return this._rowSelected;
    }

    // #endregion

    // #region Imports

    /**
    * Emitts the {@link rowSelected} event.
    *
    * @protected
    */
    protected onRowSelected(args: TData): void {
        this._rowSelected.emit(args);
    }

    // #endregion

}
