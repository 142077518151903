// #region Imports

import { Observable } from 'rxjs';

// #endregion

/**
 * Subscribes a observable only once. The subscription will be unsubscribed when the observable is completed.
 *
 * @public
 */
export function subscribeOnce<T>(observable: Observable<T>, next?: (value: T) => void, error?: (error: any) => void, complete?: () => void): { observable: Observable<T> } {
    const subscription = observable.subscribe(next, error, () => {
        if (complete) {
            complete();
        }

        subscription.unsubscribe();
    });

    return { observable };
}
