// #region Imports

import { Directive } from '@angular/core';

// #endregion

/**
 * The `FilterClearAllDirective` directive.
 *
 * @public
 */
@Directive({
    selector: '[l7FilterPart]',
    exportAs: 'l7FilterPart',
    standalone: false,
})
export class FilterPartDirective { }
