// #region Imports

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@ngbracket/ngx-layout';
import { BrandComponent } from './BrandComponent';

// #endregion

/**
 * The `BrandModule` module.
 *
 * @public
 */
@NgModule({
    imports: [
        CommonModule,
        FlexLayoutModule,
    ],
    declarations: [
        BrandComponent,
    ],
    exports: [
        BrandComponent,
    ],
    // providers: [],
})
export class BrandModule { }
