import { Component, OnInit, Input, Output, EventEmitter, ViewChild, ElementRef, AfterViewInit } from '@angular/core';
import { UntilDestroy } from '@ngneat/until-destroy';
import { WebcamComponent, WebcamImage, WebcamInitError, WebcamUtil } from 'ngx-webcam';
import { Subject, Observable } from 'rxjs';

@UntilDestroy()
@Component({
    selector: 'l7-web-camera-component',
    templateUrl: './WebCameraComponent.html',
    styleUrls: ['./WebCameraComponent.scss'],
    standalone: false,
})
export class WebCameraComponent implements OnInit, AfterViewInit {

    // webcam snapshot trigger
    private readonly _trigger: Subject<void> = new Subject<void>();
    // switch to next / previous / specific webcam; true/false: forward/backwards, string: deviceId
    private readonly _nextWebcam: Subject<boolean | string> = new Subject<boolean | string>();

    public constructor() {}

    @Input() buttonText: string;

    @Input() height: number;

    @Input() width: number;

    @Input() showButtons: boolean;

    @Input() showBackButton: boolean;

    @Input() set triggerCapture(value: boolean) {
        if (value) {
            this.recordSubmitted();
        }
    }

    @ViewChild('webcamComponent') webcamElement: WebcamComponent;
    @ViewChild('clearArea') clearArea: ElementRef<HTMLDivElement>;
    @ViewChild('cameraArea') cameraArea: ElementRef<HTMLDivElement>;

    @Output() capturedImage: EventEmitter<WebcamImage> = new EventEmitter<WebcamImage>();
    @Output() backTriggered: EventEmitter<boolean> = new EventEmitter<boolean>();

    public showWebcam: boolean = true;
    public allowCameraSwitch: boolean = true;
    public multipleWebcamsAvailable: boolean = false;
    public deviceId: string;
    public videoOptions: MediaTrackConstraints = {
        width: { ideal: 800 },
        height: { ideal: 500 },
    };

    public errors: Array<WebcamInitError> = [];

    // latest snapshot
    public webcamImage: WebcamImage = null;

    public ngAfterViewInit(): void {
        this.webcamElement.nativeVideoElement.setAttribute('style', 'border-radius: 8px;');
    }

    public ngOnInit(): void {
        WebcamUtil.getAvailableVideoInputs()
            .then((mediaDevices: Array<MediaDeviceInfo>) => {
                this.multipleWebcamsAvailable = mediaDevices && mediaDevices.length > 1;
            });
    }

    public handleInitError(error: WebcamInitError): void {
        console.error(error);
        this.errors.push(error);
    }

    public handleImage(webcamImage: WebcamImage): void {
        this.capturedImage.emit(webcamImage);
    }

    public cameraWasSwitched(deviceId: string): void {
        this.deviceId = deviceId;
    }

    public get triggerObservable(): Observable<void> {
        this.webcamElement?.nativeVideoElement?.play();
        return this._trigger.asObservable();
    }

    public get nextWebcamObservable(): Observable<boolean | string> {
        return this._nextWebcam.asObservable();
    }

    public recordSubmitted(): void {
        this._trigger.next();
    }

    public back(): void {
        this.backTriggered.emit(true);
    }

    private cropImage(webcamImage: WebcamImage): void {
        const clearAreaElement = this.clearArea.nativeElement;
        const image = new Image();
        image.src = webcamImage.imageAsDataUrl;

        image.onload = () => {
            // Calculate the scale factors based on the natural image size and display size
            const scaleX = image.naturalWidth / this.cameraArea.nativeElement.offsetWidth;
            const scaleY = image.naturalHeight / this.cameraArea.nativeElement.offsetHeight;

            // Calculate the position and size of the crop area based on the scale
            const cropArea = {
                x: clearAreaElement.offsetLeft * scaleX,
                y: clearAreaElement.offsetTop * scaleY,
                width: clearAreaElement.offsetWidth * scaleX,
                height: clearAreaElement.offsetHeight * scaleY,
            };

            const canvas = document.createElement('canvas');
            const ctx = canvas.getContext('2d');
            canvas.width = image.naturalWidth;
            canvas.height = image.naturalHeight;

            ctx.drawImage(image, 0, 0);

            // Get the imageData for the cropped area
            const croppedImageData = ctx.getImageData(cropArea.x, cropArea.y, cropArea.width, cropArea.height);

            // Create a new canvas for the cropped area
            const cropCanvas = document.createElement('canvas');
            const cropCtx = cropCanvas.getContext('2d');
            cropCanvas.width = cropArea.width;
            cropCanvas.height = cropArea.height;

            // Draw the cropped area onto the new canvas
            cropCtx.putImageData(croppedImageData, 0, 0);

            // Convert the cropped canvas to a data URL
            const croppedImageDataUrl = cropCanvas.toDataURL('image/jpeg');

            const croppedWebcamImage = new WebcamImage(croppedImageDataUrl, 'image/jpeg', croppedImageData);

            this.capturedImage.emit(croppedWebcamImage);
        };
    }

}
