// #region Imports

import { NgModule } from '@angular/core';
import { RouterLinkActiveDirective } from './RouterLinkActiveDirecitve';

// #endregion

/**
 * The `RouterLinkActiveModule` module.
 *
 * @public
 */
@NgModule({
    declarations: [
        RouterLinkActiveDirective,
    ],
    exports: [
        RouterLinkActiveDirective,
    ],
})
export class RouterLinkActiveModule { }
