/* libs/presentation/src/Components/configuration-overview-view/ConfigurationOverviewView.scss */
my-box {
  background-color: var(--l7-info-500-color);
}
:host h4 {
  font-size: 16px;
}
:host .image-container {
  padding: 24px;
  border-radius: 5px;
  border: solid 1px #72787f;
}
/*# sourceMappingURL=ConfigurationOverviewView.css.map */
