// #region Imports

import { InjectionToken } from '@angular/core';
import { IAuthModuleConfig } from './IAuthModuleConfig';

// #endregion

/**
 * @public
 */
export const AUTH_MODULE_CONFIG = new InjectionToken<IAuthModuleConfig>('AUTH_MODULE_CONFIG');
