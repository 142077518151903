// #region Imports

import { Injectable } from '@angular/core';
import { MissingTranslationHandler, MissingTranslationHandlerParams } from '@ngx-translate/core';
import { AppConfig } from '../Configuration/AppConfig/AppConfig';

// #endregion

/**
 * @public
 */
@Injectable()
export class DefaultMissingTranslationhandler implements MissingTranslationHandler {

    /**
     * Constructs a new instance of the `DefaultMissingTranslationhandler` class.
     *
     * @public
     */
    public constructor(private readonly _appConfig: AppConfig) {

    }

    // #region Methods

    public handle(params: MissingTranslationHandlerParams): string {
        return this._appConfig.get('stage') === 'local'
            ? `### ${params.key} ###`
            : params.key;
    }

    // #endregion

}
