export function equals<T extends object>(self: T, target: T): boolean {
    if (self === target) {
        return true;
    }
    if (self === null || target === null) {
        return false;
    }
    if (self === undefined || target === undefined) {
        return false;
    }
    if (self !== self && target !== target) {
        return true;
    }
    const t1 = typeof self;
    const t2 = typeof target;
    let length: number;
    let key: any;
    let keySet: any;
    if (t1 === t2 && t1 === 'object') {
        if (Array.isArray(self)) {
            if (!Array.isArray(target)) {
                return false;
            }
            length = self.length;
            if (length === target.length) {
                for (key = 0; key < length; key++) {
                    if (!equals(self[key], target[key])) {
                        return false;
                    }
                }
                return true;
            }
        } else {
            if (Array.isArray(target)) {
                return false;
            }
            keySet = Object.create(null);
            for (key in self) {
                if (!equals(self[key], target[key])) {
                    return false;
                }
                keySet[key] = true;
            }
            for (key in target) {
                if (!(key in keySet) && typeof target[key] !== 'undefined') {
                    return false;
                }
            }
            return true;
        }
    }
    return false;
}
