// #region Imports

import { LayoutModule } from '@angular/cdk/layout';
import { NgModule } from '@angular/core';
import { BreakpointObserverDirective } from './BreakpointObserverDirective';

// #endregion

/**
 * The `BreakpointObserverModule` module.
 *
 * @public
 */
@NgModule({
    imports: [
        LayoutModule,
    ],
    exports: [
        BreakpointObserverDirective,
    ],
    declarations: [
        BreakpointObserverDirective,
    ],
})
export class BreakpointObserverModule { }
