// #region Imports

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TextBlockComponent } from './TextBlockComponent';

// #endregion

/**
 * @public
 */
@NgModule({
    imports: [
        CommonModule,
    ],
    declarations: [
        TextBlockComponent,
    ],
    exports: [
        TextBlockComponent,
    ],
})
export class TextBlockModule {
}
