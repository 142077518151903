import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormGroupDirective } from '@angular/forms';
import { MandatoryCheckboxWithErrorDirective } from './MandatoryCheckboxWithErrorDirective';

@NgModule({
    declarations: [
        MandatoryCheckboxWithErrorDirective,
    ],
    providers: [FormGroupDirective],
    exports: [MandatoryCheckboxWithErrorDirective],
    imports: [
        CommonModule,
    ],
})
export class MandatoryCheckboxWithErrorDirectiveModule { }
