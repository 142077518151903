/* libs/auth/src/Views/AuthorizeView.scss */
:host {
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 2;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100vh;
  width: 100%;
  background:
    linear-gradient(
      180deg,
      #00718e 0%,
      #002a35 100%);
  -webkit-font-smoothing: auto;
}
:host .container {
  padding: 32px;
}
:host .container button {
  outline: none;
  border: none;
  font-size: 16px;
  padding: 4px 16px;
  background-color: #b81931;
}
:host .container l7-brand {
  margin-bottom: 56px;
}
:host .container .footer {
  padding: 32px 0;
}
:host .container .footer l7-text-block {
  margin: 0 8px;
}
:host .container .footer a {
  text-decoration: none;
  font-size: 16px;
}
:host .abcfinance-lettering-logo-wrapper {
  position: absolute;
  right: 40px;
  top: 40px;
}
:host .abcfinance-lettering-logo-wrapper img {
  height: 96px;
}
:host .abcfinance-circle-wrapper {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: -500px;
  z-index: -1;
}
:host .abcfinance-circle-wrapper img {
  min-height: 100vh;
  max-height: 100vh;
}
/*# sourceMappingURL=AuthorizeView.css.map */
