/* libs/presentation/src/Components/camera/camera-stream/camera-stream.component.scss */
:host {
  width: 100%;
  display: flex;
  justify-content: center;
  margin: 40px 0;
}
:host .camera-stream {
  position: relative;
  max-width: 640px;
  min-width: 320px;
  width: 100%;
}
:host .camera-stream .camera-stream__placeholder {
  position: absolute;
  display: block;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
}
:host .camera-stream .camera-stream__video {
  position: relative;
}
:host .camera-stream .camera-stream__video video,
:host .camera-stream .camera-stream__video canvas {
  display: block;
  margin: 0 auto;
  width: 100%;
  height: auto;
}
:host .camera-stream .camera-stream__video canvas {
  position: absolute;
  top: 0;
  left: 50%;
  height: 100%;
  transform: translateX(-50%);
}
:host .camera-stream .camera-stream__menu {
  position: absolute;
  bottom: 0;
  left: 50%;
}
/*# sourceMappingURL=camera-stream.component.css.map */
