/* libs/ui/src/Controls/Components/MenuGroup/MenuGroupComponent.scss */
my-box {
  background-color: var(--l7-info-500-color);
}
:host {
  display: block;
  margin: 8px 0 0 0;
}
:host legend {
  padding-left: 16px;
  color: rgba(26, 35, 45, 0.12);
  font-size: 12px;
  line-height: 12px;
  font-weight: 900;
}
:host l7-menu-group ~ l7-menu-group {
  margin-top: 16px;
}
:host legend ~ * {
  margin-top: 8px;
}
/*# sourceMappingURL=MenuGroupComponent.css.map */
