// #region Imports

import { isNumeric } from '@abcfinlab/core';

// #endregion

export function convertNavStatusToNumber(navStatus: string): number {
    return isNumeric(navStatus)
        ? Number.parseFloat(navStatus)
        : 0;
}
