import { FeatureManager } from '@abcfinlab/core';
import { getWebInstrumentations, initializeFaro } from '@grafana/faro-web-sdk';
import { TracingInstrumentation } from '@grafana/faro-web-tracing';

export function faroInitializer(featureManager: FeatureManager): () => Promise<void> {
    return async () => {
        if (featureManager.active('grafanaFaro')) {
            initializeFaro({
                url: 'https://faro-collector-prod-eu-west-2.grafana.net/collect/e8230ca4a09ce48bb16109951809e0c4',
                app: {
                    name: 'Lease Seven Dev',
                    version: '1.0.0',
                },
                instrumentations: [
                    // Mandatory, omits default instrumentations otherwise.
                    ...getWebInstrumentations({ captureConsole: true, captureConsoleDisabledLevels: [] }),
                    // Tracing package to get end-to-end visibility for HTTP requests.
                    new TracingInstrumentation(),
                ],
            });
        }
    };
}
