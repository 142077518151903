/* libs/ui/src/Controls/Components/Summary/SummaryComponent.scss */
my-box {
  background-color: var(--l7-info-500-color);
}
:host fieldset {
  margin-bottom: 16px;
}
:host fieldset legend {
  font-size: 14px;
  line-height: 20px;
  font-weight: bold;
  color: #8b9095;
}
/*# sourceMappingURL=SummaryComponent.css.map */
