import { Directive, HostListener, Input } from '@angular/core';

@Directive({
    selector: '[l7AllowedRegex]',
    standalone: false,
})
export class AllowedRegexDirective {

    @Input() allowedRegExp: string;

    // l7AllowedRegex [allowedRegExp]="'^[a-zA-ZÄÖÜäöüßáàéèíìóòúùÁÀÉÈÍÌÓÒÚÙãñç]*$'"

    @HostListener('keydown', ['$event']) onKeyDown(event: any) {
        const k = event.target.value + event.key;

        if (['ArrowLeft', 'ArrowRight', 'ArrowUp', 'ArrowDown', 'Backspace', 'Tab', 'Alt',
            'Shift', 'Control', 'Enter', 'Delete', 'Meta'].includes(event.key)) {
            return;
        }

        const re = new RegExp(this.allowedRegExp);

        if (!re.test(k)) {
            event.preventDefault();
        }
    }

}
