/* libs/presentation/src/Components/check-signatures/check-signatures-dialog.component.scss */
:host img {
  max-height: 25vh;
  width: 25vw;
}
:host .mat-mdc-card-title {
  margin-left: 18px;
}
:host .mat-mdc-card-content.center {
  text-align: center;
}
:host legend {
  font-size: 14px;
  line-height: 20px;
  font-weight: bold;
  color: #83929b;
}
:host fieldset {
  margin-bottom: 16px;
}
/*# sourceMappingURL=check-signatures-dialog.component.css.map */
