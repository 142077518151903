// #region Imports

import { NgModule } from '@angular/core';
import { AbsoluteDirective } from './AbsoluteDirective';

// #endregion

/**
 * The `FormTrackModule`.
 *
 * @public
 */
@NgModule({
    declarations: [
        AbsoluteDirective,
    ],
    exports: [
        AbsoluteDirective,
    ],
})
export class AbsoluteDirectiveModule { }
