// #region Imports

import { TranslationModule } from '@abcfinlab/core';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { CompareToComponent } from './CompareToComponent';

// #endregion

/**
 * @public
 */
@NgModule({
    imports: [
        CommonModule,
        TranslationModule,
    ],
    declarations: [
        CompareToComponent,
    ],
    exports: [
        CompareToComponent,
    ],
})
export class CompareToModule {
}
