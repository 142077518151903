import { Inject, Injectable } from '@angular/core';
import { WINDOW } from '../Tokens/WindowToken';

/**
 *
 * @public
 */
@Injectable({ providedIn: 'root' })
export class BlobHandler {

    // #region Fields

    private readonly _window: Window;

    // #endregion

    // #region Ctor

    /**
     * Constructs a new instance of the `BlobHandler.ts` class.
     *
     * @public
     */
    public constructor(@Inject(WINDOW) window: Window) {
        this._window = window;
    }

    // #endregion

    // #region Methods

    public open(blob: Blob, target: string = '_blank'): void {
        const url = URL.createObjectURL(blob);
        this._window.open(url, target);
    }

    // #endregion

}
