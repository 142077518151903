/* libs/layout/src/Controls/Components/Page/PageHeaderComponent.scss */
my-box {
  background-color: var(--l7-info-500-color);
}
:host {
  display: block;
  width: 100%;
  padding: 16px 0;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}
:host .back-button {
  text-decoration: none;
}
:host .title {
  margin: 0;
  font-weight: 600;
  font-size: 24px;
  line-height: 34px;
  color: #1a232d;
}
:host .title.sub {
  font-weight: bold;
  font-size: 16px;
  line-height: 24px;
}
:host .title.supportive {
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  margin-top: 24px;
  color: rgba(26, 35, 45, 0.54);
}
:host .title:empty {
  margin: 0;
}
/*# sourceMappingURL=PageHeaderComponent.css.map */
