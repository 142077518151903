import { IContractTypeDto, IInsuranceTypeDto, ISlbCalculationDto } from '@abcfinlab/api/contract';
import { IContractTypeDto as IContractTypeDtoGlobal } from '@abcfinlab/api/global';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
export interface ICalculationInfo {
    totalLeasingValueNet: number;
    lastInstalment: number;
    firstInstalment: number;
    instalment: number;
    downPayment: number;
    residualValue: number;
}
export interface IInsuranceValues {
    insuranceValue: number;
    insuranceType: IInsuranceTypeDto;
}
@Component({
    selector: 'l7-object-value-changed',
    templateUrl: './object-value-changed.component.html',
    styleUrls: ['./object-value-changed.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false,
})
export class ObjectValueChangedComponent {

    @Input() calculationValues: ICalculationInfo;
    @Input() contractType: IContractTypeDto | IContractTypeDtoGlobal;
    @Input() insuranceValues: IInsuranceValues;
    @Input() slbCalculation: ISlbCalculationDto;
    @Input() isPurchasePriceChangedInactive: boolean;

}
